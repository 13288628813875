@import '../../globals.scss';



.SliderInput  {
    display:flex;
    flex-basis:25%;
    align-items:center;

    .SliderInput-Value  {
        font-family: $main-font;
        font-style: normal;
        font-weight: 500; 
        font-size: 14px;
        line-height: 20px;
        width:4ch;
        text-align:center;
        /* identical to box height, or 143% */

        /* Text/Text-Default */
        color: $text-neutral;
        
    }

    .SliderInput-BarContainer {
        display:flex;
        position:relative;
        flex:1;
    }
    .SliderInput-BackBar  {
        position:absolute;
        background:$neutral-200;
        border-radius:5px;
    }

    .SliderInput-DisplayBar   {
        position:absolute;
        border-radius:5px;
    }

    .SliderInput-Dial {
        position:absolute;
        margin:auto;

        cursor:pointer;

        font-family: $main-font;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        /* identical to box height, or 167% */

        align-items: center;
        text-align: center;

        color: #FFFFFF;

    }


    .SliderInput-LockingToggle    {
        margin-top:8px;
    }




    

    &.SliderInput-Vertical  {
        flex-direction: column;
        gap:4px;

        .SliderInput-BarContainer {
            min-height:50px;
            max-height:250px;
            width:0px;
        }
        .SliderInput-BackBar  {
            top:0;
            bottom:0;
            transform: translate(-50%, 0);
            width:5px;
            height:100%;
        }
    
        .SliderInput-DisplayBar   {
            transform: translate(-50%, 0);
            width:5px;
        }
    
        .SliderInput-Dial {
            transform:translate(-50%, -50%);
            left:0px;
            right:0px;
        }
    }

    &.SliderInput-Horizontal  {
        flex-direction: row;
        gap:15px;
        flex-grow:1;

        .SliderInput-BarContainer {
            min-width:50px;
            max-width:400px;
            flex-grow:1;
            height:0px;
        }
        .SliderInput-BackBar  {
            left:0;
            right:0;
            transform: translate(0, -50%);
            height:5px;
            width:100%;
        }
    
        .SliderInput-DisplayBar   {
            transform: translate(0, -50%);
            height:5px;
        }
    
        .SliderInput-Dial {
            transform:translate(-50%, 0);
            top:0px;
            bottom:0px;
        }
    }
}