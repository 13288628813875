@import '../../globals.scss';


.RowGroupSubGroup-Container {
    display:flex;
    flex-direction: column;
    align-self:stretch;
}

.RowGroupSubGroup   {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    align-self: stretch; 
}


.RowGroupSubGroup-Header    {
    display: flex;
    flex-direction: column;
    padding: 0px 10px;

    align-self: stretch; 
}



.RowGroupSubGroup-Header-Content    {
    display: flex;
    align-items: stretch;
    gap: 10px;
    align-self: stretch;
    justify-content: center;
    
    padding: 6px 0px;
    overflow-x:hidden;
    border-bottom:1px solid #9CA6B4;

}


.RowGroupSubGroup-Header-PrimaryContent  {
    display: flex;
    align-items: center;
    overflow-x:hidden;
    gap:10px;
}

.RowGroupSubGroup-Header-StatusContent   {
    display: flex;
    align-items: center;
    gap: 10px;
    overflow-x:hidden;

}


.RowGroupSubGroup-Header-ExpandedContentToggle  {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px; 
    padding:0px 20px;
    margin:0px -20px;
    align-self: stretch;
    justify-content: center;
}


.RowGroupSubGroup-Header-StatusContent-Title  {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px; 
    overflow-x:hidden;
}
.RowGroupSubGroup-Header-StatusContent-Title-Content  {
    color: var(--Text, #4D5563);
    font-family: $main-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 

    overflow-x:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

}

.RowGroupSubGroup-Header-StatusContent-NumRows    {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x:hidden;
}

.RowGroupSubGroup-Header-StatusContent-NumRows-Content    {
    color: var(--Dark-Section-Border, #9CA6B4);
    font-family: $main-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
}


.RowGroupSubGroup-Header-AdditionalFunctions  {
    display: flex;
    align-items: center;
    gap: 4px; 
}
.RowGroupSubGroup-Header-AdditionalFunctions-Button  {
    display: flex;
    align-items: center;
    gap: 10px; 
    padding-left:5px;
    padding-right:5px;
}




.RowGroupSubGroup-Content-Container   {
    display:flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch; 


    &.RowGroupSubGroup-Content-Container-Expandable   {
        overflow-y:hidden;
        /*transition:height 250ms ease;*/
    }
}

.RowGroupSubGroup-Content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-self:stretch;
}

.RowGroupSubGroup-Content-Card   {
    display: flex;
    flex-direction: column;
    padding: 4px 20px;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch; 

    border-radius: 4px;
    border: 1px solid var(--section-border, #DDE3EB);
    background: #FFF; 
}

