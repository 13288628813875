@import '../../../globals.scss';


.RecipeTimelineBar-Container   {
    display:flex;
    flex-direction: column;
    padding: 5px 0px;

    flex:1;
}

.RecipeTimelineBar {
    display:flex;
    flex-direction: row;
    flex-grow:1;
    gap:5px;
    align-content: center;
    align-items: center;
}

.RecipeTimelineBar-Duration {
    display:flex;
    flex-direction: column;
    width:8ch;
    text-align: right;
}

.RecipeTimelineBar-Content    {
    display:flex;
    flex:1;
    min-width:200px;
}

.RecipeTimelineBar-Bars {
    display:flex;
    align-self:stretch;
    position: relative;
    height:15px;
}

.RecipeTimelineBar-Bar  {
    position:absolute;
    top:0;
    bottom:0;
    height:100%;
    gap:10px;

    &.RecipeTimelineBar-SelectedBar {
        .RecipeTimelineBar-Bar-Content  {
            background: var(--Main-Blue, #1D65F0); 
        }
    }
}

.RecipeTimelineBar-Bar-Content  {
    display:flex;
    align-self: stretch;
    position: relative;
    flex:1;
    height:100%;
    background-color: #F0F4FB;
    border-radius: 2px;
    margin:-1px;
    border:1px solid $main-blue;
}

.RecipeTimelineBar-Bar-ProgressBar  {
    position:absolute;
    top:0;
    bottom:0;
    height:100%;
    background-color: $main-blue;
}



.RecipeTimelineBar-EventEntry   {
    position:absolute;
    top:50%;
    bottom:50%;
    width:0;
    height:0;
    cursor:pointer;
}

.RecipeTimelineBar-EventEntry-Container   {
    display:flex;
    position: relative;
    transform: translate(-50%, -50%);
    padding:10px;
}
.RecipeTimelineBar-EventEntry-Content {
    display:flex;
    position: relative;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.RecipeTimelineBar-EventEntry-SingleEntry   {
    position:absolute;
    width: 6px;
    height: 6px; 
    border-radius:6px;
    background-color:#9CA6B4;
    transition:background-color 500ms ease;
}

.RecipeTimelineBar-EventEntry-SingleEntry-Selection {
    position:absolute;
    width: 10px;
    height: 10px; 
    border-radius: 10px;
    border:1px solid $main-blue;
    opacity:0;
    transition:opacity 500ms ease;
}


.RecipeTimelineBar-EventEntry-Selected .RecipeTimelineBar-EventEntry-SingleEntry  {
    background-color: $main-blue;
}
.RecipeTimelineBar-EventEntry-Selected .RecipeTimelineBar-EventEntry-SingleEntry-Selection  {
    opacity: 1.0;
}



.RecipeTimelineBar-EventEntry-MultiEntry   {
    position: absolute;
    display:flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;
    border-radius:10px;
    background-color:#9CA6B4;
    transition:background-color 500ms ease;
}

.RecipeTimelineBar-EventEntry-Selected .RecipeTimelineBar-EventEntry-MultiEntry  {
    background-color: $main-blue;
}


.RecipeTimelineBar-EventEntry-MultiEntry-Value   {
    color: var(--White, #FFF);
    text-align: center;
    font-family: Roboto;
    font-size: 7px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
    
}