@import '../../../globals.scss';
@import '../../../components/DataStatus/DataStatus.scss';

.BladeInstance_StatusContent    {
    display: flex;
    align-items: flex-start;
    gap: 2px;
    align-self: stretch;
    padding:10px;
}

.BladeInstance_StatusContent-Column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    flex: 1 0 0;
}
