@import '../globals.scss';


.DropDownButton    {
    display:flex;
    flex-direction: column;
    align-self:stretch;


    &.DropDownButton-Disabled    {
        opacity:0.4;
    }
    &:not(.DropDownButton-Disabled)  {
        cursor:pointer;
    }

    

    &.DropDownButton-Selected    {
        .DropDownButton-Content    {
            border-color: #2E72D2;
            background-color:#E4EAF5;
        }
    }

    &.DropDownButton-WithBorder {
        &.DropDownButton-Neutral {
            .DropDownButton-Content    {
                background:#FFF;
                border-color: #9CA6B4;
            }
        }
        &.DropDownButton-AltNeutral {
            .DropDownButton-Content    {
                background:#FFF;
                border-color: #DDE3EB;
            }
        }
    }

    

    &.DropDownButton-Neutral    {
        border-radius: 4px;
        border: 1px solid #DDE3EB;
        background: #FFF; 

        .DropDownButton-Content {
            font-size:14px;
        }
    }

    &.DropDownButton-Icon-Action   {
        border-radius: 4px;
        border: 1px solid var(--Section-Border, #DDE3EB);
        background: #F7F8FB;

        align-self: stretch;
    }

    
    &.DropDownButton-Icon-Action-Borderless   {

        align-self: stretch;
    }
}


.DropDownButton-Container    {
    display:flex;
    flex-direction: column;
    align-self:stretch;
    flex: 1 0 0;
}
.DropDownButton-Content    {
    display: flex;
    padding: 8px 12px 8px 12px;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;


    border-radius: 4px;
    border-width:1px;
    border-style: solid;
    border-color:transparent;

    


    .DropDownButton-Content-Arrow  {
        display:flex;
        align-self:center;
    }
}



.DropDownButton-Popup-Closer {
    position:fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    width:100%;
    height: 100%;
    z-index:999;
    cursor:default;
}

.DropDownButton-Popup-Wrapper    {
    position:fixed;
    overflow:hidden;
    box-sizing: border-box;
    transition:height 100ms ease;
    z-index:1000;
}

.DropDownButton-Popup-Container    {
    display:flex;
    overflow:hidden;
}

.DropDownButton-Popup    {
    display:flex;
    overflow-y:auto;
    overflow-x:hidden;

    border-radius: 4px;
    border-style:solid;
    border-color: #2E72D2;
    box-shadow: -1px 4px 20px 0px rgba(0, 0, 0, 0.10);
    background: #FFF;
}

.DropDownButton-Popup-ContentWrapper {
    display:flex;
    align-self:stretch;
    overflow-x:hidden;
}
.DropDownButton-Popup-Content    {
    display:flex;
    padding: 10px 8px; 
    align-items: flex-start;
    gap: 10px;    
    overflow-x:hidden; 
}



.DropDownButton-Popup-Options    {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    flex: 1 1 0; 
    overflow-x:hidden;
}


.DropDownButton-Popup-Option   {
    display: flex;
    padding: 4px 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    align-self: stretch; 
    border-radius: 4px;
    overflow-x:hidden;
    flex:1;

    &:hover {
        background: var(--background, #F0F1F2);
    }

    &:not(.DropDownButton-Popup-Option-Disabled) {
        cursor:pointer;
    }
    &.DropDownButton-Popup-Option-Disabled {
        cursor:default;
    }



    .DropDownButton-Popup-Option-Title   {
        display:flex;
        flex-direction: row;
        align-items:center;
        gap:4px;
        overflow-x:hidden;
        align-self:stretch;
        color: #000;

        >span {
            overflow:hidden;
            text-overflow: ellipsis;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: $main-font;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */ 
        }
    }

    .DropDownButton-Popup-Option-Info   {
        color: var(--dark-section-border, #9CA6B4);
        
        overflow:hidden;
        text-overflow: ellipsis;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: $main-font;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 10px; /* 100% */ 
    }

    &.DropDownButton-Popup-Option-Disabled  {
        /*background: var(--background, #F0F1F2);*/

        .DropDownButton-Popup-Option-Title   {
            opacity:0.4;
        } 
        .DropDownButton-Popup-Option-Info   {
            opacity:0.9;
        }     
    }


    &.DropDownButton-Popup-Option-Selected   {
        .DropDownButton-Popup-Option-Title   {
            color: #2E72D2;
        } 
    }



    &.DropDownButton-Popup-Option-Status-Critical   {
        .DropDownButton-Popup-Option-Title   {
            color: #DD1212;
        } 
    } 
}