@import '../globals.scss';


.TabControl {
    display: flex;
    flex-grow: 1;
    align-self:stretch;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
}


.TabControl_Tabs-Container  {
    display:flex;
    padding: 0px 20px;
    /* Neutral/Neutral 300 */
    border-bottom: 1px solid $neutral-300;

    &.TabControl_Tabs-Center    {
        justify-content:center;
    }
}
.TabControl_Tabs {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    overflow-x:auto;    

    &.TabControl_Tabs-Flex  {
        align-items:stretch;
        flex:1;
        .TabControl_Tab {
            flex:1;
        }
    }
}



.TabControl_Tab {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 4px;
    position:relative;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

    cursor:pointer;

    &:hover {
        background:$neutral-200;
    }

    &.TabControl_Tab-Selected   {
        .TabControl_TabLabel    {
            font-weight:500;
        }
    }
    

    .TabControl_TabLabel    {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 13px 16px;
        gap: 10px;


        /* Inside auto layout */
        color: #191C22;
        /* Nav button light */
        font-family: $main-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 
        
        /* identical to box height, or 143% */
        text-align: center;

    }

    .TabControl_TabSelectedIndicator   {
        height: 3px;

        /* Base/Interactive */
        background: $interactive;
        border-radius: 4px 4px 0px 0px;
        
        /* Inside auto layout */
        position:absolute;
        bottom:0;
        left:0;
        right:0;
    }
}

.TabControl_ContentWrapper  {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y:auto;
}