@import '../../globals.scss';


.NumberInput {
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-self:flex-start;
    gap: 10px;
    position:relative;


    /* Base/True White */
    background: #FFFFFF;

    /* Neutral/Neutral 200 or 300 */
    margin:1px;
    border: 1px solid $neutral-300;

    /* shadow-tertiary */
    box-shadow: 16px 20px 16px rgba(187, 193, 204, 0.01), 7px 9px 12px rgba(187, 193, 204, 0.01), 2px 2px 6px rgba(187, 193, 204, 0.01), 0px 0px 0px rgba(187, 193, 204, 0.01);
    border-radius: 4px;

    /* shadow-tertiary */
    box-shadow: 16px 20px 16px rgba(187, 193, 204, 0.01), 7px 9px 12px rgba(187, 193, 204, 0.01), 2px 2px 6px rgba(187, 193, 204, 0.01), 0px 0px 0px rgba(187, 193, 204, 0.01);
    border-radius: 4px;

    input   {
        background:transparent;
    }
    
    &.NumberInput-Focused:not(.NumberInput-Invalid)   {
        margin:0px;
        border-color:$interactive;
        border-width:2px;
        z-index:99999;
    }
    &.NumberInput-Invalid   {
        margin:0px;
        border-color:$main-red;
        border-width:2px;
        z-index:99999;
    }

    &.NumberInput-Disabled  {
        background: $neutral-50;
    }

    .NumberInput_ContentWrapper {
        display:flex;
        justify-content: stretch;
        flex:1;
    }

    .NumberInput_Content    {
        display:flex;
        flex-direction: row;
        flex:1;
        align-items: center;
        justify-content: center;
        padding: 7px 7px;
        gap: 4px;

    }

    .NumberInput_Prefix   {
        display:flex;
        svg {
            width:20px;
            height:20px;

            /* Inside auto layout */
            flex: none;
            order: 0;
            flex-grow: 0;
        }
    }

    .NumberInput_Suffix {
        font-family: $main-font;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */

        /* Neutral/Neutral 700 */
        color: $neutral-700;
    }

    .NumberInput_DisplayArea  {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 4px;

        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
        
        input  {
            font-style: normal;
            font-weight: 400;
            line-height: 20px;

            /* identical to box height, or 125% */
            display: flex;
            align-items: center;

            /* Text/Text-Default */
            color: $text-neutral;


            /* Inside auto layout */
            flex: none;
            order: 0;
            align-self: stretch;
            flex-grow: 0;


            border:0;
            margin:0;
            padding:0;
            -webkit-appearance: none;
            -moz-appearance: none;
            outline: none;
        }
    }


    .NumberInput_Buttons    {
        display:flex;
        flex-direction: column;
        flex-grow:0;
        align-self: stretch;
        
    }
    .NumberInput_ButtonUp, .NumberInput_ButtonDown  {
        display:flex;
        flex-direction: column;
        align-items: stretch;
        align-self:stretch;
        background-color: $neutral-100;
        border-color:$neutral-300;
        border-style:solid;
        cursor:pointer;
    }
    .NumberInput_ButtonUp   {
        border-width:0 1px 0 0;
    }
    .NumberInput_ButtonDown   {
        border-width:0 0 0 1px;
    }

    

    .NumberInput_Button {
        display:flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        padding:0px 5px;
        align-items: center;
    }
}


.NumberInput-BlurDetect   {
    position:fixed;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    height:0;
    width:0;
    &.NumberInput-BlurDetect-Active   {
        height:100%;
        width:100%;
        left:0;
        top:0;
        right:0;
        bottom:0;
        z-index:99998;
    }
}