@import '../globals.scss';


.LoadingBar {
    display: flex;
    flex-direction: column;
    gap: 6px; 
    align-self:stretch;
    padding:5px 10px;
}
.LoadingBar-BarContainer    {
    display: flex;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    align-self:stretch;
    flex:1;
}
.LoadingBar-Bar {
    display: flex;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    align-self:stretch;
    transition: width 500ms ease;
}
.LoadingBar-Content    {
    display:flex;
    align-self:stretch;
    align-content:center;
    flex:1;
}

.LoadingBar-SubContent    {
    display:flex;
    align-self:center;
    
    color: #191C22;
    /* Cell Content 3 */
    font-family: $main-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
}