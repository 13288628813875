@import '../../../globals.scss';


#ScheduleGrowPopup  {
 
}


.ScheduleGrowPopup_GrowInfoUnitResults-Icon  {
    display:flex;
    align-items:center;
    justify-content:center;
    width:41px;
    height:41px;
}
.ScheduleGrowPopup_GrowInfoUnitResults-Display  {
    display:flex;
    align-items: center;
    color: #4F5562;
    font-family: $main-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity:0.5;
    white-space:nowrap;
}










.ScheduleGrowPopup-Calendar-DayTile  {
    display:flex;
    align-self: stretch;
    position: relative;
    justify-content: stretch;
    flex-direction: column;
    justify-content: center;
    flex:1;

    
}


.ScheduleGrowPopup-Calendar-DayTileContent  {
    display:flex;
    align-self:stretch;
    justify-content: center;
    align-items: center;
    flex:0.6;

    &.ScheduleGrowPopup-Calendar-SelectedDayStart    {
        box-sizing: border-box;
        border-radius:50% 0 0  50%;
        border:1px solid #F0F4FB;
        background-color: #F0F4FB;
    }

    &.ScheduleGrowPopup-Calendar-SelectedDayRange    {
        background: #F0F4FB;
    }
    &.ScheduleGrowPopup-Calendar-SelectedDayHarvest   {
        box-sizing: border-box;
        border-radius:0 50% 50% 0;
        border:1px solid #F0F4FB;
        background: #F0F4FB;
    }
    &.ScheduleGrowPopup-Calendar-SelectedDay    {
        .ScheduleGrowPopup-Calendar-DayTileContent {
            border-radius: 50%;
            background-color: #F0F4FB;
        }
    }
}


.ScheduleGrowPopup-Calendar-SelectedDayStart-Visual {
    position:absolute;
    left:5%;
    right:5%;
    bottom:5%;
    top:5%;
    border-radius: 50%;
    border: 2.338px solid var(--Main-Blue, #1D65F0);
    z-index:200;
}


.ScheduleGrowPopup-Calendar-SelectedDayHarvest-Visual {
    position:absolute;
    left:5%;
    right:5%;
    bottom:5%;
    top:5%;
    border-radius: 50%;
    border: 2.338px solid var(--Main-Blue, #DDE3EB);
    z-index:200;
}





.ScheduleGrowPopup-Review-DetailsItem  {
    > div    {
        color: #191C22;
        font-family: $main-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 
        white-space: nowrap;
    }
}

.ScheduleGrowPopup-Review-DetailsItem-AuxInfo   {
    color:#8B8D90!important;
}