@import '../globals.scss';

.GanttChart {
    display: flex;
    flex: 1;
    align-self:stretch;
    flex-direction: column;
    align-items: flex-start;

    background:$true-white;
    gap:6px;
    height:auto;
    overflow-y:hidden;

    #GanttChartTimeAxis {
        display: flex;
        flex:1;
        order:0;
    }

    .GanttChartCanvas-Container {
        position: relative;
        display: flex;
        flex-grow: 1;
        order:1;
        align-self:stretch;

        overflow-y:hidden;

        border-radius: 4px;
        border: 1px solid var(--Dark-Section-Border, #9CA6B4);
        background: var(--White, #FFF); 

    }
    .GanttChartCanvas   {
        width:100%;
        height:100%;
        overscroll-behavior-x: contain;
    }
    .GanttChartArea-Content {
        display:flex;
        margin:5px 0;
        gap:10px;
        flex-direction: column;
        position:absolute;
        width:100%;
        height:100%;
        left:0;
        top:0;
        bottom:0;
        right:0;
        overflow-y:auto;
        overflow-x:hidden;
        
        overscroll-behavior-x: contain;
    }

    .GanttChartArea-Content-RowItem-Wrapper {
        display:flex;
        flex-direction: column;
        width:100%;
        height:auto;
    }
}