@import '../globals.scss';


.ButtonGroup {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; 



    .ButtonGroup-Content {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 6px; 
    }


    
    .ButtonGroup-Button-Main    {
        display:flex;
        flex-direction: row;
        align-items:center;

        padding: 8px 12px 8px 12px;
        
        cursor:pointer;

        &.ButtonGroup-Button-Selected    {
            background: $interactive;
            border: transparent;

            .ButtonGroup-Button-Display  {
                color: $true-white;
            }
        }

        &:not(:last-child)   {
            border-right: 1px solid $neutral-300!important;
        }
    
        .ButtonGroup-Button-Display  {
            font-family: $main-font;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            /* identical to box height, or 143% */ 
            
        }
    }

    

    

}
