@import '../../../../globals.scss';


.HarvestTask-ContainerList-Item  {
    display: flex;
    padding: 14px 16px;
    flex:1;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch; 

    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: var(--White, #FFF);
}


.HarvestTask-ContainerList-Header   {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    padding:10px 0;
}