@import '../../globals.scss';


.RowGroupRow-Container {
    display:flex;
    flex-direction: column;
    align-self:stretch;
    

    &.RowGroupRow-PlaceholderRow    {
        //position: absolute;
        /*visibility:hidden;*/
        width:100%;
        height:0px;
        overflow-y:hidden;
        .RowGroupRow-Header-PrimaryContent  {
            //flex-shrink: 0;
        }
        .RowGroupRow-Header-CellWrapper {
            overflow-x:visible;
        }
    }
}

.RowGroupRow-StatusContainer    {
    display: flex;
    padding: 6px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch; 

    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    border-color:transparent;

    &.RowGroupRow-StatusContainer-Active    {
        margin-bottom:2px;
    }
}

.RowGroupRow   {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    align-self: stretch; 
}

.RowGroupRow-Cards  {
    display: flex;
    padding: 2px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch; 

    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
}

.RowGroupRow-Header    {
    display: flex;
    align-items: stretch;
    gap:20px;

    @media (min-width: 767px) {
        .RowGroupRow-Header-SecondaryContent  {
            gap:20px;
        }
    }
    @media (max-width: 766px) {
        .RowGroupRow-Header-SecondaryContent  {
            gap:10px;
        }
    }
    
    align-self: stretch;
    overflow-x:hidden;
}


.RowGroupRow-Header-PrimaryContent  {
    display: flex;
    align-items: center;
    overflow-x:hidden;
} 

.RowGroupRow-Header-SecondaryContent-Cell   {
    display:flex;
}

.RowGroupRow-Header-ExpandedContentToggle-CellWrapper   {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    justify-content: center;
}

.RowGroupRow-Header-ExpandedContentToggle  {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    justify-content: center;
}
.RowGroupRow-Header-ExpandedContentToggle-Button  {    
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px; 
    padding:0px 20px;
    margin:0px -10px;
    width:10px;
    align-self: stretch;
    justify-content: center;
}

.RowGroupRow-Header-CellWrapper   {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    justify-content: center;
    overflow-x: hidden;
}

.RowGroupRow-Header-StatusContent   {
    display: flex;
    padding: 6px 10px 6px 0px;
    align-items: center;
    gap: 30px;
    overflow-x:hidden;

}
.RowGroupRow-Header-IdentifyingContent  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px; 
    overflow-x:hidden;
}


.RowGroupRow-Header-IdentifyingContent-Primary  {
    display: flex;
    flex-direction: column;
    overflow-x:hidden;
    align-self:stretch;
    flex: 1;
}


.RowGroupRow-Header-IdentifyingContent-Secondary  {
    display: flex;
    flex-direction: column;
    overflow-x:hidden;
    align-self:stretch;
}

.RowGroupRow-Header-IdentifyingContent-Title  {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px; 
    overflow-x:hidden;
}
.RowGroupRow-Header-IdentifyingContent-Title-Content  {
    
    color: #191C22;
    font-family: $main-font;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 

    overflow-x:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

}

.RowGroupRow-Header-IdentifyingContent-Title-AuxContent {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px; 
}

.RowGroupRow-Header-IdentifyingContent-StatusIndicator   {
    width:9px;
    height:9px;
    border-radius: 9px;
    flex-shrink:0;
}



.RowGroupRow-Header-IdentifyingContent-Subtitle  {
    display: flex;
    align-items: center;
    gap: 4px; 
    overflow-x:hidden;
}
.RowGroupRow-Header-IdentifyingContent-Subtitle-Content  {
    color: #4F5562;
    font-family: $main-font;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    padding-right:2px; 

    overflow-x:hidden;
    white-space: nowrap;
    text-overflow: ellipsis; 
}



.RowGroupRow-Header-SecondaryContent   {
    display: flex;
    align-self:stretch;
    justify-content: flex-end;
    align-items: center;
    flex: 1 0 auto;
    overflow-x:hidden;
}


.RowGroupRow-Header-Issues-Container    {
    display: flex;
    padding: 6px 20px 6px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch; 
    cursor:pointer;
}



.RowGroupRow-Header-Issues    {
    display: flex;
    align-self:stretch;
    justify-content: center;
    align-items: center;
    gap: 6px;
}
.RowGroupRow-Header-Issues-Content {
    color: var(--main-blue, #2E72D2);
    font-family: $main-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline; 
    white-space: nowrap;
}

.RowGroupRow-Header-AdditionalFunctions  {
    display: flex;
    align-items: center;
    gap: 4px; 
}
.RowGroupRow-Header-AdditionalFunctions-Button  {
    display: flex;
    align-items: center;
    gap: 10px; 
    padding-left:5px;
    padding-right:5px;
}


.RowGroupRow-ExtendedHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch; 
}
.RowGroupRow-ExtendedHeader-Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch; 
    border-top: 1px solid var(--section-border, #DDE3EB); 
}



.RowGroupRow-Content-Container   {
    display:flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch; 


    &.RowGroupRow-Content-Container-Expandable   {
        overflow-y:hidden;
        transition:height 250ms ease;
    }
}

.RowGroupRow-Content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-self:stretch;
}

.RowGroupRow-Content-Card   {
    display: flex;
    flex-direction: column;
    @media (min-width: 767px) {
        padding: 20px 20px;
        gap:20px;
    }
    @media (max-width: 766px) {
        padding: 10px 10px;
        gap:10px;
    }

    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch; 

    border-radius: 4px;
    border: 1px solid var(--section-border, #DDE3EB);
    background: #FFF; 
}

.RowGroupRow-Content-Card-H   {
    display: flex;
    flex-direction: row;
    @media (min-width: 767px) {
        padding: 20px 20px;
        gap: 20px;
    }
    @media (max-width: 766px) {
        padding: 10px 10px;
        gap: 10px;
    }

    justify-content: center;
    align-items: flex-start;
    align-self: stretch; 

    border-radius: 4px;
    border: 1px solid var(--section-border, #DDE3EB);
    background: #FFF; 
}


.RowGroupRow-Issues-Container    {
    display: flex;
    padding: 6px 20px 6px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch; 
}



.RowGroupRow-Issues    {
    display: flex;
    align-items: center;
    gap: 6px;
    cursor:pointer;
}
.RowGroupRow-Issues-Content {
    color: var(--main-blue, #2E72D2);
    font-family: $main-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline; 
    white-space: nowrap;
}