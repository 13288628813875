@import '../globals.scss';


.Tag {
    display: flex;
    padding: 4px 10px;
    justify-content: center;
    align-items: center;
    gap: 4px; 
    
    border-radius: 4px;
    background: #E7EDF9; 

    .Tag-Content    {
        font-family: $main-font;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 
    }


    .Tag-Neutral    {
        .Tag-Content    {
            color: var(--Text, #191C22);
        }
    }

    .Tag-RemoveButton   {
        display: flex;
        justify-self: center;
        align-self: Center;
        cursor:pointer;
        padding:5px;
        margin:-5px;
    }
}