@import '../../globals.scss';


.RowGroup-Container {
    display:flex;
    flex-direction: column;
    align-self:stretch;

    &:not(.RowGroup-NoContainer)>.RowGroup    {
        border: 1px solid var(--section-border, #DDE3EB);
        @media (min-width: 767px) {
            padding: 20px 15px;
        }
        @media (max-width: 766px) {
            padding: 20px 8px;
        }
    }
}

.RowGroup   {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px; 

    border-radius: 4px;
    background: #FFF; 
    overflow-y:hidden;


}

.RowGroup-Content   {
    display:flex;
    flex-direction: column;
    align-self:stretch;
    align-content: center;
    align-items: center;
    overflow-y:hidden;

    &.RowGroup-ContentSeperate  {
        gap:4px;
    }
}



.RowGroup-Header    {
    display: flex;
    padding: 0px 10px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}


.RowGroup-Header-Title  {
    display: flex;
    align-items: center;
    gap: 10px; 
    padding: 4px 0px;
    overflow-x:hidden;
}
.RowGroup-Header-Title-Content  {
    color: var(--text, #191C22);
    font-family: $main-font;
    font-size: 24px;
    font-style: normal; 
    font-weight: 500;
    line-height: normal; 

    overflow-x:hidden;
    white-space: nowrap;
    text-overflow: ellipsis; 
}


.RowGroup-Header-Settings  {
    display: flex;
    align-items: center;
    gap: 4px; 
}
.RowGroup-Header-Settings-Button  {
    display: flex;
    padding: 0 12px;
    align-items: center;
    gap: 10px; 
}


.RowGroup-ColumnHeaders-Container   {
    display: flex;
    align-items: center;
    align-self: stretch; 
    flex: 1 0 auto;

    border-radius: 4px;
    background: #F7F8FB;
    overflow-x:hidden;
    
}

.RowGroup-Content   {
    &:not(.RowGroup-ContentSeperate) {
        border-radius: 4px;
        border: 1px solid var(--section-border, #DDE3EB);
        >.RowGroup-ColumnHeaders-Container {
            border-bottom: 1px solid var(--section-border, #DDE3EB);        
        }
    }
    &.RowGroup-ContentSeperate>.RowGroup-ColumnHeaders-Container {
        border: 1px solid var(--section-border, #DDE3EB);
    }
}
.RowGroup-ColumnHeaders {
    display: flex;
    align-items: center;
    align-self: stretch; 
    position:relative;
    width:100%;
}


.RowGroup-ColumnHeader-Container {
    display:flex;
    flex-direction: column;
    position: absolute;
}
.RowGroup-ColumnHeader  {
    display:flex;
    flex-direction: column;
}

.RowGroup-ColumnHeader-Content  {
    display:flex;
    flex-direction: row;
    padding:7px 0;

    color: var(--text, #191C22);
    /* Cell Header */
    font-family: $main-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
}

.RowGroup-PreItemsContent   {
    display:flex;
    flex-direction: column;
    align-self: stretch;
    margin-top:4px;
    padding: 0px 6px;
}
.RowGroup-PreItemsContent-Header    {
    display: flex;
    padding: 6px 6px;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #F7F8FB;
}


.RowGroup-Items-Container   {
    display:flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch; 
    overflow-y:auto;
    
    border-radius: 4px;

    &.RowGroup-Items-Container-Expandable   {
        overflow-y:hidden;
        transition:height 250ms ease;

        background-color:#f7f8fb;
    }
}

.RowGroup-Items {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    position: relative;

    .RowGroupSubGroup-Container-Expanded:not(:last-child) {
        margin-bottom:20px;
    }
}

.RowGroup-Items-Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;


}


.RowGroup-Footer    {
    display: flex;
    padding: 0px 10px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    align-self: stretch; 
}

.RowGroup-Footer-Content    {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch; 

    border-top: 1px solid var(--section-border, #DDE3EB); 
}

.RowGroup-ExpandedStateToggle   {
    display: flex;
    padding: 10px 10px 10px 10px;
    margin-bottom:-10px;
    align-items: center;
    gap: 4px; 
    flex:1;
    justify-content: center;
    cursor:pointer;

    .RowGroup-ExpandedStateToggle-Label {
        color: #2E72D2;
        font-family: $main-font;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 
    }
}