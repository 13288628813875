@import '../../../globals.scss';


#CreateControlDeviceWalkthrough_Popup  {
 
    .Walkthrough-HeaderTitle {
        display:flex;
        gap:6px;
        span    {
            font-family: $main-font;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal; 
        }
        >span:nth-child(1)  {

        }
        >span:nth-child(2)  {
            color:#2E72D2;
        }
    }
}


.ManageRackWalkthrough_Popup-EthernetConnectionStatus   {
    display: flex;
    padding: 6px 20px 6px 10px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0; 

    border-radius: 4px;
    border: 1px solid var(--section-border, #DDE3EB);

    color: #191C22;
    font-family: $main-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
}



.ManageRackWalkthrough_Popup-RackPlacement-Racks    {
    display:flex;
    flex-direction: column;
    gap:2px;
}


.ManageRackWalkthrough_Popup-RackPlacement-Racks-Rack {
    display: flex;
    padding: 6px 20px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch; 

    border-radius: 4px;
    border: 1px solid var(--section-border, #DDE3EB);
    background: #FFF; 

    .ManageRackWalkthrough_Popup-RackPlacement-Racks-Rack-Name  {
        display: flex;
        align-items: center;
        gap: 10px; 

        color: #191C22;
        font-family: $main-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 
    }
    .ManageRackWalkthrough_Popup-RackPlacement-Racks-Rack-Position  {
        color: var(--dark-section-border, #9CA6B4);
        font-family: $main-font;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 
    }
}
.ManageRackWalkthrough_Popup-RackPlacement-ExistingRack {

}

.ManageRackWalkthrough_Popup-RackPlacement-MovingRack-Moving {
    border: 1px solid var(--main-blue, #2E72D2);
    position:fixed;
    z-index:1000;
}
.ManageRackWalkthrough_Popup-RackPlacement-MovingRack-Still {
    border: 1px solid var(--main-blue, #2E72D2);
    cursor:pointer;  
}

.ManageRackWalkthrough_Popup-RackPlacement-Racks-DropIndicator  {
    display:flex;
    flex:1;
    height:0;
    border: 2px solid var(--main-blue, #2E72D2);
    margin:5px 10px;
    pointer-events: none;
}