@import '../../../globals.scss';





#HVACStatus  {
    display:flex;
    flex-direction: column;
    overflow-y:hidden;
    flex:1;
}

.HVACStatus-NoGroupsContent  {
    display: flex;
    align-self:stretch;
    flex:1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 19px; 

    >span:nth-child(1)   {
        font-family:"$main-font";
        color: #191C22;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal; 
    }
}



.HVACStatus-HVACs {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    overflow-y:auto;
    align-self:stretch;
    flex:1;
}

.HVACStatus-HVAC  {
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    gap: 20px; 
    align-self:stretch;

    border-radius: 4px;
    border: 1px solid var(--section-border, #DDE3EB);
    background: #FFF; 

    .GrowRacksStatus-VerticalRackGroup-Header   {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch; 

        .GrowRacksStatus-VerticalRackGroup-Header-Title {
            display: flex;
            align-items: center;
            gap: 10px; 
            &:not(.TitleInEditMode)   {            
                padding: 4px 60px 4px 10px;
            }
        }

        .GrowRacksStatus-VerticalRackGroup-Controls {
            display: flex;
            align-items: center;
            gap: 4px; 
        }
    }

    .GrowRacksStatus-VerticalRackGroup-Racks  {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: center;
        gap: 2px;
        align-self: stretch;  
        overflow-x:hidden;
    }

    .GrowRacksStatus-VerticalRackGroup-Rack {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2px;
        align-self: stretch;

        .GrowRackStatus-VerticalRackGroup-RackInfoContent   {
            display: flex;
            padding: 6px 20px;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;  

            border-radius: 4px;
            border: 1px solid var(--section-border, #DDE3EB);
            background: #FFF; 
            overflow-x:scroll;
        }

        .GrowRackStatus-VerticalRackGroup-RackIdentityInfo  {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 21px; 

            .GrowRackStatus-VerticalRackGroup-RackIdentityInfo-Name  {
                >span   {
                    color: #191C22;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal; 
                }
            }
        }

        .GrowRackStatus-VerticalRackGroup-RackStatus    {
            display: flex;
            align-items: center;
            gap: 6px; 

            >span  {
                &.Online    {
                    color: #4DBE3B;
                }
                &.Offline   {
                    color: #EC3C3C; 
                }
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal; 
            }
        }

        .GrowRackStatus-VerticalRackGroup-ManageControls  {
            display: flex;
            align-items: center;
            gap: 20px; 

        }



        .GrowRackStatus-VerticalRackGroup-RackStatusContent {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 2px; 
        }
    }

    .GrowRacksStatus-VerticalRackGroup-FooterControls   {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch; 

        >div:nth-child(1)   {
            display: flex;
            align-items: flex-end;
            gap: 6px; 
        }

        >div:nth-child(2)   {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 7px; 
        }

        >div:nth-child(3)   {
            display: flex;
            align-items: flex-end;
            gap: 6px; 
        }
    }
}
