@import '../globals.scss';

.TimeAxis {
    display: flex;
    flex-grow: 1;
    align-self:stretch;
    flex-direction: column;

    background:$true-white;

    gap:2px;
}
.TimeAxis-MainAxis  {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    align-content:stretch; 
    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #F7F8FB;
    
    >canvas    {

    }
}
.TimeAxis-SubAxis   {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    align-self: stretch; 
    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #F7F8FB; 
}