@import '../../globals.scss';


.GroupedOptions {
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;

    /* Inside auto layout */
    flex: none;
    order: 0;

    &.GroupedOptions-Selected .GroupedOptions-Content  {
        background: $neutral-100;
    }

    .GroupedOptions-Content {
        display:flex;
        flex-direction: row;
        align-items:stretch;

        /* Base/True White */
        background: #FFFFFF;
        /* Neutral/Neutral 300 */
        border: 1px solid $neutral-300!important;
        border-radius: 4px;

        margin:0;
        gap: 4px;

        /* shadow-tertiary */
        box-shadow: 16px 20px 16px rgba(187, 193, 204, 0.01), 7px 9px 12px rgba(187, 193, 204, 0.01), 2px 2px 6px rgba(187, 193, 204, 0.01), 0px 0px 0px rgba(187, 193, 204, 0.01);

        

    }

    .GroupedOptions-Main    {
        display:flex;
        flex-direction: row;
        align-items:center;

        padding: 8px 4px 8px 8px;
        
        cursor:pointer;
    }

    .GroupedOptions-Display  {
        font-family: $main-font;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */

        /* Text/Text-Default */
        color: $text-neutral;
    }

    .GroupedOptions-DropDown    {
        display:flex;
        align-items: center;
        cursor:pointer;
        border-left:1px solid $neutral-300;
        padding: 8px 4px 8px 4px;
    }

    .GroupedOptions-Prefix   {
        

        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */

        /* Neutral/Neutral 700 */
        color: $neutral-700;


    }

    .GroupedOptions-Suffix  {
    }

}

.GroupedOptions-DropDownContent {
    padding:8px;

    .GroupedOptions-DropDownContent-Item   {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 16px;
        gap: 16px;

        align-self: stretch;
        flex-grow: 0;

        cursor:pointer;

        &:hover {
            background-color:$neutral-50;
        }

        .GroupedOptions-DropDownContent-ItemCheckbox    {
            .MuiCheckbox-root   {
                padding:0;
            }
        }

        .GroupedOptions-DropDownContent-ItemDisplay {
            font-family: $main-font;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            /* identical to box height, or 143% */

            /* Text/Text-Default */
            color: $text-neutral;


        }
    }
}