@import '../globals.scss';


#SelectGrowPlans  {
    display:flex;
    flex-direction: column;
    align-content: stretch;
    flex-grow: 1;
    max-height:100%;
    align-self:stretch;
}

.SelectGrowPlans-RowPullOut {
    justify-content: stretch!important;
    .SelectGrowPlans-RowPullOut-Button   {
        border:1px solid $neutral-300;
        border-radius:4px;
        flex:1;
    }

}