@import '../../globals.scss';



.GrowZoneRecipeManager-PageSubtitle {
    display: flex;
    align-items: center;
    gap: 4px; 

    >span   {
        color: #4F5562;
        font-family: $main-font;
        font-size: 14px;
        font-style: italic;
        font-weight: 400;
        line-height: normal; 
    }
}

.GrowZoneRecipeManager-PageWrapper  {
    display: flex;
    margin:0px 20px 20px 20px; 
    padding: 15px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    flex: 1;
    align-self: stretch; 
    overflow-y: hidden;

    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #FFF;
}

.GrowZoneRecipeManager-PageContent  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex: 1;
    align-self: stretch;
    overflow-y: hidden;
}



.GrowZoneRecipeManager-Toolbar  {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch; 
}

.GrowZoneRecipeManager-ActiveGrowDisplay-Container    {
    display: flex;
    padding: 9px;
    justify-content: center;
    align-items: center;
    gap: 6px; 

    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);

    >div    {
        text-align: center;
        font-family: $main-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal; 
    }
    .GrowZoneRecipeManager-ActiveGrowDisplay-Label   {
        color: var(--Dark-Section-Border, #9CA6B4);
    }
    .GrowZoneRecipeManager-ActiveGrowDisplay-Empty   {
        color: #191C22; 
    }
    .GrowZoneRecipeManager-ActiveGrowDisplay-Value   {
        color: var(--Main-Blue, #2E72D2);
        cursor:pointer;
    }
}



.GrowZoneRecipeManager-ChartZoomLevel-Display   {
    color: #191C22;
    font-family: $main-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


.GrowZoneRecipeManager-ManagerContent   {
    display: flex;
    position: relative;
    align-items: flex-start;
    gap: 20px;
    flex: 1;
    align-self: stretch; 
    align-items: stretch;
    overflow-y: hidden;
}


.GrowZoneRecipeManager-SetpointLegend-Container {
    display:flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    overflow:hidden;
    z-index:99; 
}
.GrowZoneRecipeManager-SetpointLegend-Container, .GrowZoneRecipeManager-SetpointLegend-ExpandToggleContainer    {
    transition:width 250ms ease;
    overflow:hidden; 
}
.GrowZoneRecipeManager-SetpointLegend {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    align-self: stretch;
    width: max-content;
    height:100%;
}
.GrowZoneRecipeManager-SetpointLegend-ContentWrapper {
    display:flex;
    flex-direction: column;
    justify-content:flex-start;
    flex:1;
    gap:10px;
    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #FFF; 
    overflow:hidden; 
    padding-bottom:19px;
}
.GrowZoneRecipeManager-SetpointLegend-Header  {
    display: flex;
    padding: 5px;
    justify-content: center;
    align-items: stretch;
    gap: 10px;
    align-self: stretch; 

    border-bottom: 1px solid var(--Section-Border, #DDE3EB);
    background: #F7F8FB; 
}
.GrowZoneRecipeManager-SetpointLegend-Header-Toggle   {
    display:flex;
    width: 17px;
    height: 17px;
    align-items:center;
}
.GrowZoneRecipeManager-SetpointLegend-ExpandToggleContainer    {
    position:absolute;
    left:0;
    top:0;
    z-index:1000;
}
.GrowZoneRecipeManager-SetpointLegend-ExpandToggle    {
    position:relative;
    display: flex;
    padding-bottom: 0px;
    flex-direction: column;
    justify-content: flex-start;
    width: max-content;
    flex:0 0 auto;
    align-items: stretch;
    align-self: stretch; 
    z-index:1999;
}
.GrowZoneRecipeManager-SetpointLegend-ExpandToggle-Content   {
    display: flex;
    justify-content: space-between;
    align-items: center; 
    border-radius: 4px 4px 4px 0px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #F7F8FB; 
}
.GrowZoneRecipeManager-SetpointLegend-ExpandToggle-Button   {
    display:flex;
    flex-direction: column;
    margin: 9px 10px;
    width: 17px;
    height: 17px;
    align-items:center;
}


.GrowZoneRecipeManager-SetpointLegend-Content   {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    flex: 1;
    align-self: stretch; 
    overflow-y:auto;
}


.GrowZoneRecipeManager-SetpointLegend-SetpointToggles {
    display: flex;
    margin: 4px 16px;
    padding: 4px 6px;
    flex-direction: column;
    align-items: center;
    flex: 1;
    align-self: stretch; 
    gap:10px;

    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #F7F8FB; 
}

.GrowZoneRecipeManager-SetpointLegend-SetpointToggles-Content {
    display: flex; 
    flex-direction: column;
    align-items: center;
    align-self: stretch;
}


.GrowZoneRecipeManager-SetpointLegend-SetpointToggles-OptionalButtons {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-self: stretch;
    justify-content:flex-end;
}


.GrowZoneRecipeManager-SetpointLegend-SetpointToggle-Container  {
    display: flex;
    padding: 4px 6px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    align-self: stretch; 
    border-radius: 4px;
    border: 1px solid transparent;

    &.GrowZoneRecipeManager-SetpointLegend-SetpointToggle-Active    {
        border-color: var(--Main-Blue, #2E72D2);
        background: var(--Lihgt-blue-filler, #E4EAF5);
    }
}

.GrowZoneRecipeManager-SetpointLegend-SetpointToggle  {
    display: flex;
    padding: 6px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 10px;
    align-self: stretch;

    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #FFF;

}

.GrowZoneRecipeManager-SetpointLegend-SetpointToggle-Toggle  {
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1 0 0; 
}

.GrowZoneRecipeManager-SetpointLegend-SetpointToggle-Toggle-Identifier  {
    display: flex;
    align-items: center;
    gap: 6px;
    flex: 1 0 0; 
    cursor:pointer;
}

.GrowZoneRecipeManager-SetpointLegend-SetpointToggle-Toggle-Identifier-Indicator    {
    width: 13px;
    height: 13px;
    border-radius:13px;
}

.GrowZoneRecipeManager-SetpointLegend-SetpointToggle-Toggle-Identifier-Label  {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; 

    overflow: hidden;
    color: #191C22;
    text-overflow: ellipsis;
    font-family: $main-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
    white-space: nowrap;
} 

.GrowZoneRecipeManager-SetpointLegend-LightingZone-Content  {
    display:flex;
    flex:1;
    flex-direction: column;
    gap:10px;
    padding:0 9px;
}

.GrowZoneRecipeManager-SetpointLegend-LightingZone-ToggleState-Container    {
    display:flex;
    flex-direction: column;
    align-self: stretch;
    justify-content: stretch;   
}

.GrowZoneRecipeManager-SetpointLegend-LightingZoneCompositionControl  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px; 
    flex: 1 0 0;
    align-self:stretch;
}

.GrowZoneRecipeManager-SetpointLegend-LightingZoneCompositionControl-Items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    gap: 8px;
    flex: 1 0 0; 
    align-self:stretch;
}


.GrowZoneRecipeManager-SetpointLegend-Subzones-Container    {
    display: flex;
    padding: 0px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch; 
}

.GrowZoneRecipeManager-SetpointLegend-Subzones-Selection  {
    display: flex;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch; 

    >div:nth-child(0)   {
        color: var(--Text, #191C22);
        font-family: $main-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 
    }
}

.rowZoneRecipeManager-SetpointLegend-Subzones-Visual    {
    display: flex;
    align-items: stretch;
}





.GrowZoneRecipeManager-SetpointChartWrapper    {
    position: relative;
    display:flex;
    align-items: stretch;
    order:1;
    flex:1;
    touch-action: none;
}
.GrowZoneRecipeManager-SetpointChartContainer   {
    display:flex;
    align-items: stretch;
    flex:1;

    background-color: $true-white;
    border-radius:16px;
    /*overflow: hidden;
    isolation: isolate;*/

    #GrowZoneRecipeManager-SetpointChart   {
        display:flex;
        flex:1;
    }  
}

.GrowZoneRecipeManager-SetpointChart-OverlayBoard   {
    position: absolute;
    left:0;
    top:0;
    bottom:0;
    right:0;
    overflow:hidden;
}
.GrowZoneRecipeManager-SetpointChart-OverlayBoard-Content   {
    position: relative;
    left:0;
    top:0;
    bottom:0;
    right:0;
    width:100%;
    height:100%;
}

.GrowZoneRecipeManager-SetpointChart-CurrentTimeElapsedVisual   {
    position: absolute;
    top:0;
    height:auto;
    width:1px;
    background-color: #000;
}

.GrowZoneRecipeManager-SetpointChart-LightingMaxPPFDVisual  {
    position: absolute;
    height:0;
    
    .GrowZoneRecipeManager-SetpointChart-LightingMaxPPFDVisual-DialStart, .GrowZoneRecipeManager-SetpointChart-LightingMaxPPFDVisual-DialEnd    {
        position:absolute;
        top:-4px;
        width:8px;
        height:8px;
        border-radius: 8px;
        background-color:#916A00;
    }
    .GrowZoneRecipeManager-SetpointChart-LightingMaxPPFDVisual-DialStart {
        left:-4px;
    }
    .GrowZoneRecipeManager-SetpointChart-LightingMaxPPFDVisual-Line {
        left:0;
        right:0;
        top:-1;
        bottom:-1;
        background-color: #916A00; 
        height:2px;
    }
    .GrowZoneRecipeManager-SetpointChart-LightingMaxPPFDVisual-DialEnd {
        right:-4px;        
    }
}
.GrowZoneRecipeManager-SetpointChart-LightingConflictOverlay    {
    position: absolute;
    width:auto;
    height:auto;
    opacity: 0.2;
    background: var(--Red, #EC3C3C);
}



.GrowZoneRecipeManager-SetpointChart-ChartNotification-Board    {
    z-index:1001;
}

.GrowZoneRecipeManager-SetpointChart-ChartNotification-Container    {
    position:absolute;
    display: flex;
    padding: 16px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px; 
    
    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: var(--White, #FFF);
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.10);

    touch-action: auto;
}

.GrowZoneRecipeManager-SetpointChart-ChartNotification-Header   {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}
.GrowZoneRecipeManager-SetpointChart-ChartNotification-Header-Title {
    display: flex;
    align-items: center;
    gap: 10px;
}

.GrowZoneRecipeManager-SetpointChart-ChartNotification-Body {
    display:flex;
    flex-direction: column;
    align-items: center;
}

#GrowZoneRecipeManager-SetpointChart_TooltipBoard {
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    pointer-events:none;
    z-index:1000;
}

.GrowZoneRecipeManager-Tooltip-Container    {
    position:absolute;
    height:0;
    width:0;

    z-index:99999;
}
.GrowZoneRecipeManager-Tooltip  {
    display: flex;
    position:absolute;
    flex-direction: column;
    align-items: flex-start;

    min-width:100px;
    min-height:40px;

    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #FFF;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.10);
    &:not(.GrowZoneRecipeManager-TooltipEdit)  {
        padding: 5px;
        gap: 10px; 
    }
    &.GrowZoneRecipeManager-TooltipEdit  {
        pointer-events: auto!important;
        padding: 20px;
        gap: 16px;
    }
    &.GrowZoneRecipeManager-TooltipMoving   {
        pointer-events: none!important;
        padding: 20px;
        gap: 16px;
        opacity:0.8;
    }
}

.GrowZoneRecipeManager-Tooltip-Header   {
    color: #191C22;
    /* Nav Button Med */
    font-family: $main-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
}

.GrowZoneRecipeManager-Tooltip-Inputs   {
    display:flex;
    flex-direction: column;
    gap:12px;
}

.GrowZoneRecipeManager-Tooltip-Inputs-TimeDisplay   {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch; 
    >div:nth-child(1)  {
        color: #191C22;
        /* Nav Button Med */
        font-family: $main-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal; 
    }
    >div:nth-child(2)  {
        color: #191C22;
        font-family: $main-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 
    }
}

.GrowZoneRecipeManager-Tooltip-Inputs-TimeInputs   {
    display: flex;
    align-items: center;
    justify-content: stretch;
    gap: 6px;
    align-self: stretch;
}


.GrowZoneRecipeManager-Tooltip-Inputs-ValueDisplay   {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch; 
    >div:nth-child(1)  {
        color: #191C22;
        /* Nav Button Med */
        font-family: $main-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal; 
    }
}

.GrowZoneRecipeManager-Tooltip-Inputs-ValueInputs   {
    
}




.GrowZoneRecipeManager-Tooltip-Inputs-RangeDisplay   {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch; 
    >div:nth-child(1)  {
        color: #191C22;
        /* Nav Button Med */
        font-family: $main-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal; 
    }
    >div:nth-child(2)  {
        color: #191C22;
        font-family: $main-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 
    }
}

.GrowZoneRecipeManager-Tooltip-Inputs-RangeInputs   {

}


.GrowZoneRecipeManager-Tooltip-Inputs-Functions {
    display:flex;
    flex-direction: row;
    gap:4px;
    align-self:stretch;
    flex:1;
}


.GrowZoneRecipeManager-Tooltip-Inputs-Functions-Amplitude {
    display:flex;
    flex-direction: column;
    gap:4px;

    >div:nth-child(1)   {
        color: #191C22;
        /* Nav Button Med */
        font-family: $main-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal; 
    }
}


.GrowZoneRecipeManager-Tooltip-Inputs-Functions-Frequency {
    display:flex;
    flex-direction: column;
    gap:4px;

    >div:nth-child(1)   {
        color: #191C22;
        /* Nav Button Med */
        font-family: $main-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal; 
    }
}

.GrowZoneRecipeManager-Tooltip-AdvancedFunctions    {
    display: flex;
    padding: 4px 0px;
    align-items: flex-start;
    gap: 6px;
    align-self: stretch; 
    flex:1;
}

.GrowZoneRecipeManager-Tooltip-BlurDetect   {
    position:fixed;
    height:100%;
    width:100%;
    left:0;
    top:0;
    right:0;
    bottom:0;
    z-index:99998;
}


.GrowZoneRecipeManager-SetpointChart_Tooltip-Table {
    
}

.GrowZoneRecipeManager-SetpointChart_Tooltip-Unit  {
    margin-left:4px;
    font-family: $main-font;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 167% */

    /* Text/Text-Subdued */
    color: $text-neutral-subdued;


}

.GrowZoneRecipeManager-SetpointChart_SetpointControl   {
    .GrowZoneRecipeManager-SetpointChart_SetpointControl-Heading  {
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-content:center;
        flex-grow:1;

        font-family: $main-font;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        /* Text/Text-Default */
        color: $text-neutral;
    }

    .GrowZoneRecipeManager-SetpointChart_SetpointControl-Inputs    {
        display:flex;
        flex-direction: row;
        gap: 16px;
    }

    .GrowZoneRecipeManager-SetpointChart_SetpointControl-FunctionsContent  {
        display:flex;
        flex-direction: column;
        gap:4px;
    }
    .GrowZoneRecipeManager-SetpointChart_SetpointControl-Functions {
        display:flex;
        flex-direction: row;
        align-items: flex-start;
        gap:8px;
        
        >div    {
            display:flex;
            align-content:center;
            justify-content: center;
            cursor:pointer;

            svg {
                width: 20px;
                height: 20px;
            }
        }
    }

    .GrowZoneRecipeManager-SetpointChart_SetpointControl-FunctionInputs    {
        display:flex;
        flex-direction: row;
        gap: 16px;
    }
}



.GrowZoneRecipeManager-NoRecipeOverlay-Container {
    position:absolute;
    display:flex;
    width:100%;
    height:100%;
    align-items:center;
    justify-content: center;
}

.GrowZoneRecipeManager-NoRecipeOverlay  {
    display: flex;
    padding: 30px 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px; 

    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #FFF; 
}

.GrowZoneRecipeManager-NoRecipeOverlay-Header  {
    color: #000;
    font-family: $main-font;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
}

.GrowZoneRecipeManager-NoRecipeOverlay-Options  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px; 
}















.GrowZoneRecipeManager-ManageSelectedZonesPopup-Content-Container {
    display: flex;
    padding: 10px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
}



.GrowZoneRecipeManager-ManageSelectedZonesPopup-Content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
}


.GrowZoneRecipeManager-ManageSelectedZonesPopup-Content-ZonesSelectionContainer {
    display: flex;
    padding-top: 10px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch; 
}

.GrowZoneRecipeManager-ManageSelectedZonesPopup-Content-ZonesSelectionList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    align-self: stretch; 
}
.GrowZoneRecipeManager-ManageSelectedZonesPopup-Content-ZonesSelectionList-Header {
    overflow: hidden;
    color: var(--Text, #191C22);
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: $main-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
}

.GrowZoneRecipeManager-ManageSelectedZonesPopup-Content-ZonesSelectionList-Item {

}




.GrowZoneRecipeManager-ManageNutrients-Container    {
    display: flex;
    padding: 6px;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    flex: 1 0 0;
    align-self: stretch; 

    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #F7F8FB; 
    overflow-y:auto;
}

.GrowZoneRecipeManager-ManageNutrients-Header   {
    display: flex;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch; 
}


.GrowZoneRecipeManager-ManageNutrients-PartsList {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    align-self: stretch; 
}

.GrowZoneRecipeManager-ManageNutrients-Part {
    display: flex;
    padding: 14px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch; 
    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #FFF;
}



.GrowZoneRecipeManager-ManageNutrients-Part-Header {
    display: flex;
    align-items: center;
    gap: 10px; 
}

.GrowZoneRecipeManager-ManageNutrients-Part-Configure   {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.GrowZoneRecipeManager-ManageNutrients-Part-PercentOfAdjustment {
    display: flex;
    align-items: center;
    gap: 10px; 
}


.GrowZoneRecipeManager-ManageNutrients-Part-SubpartsEditing    {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap:16px;
    padding-left:30px;
    align-self: stretch; 
}


.GrowZoneRecipeManager-ManageNutrients-Part-SubpartsList    {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 10px;
    align-self: stretch; 
}


.GrowZoneRecipeManager-ManageNutrients-Part-Subpart    {
    display: flex;
    align-items: center;
    gap: 10px; 
}

.GrowZoneRecipeManager-ManageNutrients-Part-Subpart-Configure   {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    flex:1; 
}

.GrowZoneRecipeManager-ManageNutrients-Part-Subpart-PercentOfAdjustment {
    display: flex;
    align-items: center;
    gap: 10px;  
}





.GrowZoneRecipeManager-ManageNutrients-IsEditing {
   
}
