@import '../globals.scss';


.Badge {
    /* Auto layout */
    display: flex;
    flex-shrink:1;

    > div   {
        display:flex;
        align-self:center;
        align-items: center;
        padding: 2px 8px;
        border-radius: 10px;
        overflow:hidden;

        > span    {
            font-family:  $main-font;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal; 
            
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    






    &.Badge-Medium  {
        > div > span {
            font-size: 14px;
            /* identical to box height, or 143% */
        }
    }
    &.Badge-Small   {
        > div > span {
            font-size: 12px;
            /* identical to box height, or 133% */
        }
    }


    &.Badge-Neutral  {
        > div   {
            /* Accent/Neutral */
            background: $accent-neutral;
            >span   {
                color:#000;
            }
        }
    }
    &.Badge-Active  {
        > div   {
            /* Accent/Highlight */
            background: $interactive;
            >span   {
                color: $true-white;
            }
        }
    }
    &.Badge-Success  {
        > div   {
            /* Surface/Success */
            background: $surface-success;
        }
    } 
    &.Badge-Success-Sub  {
        > div   {
            /* Surface/Success-Sub */
            background: $surface-success-subdued;
        }
    }
    &.Badge-Critical  {
        > div   {
            /* Surface/Critical */
            background: $surface-critical;
        }
    }
    &.Badge-Warning  {
        > div   {
            /* Surface/Warning */
            background: $surface-warning;
        }
    }
    &.Badge-Info  {
        > div   {
            /* Surface/Warning */
            background: $surface-highlight;
        }
    }



    &.Badge-Link    {
        border-radius: 20px;
        border: 1px solid var(--Main-Blue, #1D65F0);
        background: #EEF3FF;

        cursor:pointer;


        > div > span    {
            color: #093281;
        }
    }

    &.Badge-Active-Green    {
        border-radius: 10px;
        background: #C6E8CB;

        > div > span    {

        }
    }
    
}