@import '../../globals.scss';


.TextInput {
    box-sizing: border-box;


    display: flex;
    padding: 10px 16px 10px 10px;
    align-items: center;
    align-self: stretch; 

    margin:1px;
    border-radius: 4px;
    border: 1px solid #DDE3EB;
    background: #FFF;
    overflow-x: hidden;

    flex-shrink: 0;

    >div {
        display:flex;
        flex:1 1 auto;
        align-self:stretch;
        overflow-x:hidden;
    }

    &.TextInput-Focused   {
        border-color:$main-blue;
        margin:0px;
        border-width:2px;
        z-index:99999;
    }

    &.TextInput-Disabled    {
        opacity:0.4;
        cursor:default;
        pointer-events: none;
    }

    
    &.TextInput-Inactive    {
        outline-color: #DDE3EB;
        background: rgba(255, 255, 255, 0.40);
        cursor:default;
        pointer-events: none;
    }

    .TextInput-Content  {
        display:flex;
        gap: 4px;
        flex:1;
        align-self: stretch;
        overflow-x:hidden;
    }

    .TextInput-Prefix   {
        display:flex;
        svg {
            /* Inside auto layout */
            flex: none;
            order: 0;
            flex-grow: 0;
        }
    }

    .TextInput-DisplayArea  {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        align-self:stretch;
        padding: 0px;
        gap: 4px;
        flex:1;
        overflow-x:hidden;
        
        input, textarea {
            color: #191C22;
            font-family: $main-font;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal; 

            display: flex;
            align-items: center;
            align-self:stretch;

            flex: 1;


            border:0;
            margin:0;
            padding:0;
            background:none;
            -webkit-appearance: none;
            -moz-appearance: none;
            outline: none;
        }

        textarea {
            width:100%;
            resize: none;
        }
    }


    .TextInput-SubmitButton   {
        display: flex;
        overflow-y:hidden;
        max-height:100%;
        cursor:pointer;
    }

    .TextInput-SubmitButton-IconButton  {
        padding: 5px 7px;
        margin: -5px -7px;
        justify-content: center;
        align-items: center;
        gap: 10px; 

        border-radius: 3px;
        border: 1px solid var(--Section-Border, #DDE3EB);
        background: #F7F8FB;
    }

}


.TextInput-BlurDetect   {
    position:fixed;
    height:0;
    width:0;
    &.TextInput-BlurDetect-Active   {
        height:100%;
        width:100%;
        left:0;
        top:0;
        right:0;
        bottom:0;
        z-index:99998;
    }
}