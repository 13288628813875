@import '../../../globals.scss';
@import '../../../components/DataStatus/DataStatus.scss';

.BladeInstance_StatusContent    {
    display: flex;
    align-items: flex-start;
    gap: 2px;
    align-self: stretch;
    padding:10px;
}

.BladeInstance_StatusContent-Column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    flex: 1 0 0;
}



.BladeInstance-EventsContent    {
    display: flex;
    @media (min-width: 767px) {
        padding: 20px;
    }
    @media (max-width: 767px) {
        padding: 10px 6px;
    }
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.BladeInstance-EventsContent-Header {
    display: flex;
    align-self: stretch;


    @media (min-width: 767px) {
        justify-content: space-between;
        align-items: flex-start;
    }
    @media (max-width: 767px) {
        align-items: center;
        gap: 2px;
    }
}

.BladeInstance-EventsContent-Filters    {
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
}

.BladeInstance-EventsContent-EventsRowGroup {
    display:flex;
    flex-direction: column;
    flex:1;
    align-self: stretch;
}