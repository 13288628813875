@import '../../../globals.scss';


#ManageNDSWalkthrough_Popup  {
 
    .Walkthrough-HeaderTitle {
        display:flex;
        gap:6px;
        span    {
            font-family: $main-font;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal; 
        }
        >span:nth-child(1)  {

        }
        >span:nth-child(2)  {
            color:#2E72D2;
        }
    }
}


.ManageNDSWalkthrough_Popup-EthernetConnectionStatus   {
    display: flex;
    padding: 6px 20px 6px 10px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0; 

    border-radius: 4px;
    border: 1px solid var(--section-border, #DDE3EB);

    color: #191C22;
    font-family: $main-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
}



.ManageNDSWalkthrough_Popup-ManagePorts-Ports    {
    display:flex;
    flex-direction: column;
    gap:2px;
    justify-content: space-between;
    margin:10px 0;
}
.ManageNDSWalkthrough_Popup-ManagePorts-Port    {
    display:flex;
    flex-direction: row;
    gap:8px;
    align-items:center;
    justify-content: flex-end;
    cursor:pointer;

}




.ManageNDSWalkthrough_Popup-ManageReservoirs-ReservoirColumns   {
    display:flex;
    flex-direction: row;
    gap:2px;
}

.ManageNDSWalkthrough_Popup-ManageReservoirs-ReservoirColumn   {
    display:flex;
    gap:8px;
    align-items:center;
    justify-content:center;
}


.ManageNDSWalkthrough_Popup-ManageReservoirs-Reservoirs    {
    display:flex;
    flex-direction: row;
    gap:2px;
}
.ManageNDSWalkthrough_Popup-ManageReservoirs-Reservoir    {
    display:flex;
    gap:8px;
    align-items:center;
    justify-content: flex-end;
    cursor:pointer;

}
