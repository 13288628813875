@import '../globals.scss';


#SelectSeeds  {
    display:flex;
    flex-direction: column;
    align-content: stretch;
    flex-grow: 1;
    max-height:100%;
    align-self:stretch;
}

