@import '../globals.scss';



@keyframes Standard_LoadingView-Spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.DataTable_Standard {
  position:relative;
  background:$true-white;
  border-radius:8px;
  border:1px solid $neutral-200;
  flex-grow: 1;
  
  .DataTable_Standard-LoadingView {
    position:absolute;
    left:0;
    right:0;
    bottom:0;
    top:0;
    z-index:1000;
    background-color: rgba(0, 0, 0, 0.1);
    transition: backdrop-filter 5s;
    backdrop-filter: blur(3px) opacity(1);

    display:flex;
    align-items: center;
    justify-content: center;

    >svg  {
      animation: Standard_LoadingView-Spin 1s linear infinite;
      width:32px;
      height:32px;
    }
  }

  .DataTable_Table {
    display:flex;
    flex-direction: column;
    flex-grow:1;
    overflow-x:auto;
    overflow-y:auto;
  }

  .DataTable_TableHeader  {
    display:flex;
    flex-grow:1;      
  }

  .DataTable_TableHeaderRow  {
    display:flex;
    flex-direction: row;
    flex-grow:1;      
  }

  .DataTable_TableHeaderCell  {
    display:flex!important;
    flex-direction: row;
    align-items:center;
    padding: 16px;
    gap: 10px;

    /* Text/Text-Default */
    color: $text-neutral;
    touch-action :none;

    &:last-child  {
      flex:1;
    }
  }

  .DataTable_TableCellSelect {
    margin: -30px;
    display: inline-block;
    input {
      margin: 30px;
    }
  }

  .DataTable_TableHeaderCellContent {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    touch-action :none;
    
    overflow:hidden;
    text-overflow: ellipsis;


    &:hover {
      color: $text-neutral-subdued;
      cursor:pointer!important;
    }    
    

    &.DataTable_TableHeaderSelectionCellContent {
      overflow:visible;
    }
  }

  .DataTable_TableHeaderCellSort {
    display:flex;
    flex-shrink:0;
    touch-action :none;
    overflow:hidden;

    color: $text-neutral-subdued;

    &:hover {
      color: $text-neutral;
      cursor:pointer!important;
    }    
  }


  .DataTable_TableBody  {
    display:flex;
    flex-grow:1;
  }

  .DataTable_TableGroups  {
    display:flex;
    flex-direction: column;
    flex-grow:1;
  }

  .DataTable_TableGroup {
    display:flex;
    flex-direction: column;
    flex-grow:1;
  }

  .DataTable_TableGroupHeader {
    background:$neutral-50;
    &:hover {
      background:$neutral-100;
    }
    
  }
  .DataTable_TableGroupHeaderToggle {
    cursor:pointer;
  }

  .DataTable_TableGroupCell {
    display:flex;
    box-sizing: border-box;
    align-items: center;

    padding: 8px 16px;
    gap: 10px;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    overflow:hidden;

    width:auto!important;
    /* identical to box height, or 143% */


    /* Text/Text-Default */
    color: $text-neutral;

    svg {
      width:20px;
      height:20px;
    }
  }

  .DataTable_TableGroupContent  {
    flex-direction: column;
    overflow-y:hidden;

    &.DataTable_TableGroupContentAnimation  {
      transition:height 0.5s linear;
    }
  }

  .DataTable_TableGroupRows {
    display:flex;
    flex-grow:1;
    flex-direction: column;
    order:0;

    
  }
  .DataTable_TableGroupFooter {
    display:flex;
    flex:0;
    flex-direction: column;
    order:1;
  }

  .DataTable_TableRows  {
    display:flex;
    flex-grow:1;
    flex-direction: column;
  }

  .DataTable_PinnedRows {
    display:flex;
    flex-direction: column;
    flex-grow:1;

    &.DataTable_PinnedRowsIndicator  {
      margin-bottom:5px;
      border-bottom:1px solid $neutral-300;
    }
  }

  

  .DataTable_UnpinnedRows {
    display:flex;
    flex-direction: column;
    flex-grow:1;
  }

  .DataTable_TableRow  {
    display:flex;
    flex-direction: row;
    flex-grow:1;
    flex-shrink:0;
    
    cursor:pointer;

    &:hover {
      background-color:rgba(0,0,0,0.05);
    }

  }

  .DataTable_TableCell  {
    position:relative;
    display:flex!important;
    flex-direction: row;
    box-sizing: border-box;
    justify-content: flex-start;

    padding: 8px 16px;
    gap: 32px;
      
    &:last-child  {
      flex:1 1;
    }
  }

  .DataTable_TableCellContent  {
    display:flex;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    overflow:hidden;

    /* Text/Text-Default */
    color: $text-neutral;
    text-overflow: ellipsis;
  }



  &.DataTable_Condensed  {
    .DataTable_TableHeaderCell  {
      padding:10px 12px;
    }
    .DataTable_TableGroupCell {
      padding:6px 12px;
    }
    .DataTable_TableCell {
      padding:6px 12px;
    }
  }
}


/*.DataTable_Standard {
    background:$true-white;
    border-radius:8px;
    border:1px solid $neutral-200;
    flex-grow: 1;
  
    .MuiDataGrid-columnSeparator  {
      visibility: hidden;
    }
  
    .MuiDataGrid-columnHeader    {
      &:focus, &:focus-within  {
          outline:none!important;
      }
    }
  
    .MuiDataGrid-cell {
      border-bottom:none!important;
      &:focus, &:focus-within  {
          outline:none!important;
      }
    }
  }
  
  .DataTable_Standard-Header  {
      border-right:0;
  }*/