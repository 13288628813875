@import '../../../globals.scss';

.BladeIssuesPopup-Issues    {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    overflow-y:auto;
}

.BladeIssuesPopup-Issue-Container    {
    display: flex;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 14px;
    align-self: stretch;
}


.BladeIssuesPopup-Issue {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.BladeIssuesPopup-Issue-Title   {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
}