@import '../globals.scss';


.PopupModal {
    
}


.PopupModal-ScreenWrapper {
    position:fixed; 
    left:0;
    right:0;
    bottom:0;
    top:0;
    z-index:1000;
    background-color: rgba(0, 0, 0, 0.5);
    transition: backdrop-filter 1s width 250ms ease, height 250ms ease;
    padding:20px;
    
    display:flex;
    align-content: stretch; 
    justify-content: stretch;
}

.PopupModal-Wrapper    {
    display:flex;
    /*align-items: center;*/
    max-width: 100%;
    max-height: 100%;
    
    flex-grow:1;
}

.PopupModal-Container   {

    display:flex;
    /*justify-content: center;*/
    max-width: 100%;
    max-height:100%;
    
    flex-grow:1;
}
.PopupModal_FullScreen {
    display:flex;
    width:100%;
    height:100%;
    position:fixed;
    left:0;
    top:0;
    bottom:0;
    right:0;
    align-content:stretch;
    justify-content: stretch;
    background: var(--background, #F0F1F2); 
    z-index: 1000;

    .PopupModal {
        flex:1;
        background:transparent;

        gap:30px;

        border:none;
        padding: 20px 40px; 
        
        border-radius: 0;
        border:none;
    }

    .PopupModal-Header  {
        padding: 0px;
        margin-bottom:0;

    }

    &.PopupModal_Mobile {
        .PopupModal {
            padding:20px 20px;   
        }
    }

    &.PopupModal_Tablet {

        .PopupModal {
            padding:40px 10%;
            .PopupModal-Header  {
            }
        }

        /*.PopupModal-Card-FooterButtons {
            .Button {
                >div    {
                    padding:16px 18px;
                    > div   {
                        font-size:18px;
                    }
                }
            }
        }*/
    }
    
    &.PopupModal_Desktop    {

        .PopupModal {
            padding:40px 10%;
            .PopupModal-Header  {
                
            }
        }


        /*.PopupModal-Card-FooterButtons {
            .Button {
                >div    {
                    padding:16px 18px;
                    > div   {
                        font-size:18px;
                    }
                }
            }
        }*/
    }

    
    &.PopupModal_WideDesktop    {

        .PopupModal {
            padding:50px 20%;
            .PopupModal-Header  {
                
            }
        }

    }

    &.PopupModal_CenterContent  {
        .PopupModal-StandardHeader  {
            align-items:center;
            align-content: center;
            justify-content: center;
            text-align: center
        }


        
    }



    .PopupModal-Content    {
        align-self:stretch;
    }

    .PopupModal-Card.PopupModal-CardFill {
        border-radius: 10px;
        border: 1px solid var(--section-border, #DDE3EB);
        background: #FFF; 
    }

    .PopupModal-Card-FooterButtons    {
        flex-direction: row;
        gap: 10px;        
    }
}



.PopupModal-Content-Wrapper    {
    align-items:center;
    flex:1;
    overflow-y:auto;
    align-self: stretch;
}

.PopupModal-Content    {
    align-items:center;
    flex:1;
    /*justify-content: center;*/
    >div    {
        
    }

    
}
.PopupModal-ContentContainer:not(.PopupModal-ContentContainer-Left)    {
    &:not(.PopupModal-ContentContainer-Vertical)    {
        align-items:center;
        align-self:center;
    }
    &.PopupModal-ContentContainer-Vertical   {
        align-items:stretch;
        align-self:stretch;
        align-content:center;
    }
}

.PopupModal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;


    


    @media (min-width: 767px) {
        padding: 20px 40px; 
    }
    @media (max-width: 767px) {
        padding: 20px 20px; 
    }

    gap: 20px;

    /* Base/True White */
    background: $true-white;

    border-radius: 4px;    
    border: 1px solid var(--Section-Border, #DDE3EB); 

    /*min-width:50%;*/
    min-height:30%;

    max-height:100%;
    max-width: 100%;

    overflow:hidden;
}

.PopupModal-Header  {
    display: flex;
    align-items: center;
    gap: 40px;
    align-self: stretch; 
    max-width: 100%;
    overflow: hidden;

    &:not(.PopupModal-NoSeperator)  {
        padding-bottom:13px;
        box-shadow: inset 0px -1px 0px #E1E3E5;
    }
    flex-shrink: 0;
}


.PopupModal-HeaderContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch; 
    max-width: 100%;
    overflow: hidden;
    flex:1;
}

.PopupModal-HeaderTitle   {
    display: flex;
    align-items: center;
    gap: 6px; 

    color: #191C22;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: $header-font;
    @media (min-width: 767px) {
        font-size: 22px;
    }
    @media (max-width: 767px) {
        font-size: 18px;
    }

    font-style: normal;
    font-weight: 600;
    line-height: normal; 
}


.PopupModal-HeaderDescription   {
    margin-bottom:10px;
    
    font-family: $main-font;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    /* Text/Text-Subdued */
    color: $text-neutral-subdued


}

.PopupModal-CloseButton {
    display:flex;
    order:1;
    align-self:stretch;
    align-items:center;
    padding:0 10px;
    margin:0 -10px;
    cursor:pointer;

    > svg {
    width: 20px;
    height: 20px;
    }
}


.PopupModal-HeaderSubContent   {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-self: stretch; 
}


.PopupModal-ContentWrapper  {
    display: flex;
    flex-direction: column;
    /*padding: 10px 0px;*/
    align-self: stretch;
    align-items: stretch;

    /* Base/True White */
    /* background: $true-white; */

    flex-grow:1;
    overflow-y:auto;
    scrollbar-width: thin;

    max-width: 100%;
}

.PopupModal-Content  {
    display: flex;
    flex-direction: column;
    /*padding: 10px 0px;*/
    align-self: stretch;
    align-items: stretch;

    flex-grow:1;
}

.PopupModal-FormItem    {
    display:flex;
    flex-direction: column;
    margin-bottom:16px;
    gap:10px;
    align-self:stretch;

    .PopupModal-FormItem-Question   {
        display:flex;
        flex-direction: column;

        color: #191C22;
        /* Cell Content 3 */
        font-family: $main-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 
    }
    .PopupModal-FormItem-Question-Help  {
        display:flex;
        flex-direction: column;
        color:$text-neutral-subdued;
        font-size:13px;
    }
    .PopupModal-FormItem-Answer   {
        display: flex;
        flex-direction: column;
        /*justify-content: center;*/
        margin-top:auto; margin-bottom: auto;
        align-items: stretch;
        gap: 6px;
        align-self: stretch; 
        .PopupModal-FormItem-Answer-Info   {
            color: #191C22;
            font-family: $main-font;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal; 
            opacity:0.4;
        }   
    }
}

.PopupModal-FormItem-Inline {
    display:flex;
    flex-direction: row;
    margin-bottom:16px;
    gap:8px;
    align-self:stretch;
    align-items: center;

    .PopupModal-FormItem-Question-Wrapper   {
        display:flex;
        flex-direction: column;
        order:1;
    }
    .PopupModal-FormItem-Question   {
        display:flex;
        flex-direction: column;

    }
    .PopupModal-FormItem-Question-Help  {
        display:flex;
        flex-direction: column;
        color:$text-neutral-subdued;
        font-size:13px;
    }
    .PopupModal-FormItem-Answer   {
        order:2;
        display:flex;
        flex-direction: column;
    }
}

.PopupModal-Footer  {
    display: flex;
    padding: 20px 0px 10px 0px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch; 
    /*margin-top:20px;*/

    &:not(.PopupModal-NoSeperator)  {
        box-shadow: inset 0px 1px 0px #E1E3E5;
    }

    order:2;
}


.PopupModal-FooterButtons-Container   {
    display:flex;
    align-items: stretch;
    flex-direction: column;
    gap:10px;
    align-self: stretch;
}
.PopupModal-FooterButtons    {
    display: flex;
    align-items: stretch;
    gap: 6px;
    align-self: stretch;
    flex: 1;
    @media (min-width: 767px) {

    }
    @media (max-width: 767px) {
        flex-direction: column;
    }


    .Button {

    }
}
.PopupModal-FooterButtons-Aux    {
    display:flex;
    flex-direction: row;
    align-self:center;
}








.PopupModal-ContentContainer    {
    display: flex;
    flex-direction: column;
    padding: 30px 40px;
    align-items: flex-start;
    gap: 10px;

    &.PopupModal-ContentContainer-Split {
        &:not(.PopupModal-ContentContainer-Vertical)    {
            flex-direction: row;
        }
    }

    &.PopupModal-ContentContainer-20 {
        gap:20px;
    }
    &.PopupModal-ContentContainer-30 {
        gap:30px;
    }
    &.PopupModal-ContentContainer-40 {
        gap:40px;
    }
    &.PopupModal-ContentContainer-50 {
        gap:50px;
    }

    &.PopupModal-ContentContainer-White  {
        border-radius: 4px;
        border: 1px solid var(--section-border, #DDE3EB);
        background: #FFF; 
    }

    &.PopupModal-ContentContainer-Vertical    {
        align-items:center;
        padding: 20px 25px;
    }
}

.PopupModal-StandardHeader  {
    display:flex;
    color: #191C22;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
    &.PopupModal-StandardHeader-Center {
        text-align: center;
    }
}

.PopupModal-StandardSubHeader  {
    display:flex;
    color: #191C22;
    font-family: $main-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal; 
}





