@import '../globals.scss';


.BadgeTimeline  {
    display: flex;
    flex-direction: row;
    padding: 0px;
    gap: 10px;
    cursor:pointer;
    overflow-x:auto;
    max-width:100%;
    scrollbar-width:thin;


    .Badge.Badge-Active > div   {
        transition: background-color 250ms ease;
    }
}
