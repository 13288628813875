@import '../../../globals.scss';


#CreateRecipePopup  {
 
  
} 

.CreateRecipePopup-Content  {
    display:flex;
    flex-direction: column;
    align-items:flex-start;
    gap:16px;
}


.CreateRecipePopup-DetailsForm  {
    display:flex;
    flex-direction: column;
    gap:24px;
}
