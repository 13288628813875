@import '../../globals.scss';



.GrowStatus-DataTile-Container  {
    display: flex;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0; 

    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
}

.GrowStatus-DataTile-Info-Container  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    align-self: stretch; 
}

.GrowStatus-DataTile-Info-Header    {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch; 
}

.GrowStatus-DataTile-Info-Title  {
    display: flex;
    align-items: center; 
    overflow-x:hidden;
    flex:1;

    >span   {
        color: #191C22;
        font-family: $main-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal; 
    }
}


.GrowStatus-DataTile-Info-Score  {
    display: flex;
    align-items: center; 
    
    >span   {
        color: #2E72D2;
        font-family: $main-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal; 
    }
}



.GrowStatus-DataTile-LiveStat-Container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch; 
}

.GrowStatus-DataTile-LiveStat-Current, .GrowStatus-DataTile-LiveStat-Setpoint   {
    display: flex;
    align-items:center;
    gap: 6px; 

    > div:nth-child(1)  {
        color: #191C22;
        font-family: $main-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 
    }

    > div:nth-child(2)  {
        display:flex;
        flex-direction: row;
        align-items:center;
        gap:1px;
        
        >div:nth-child(1)   {
            color: #191C22;
            font-family: $main-font;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: normal; 

        }

        >div:nth-child(2)   {
            color: #191C22;
            font-family: $main-font;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal; 

        }
    }
    > div:nth-child(3)  {
        color: var(--Main-Blue, #2E72D2);
        font-family: $main-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 
    }
}





.GrowStatus-DataTile-ScoreVisualization-Container   {
    position:relative;
    width:100%;
    height: 20px;
}

.GrowStatus-DataTile-ScoreVisualization-BarBackground {
    position:absolute;
    width:100%;
    height:100%;
    border-radius:4px;
    overflow:hidden;
}


.GrowStatus-DataTile-ScoreVisualization-Bar {
    position:absolute;
    height:100%;
}


.GrowStatus-DataTile-ScoreVisualization-SetpointLine    {
    position: absolute;
    width:2px;
    top:-5px;
    bottom:-5px;
    height:calc(100% + 10px);
    background-color: #000;
    left:50%;
    transform: translate(0);
}


.GrowStatus-DataTile-DataChart-Container    {
    display:flex;
    position:relative;
    width:100%;
    height: 100px;
}

.GrowStatus-DataTile-DataChart-Empty    {
    display:flex;
    align-self:stretch;
    background-color:#F7F8FB;
    width:100%;
    height: 100px;
}





.GrowStatus_GrowProgress-PhotoTimeline  {
    display: flex;
    padding: 4px 10px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch; 

    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #F7F8FB;
}