@import '../globals.scss';


.Pill {
    display: flex;
    flex: 0 1 auto;
    > div   {
        display: flex;
        padding: 2px 8px;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;
        gap: 10px; 
        
        border-radius: 8px;
        background: #E8EBEF; 

        > span    {
            color: #191C22;
            font-family: $main-font;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal; 

            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    






    &.Badge-Medium  {
        > div > span {
            font-size: 14px;
            line-height: 20px;
            /* identical to box height, or 143% */
        }
    }
    &.Badge-Small   {
        > div > span {
            font-size: 12px;
            line-height: 16px;
            /* identical to box height, or 133% */
        }
    }


    &.Badge-Neutral  {
        > div   {
            /* Accent/Neutral */
            background: $accent-neutral;
        }
    }
    &.Badge-Active  {
        > div   {
            /* Accent/Highlight */
            background: $surface-highlight-subdued;
        }
    }
    &.Badge-Success  {
        > div   {
            /* Surface/Success */
            background: $surface-success;
        }
    } 
    &.Badge-Success-Sub  {
        > div   {
            /* Surface/Success-Sub */
            background: $surface-success-subdued;
        }
    }
    &.Badge-Critical  {
        > div   {
            /* Surface/Critical */
            background: $surface-critical;
        }
    }
    &.Badge-Warning  {
        > div   {
            /* Surface/Warning */
            background: $surface-warning;
        }
    }
    &.Badge-Info  {
        > div   {
            /* Surface/Warning */
            background: $surface-highlight;
        }
    }


    
}