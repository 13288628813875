@import '../../globals.scss';









#GrowZonesStatus  {
    display:flex;
    flex-direction: column;
    overflow-y:hidden;
    flex:1;
}

.GrowZonesStatus-NoGroupsContent  {
    display: flex;
    align-self:stretch;
    flex:1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 19px; 

    >span:nth-child(1)   {
        font-family:$main-font;
        color: #191C22;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal; 
    }
}


.GrowZonesStatus-VerticalRackGroups {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    overflow-y:auto;
    align-self:stretch;
    flex:1;
}


.GrowZonesStatus-VerticalRackGroup-Zone-Racks   {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    gap: 6px;
    .GrowZonesStatus-VerticalRackGroup-Zone-Rack    {
        overflow-x:hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right:2px;
    }
}



.GrowZonesStatus-VerticalRackGroup-Zone-PauseInfo-State {
    color: #191C22;
    font-family: $main-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
    white-space:nowrap;
}
.GrowZonesStatus-VerticalRackGroup-Zone-PauseInfo-TimeRemaining {
    color: #191C22;
    font-family: $main-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
    white-space:nowrap;
}
.GrowZonesStatus-VerticalRackGroup-Zone-PauseInfo-AddTimeButton {
    display: flex;
    padding: 6px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch; 

    border-radius: 4px;
    border: 1px solid var(--section-border, #2E72D2);

    >span   {
        color: #2E72D2;
        font-family: $main-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 
        white-space:nowrap;
    }
}


.GrowZones-DataAnalytics-DataLegend-Wrapper   {
    display: flex;
    position:relative;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    align-self: stretch; 
}

.GrowZones-DataAnalytics-DataLegend-Container {
    display: flex;
    padding-bottom: 0px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    align-self: stretch; 
    overflow:hidden; 
}
.GrowZones-DataAnalytics-DataLegend-Container, .GrowZones-DataAnalytics-DataLegend-ExpandToggleContainer    {
    transition:width 250ms ease;
    overflow:hidden; 
}

.GrowZones-DataAnalytics-DataLegend {
    display: flex;
    padding-bottom: 0px;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow:1;
    width: max-content;
    align-items: stretch;
    align-self: stretch;
    overflow:hidden; 
}
.GrowZones-DataAnalytics-DataLegend-Content {
    display:flex;
    flex-direction: column;
    justify-content:flex-start;
    flex-grow:1;
    gap:10px;
    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #FFF; 
    overflow:hidden; 
}
.GrowZones-DataAnalytics-DataLegend-Header  {
    display: flex;
    padding: 9px 10px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch; 
    margin:-1px -1px -1px -1px;

    border-radius: 4px 4px 0px 0px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #F7F8FB; 

    .GrowZones-DataAnalytics-DataLegend-Header-Title    {
        color: #191C22;
        font-family: $main-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal; 
    }
    .GrowZones-DataAnalytics-DataLegend-Header-Toggle   {
        display:flex;
        width: 17px;
        height: 17px;
        align-items:center;
    }
}
.GrowZones-DataAnalytics-DataLegend-ExpandToggleContainer    {
    position:absolute;
    left:0;
    top:0;
}
.GrowZones-DataAnalytics-DataLegend-ExpandToggle    {
    position:relative;
    display: flex;
    padding-bottom: 0px;
    flex-direction: column;
    justify-content: flex-start;
    width: max-content;
    flex:0 0 auto;
    align-items: stretch;
    align-self: stretch; 
    z-index:100;
}
.GrowZones-DataAnalytics-DataLegend-ExpandToggle-Content   {
    display: flex;
    justify-content: space-between;
    align-items: center; 
    border-radius: 4px 4px 4px 0px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #F7F8FB; 
}
.GrowZones-DataAnalytics-DataLegend-ExpandToggle-Button   {
    display:flex;
    flex-direction: column;
    margin: 9px 10px;
    width: 17px;
    height: 17px;
    align-items:center;
}

.GrowZones-DataAnalytics-DataLegend-DataToggles-Container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-self: stretch;
    overflow-y:hidden;
}

.GrowZones-DataAnalytics-DataLegend-DataToggles {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-self: stretch; 
    padding: 10px 10px;
    gap:6px;
    overflow-y:auto;
}

.GrowZones-DataAnalytics-DataLegend-DataToggle-Container    {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-self: stretch;
}


.GrowZones-DataAnalytics-DataLegend-DataToggle-GroupToggle    {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    gap:20px;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    background: transparent;

    &.GrowZones-DataAnalytics-DataLegend-DataToggle-GroupToggle-Expanded    {
        border-color: var(--Main-Blue, #2E72D2);
        background: var(--Lihgt-blue-filler, #E4EAF5);
    }
}


.GrowZones-DataAnalytics-DataLegend-DataToggle-GroupToggle-Info {
    display: flex;
    padding: 6px 0px 6px 10px;
    align-items: center;
    gap: 10px; 
    cursor:pointer;
    flex:1 0 auto;
}
.GrowZones-DataAnalytics-DataLegend-DataToggle-GroupToggle-Info-ExpandToggle    {
    display:flex;
    align-content:center;
    justify-content: center;
}
.GrowZones-DataAnalytics-DataLegend-DataToggle-GroupToggle-Info-Key {
    display: flex;
    align-items: center;
    gap: 6px; 
}
.GrowZones-DataAnalytics-DataLegend-DataToggle-GroupToggle-Info-Key-Color {
    width: 13px;
    height: 13px; 
    border-radius:13px;
}
.GrowZones-DataAnalytics-DataLegend-DataToggle-GroupToggle-Info-Key-Title {
    overflow: hidden;
    color: #191C22;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: $main-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
}

.GrowZones-DataAnalytics-DataLegend-DataToggle-GroupToggle-ToggleGroupActiveButton  {
    display:flex;
    flex-direction: column;
    padding-right:10px;
}

.GrowZones-DataAnalytics-DataLegend-DataToggle-DataItems-Container  {
    display:flex;
    flex-direction: column;
    overflow-y:hidden;
    transition:height 200ms ease;
}

.GrowZones-DataAnalytics-DataLegend-DataToggle-DataItems  {
    display:flex;
    flex-direction: column;
}
.GrowZones-DataAnalytics-DataLegend-DataToggle-DataItems-Content    {
    display: flex;
    padding-top:6px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 6px; 
}






.GrowZones-DataAnalytics-DataLegend-DataToggle-DataItemToggle    {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    gap:20px;
}


.GrowZones-DataAnalytics-DataLegend-DataToggle-DataItemToggle-Info {
    display: flex;
    padding: 6px 0px 6px 10px;
    align-items: center;
    gap: 10px; 
    cursor:pointer;
    flex:1 0 auto;
}
.GrowZones-DataAnalytics-DataLegend-DataToggle-DataItemToggle-Info-Key {
    display: flex;
    align-items: center;
    gap: 6px; 
}
.GrowZones-DataAnalytics-DataLegend-DataToggle-DataItemToggle-Info-Key-Color {
    width: 13px;
    height: 13px; 
    border-radius:13px;
}
.GrowZones-DataAnalytics-DataLegend-DataToggle-DataItemToggle-Info-Key-Title {
    overflow: hidden;
    color: #191C22;
    text-overflow: ellipsis;
    font-family: $main-font;
    white-space: nowrap;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
}


.GrowZones-DataAnalytics-DataLegend-DataToggle-DataItemToggle-ToggleGroupActiveButton  {
    display:flex;
    flex-direction: column;
    padding-right:10px;
}






.GrowZones-DataAnalytics-ChartingArea-Container   {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    flex: 1 0 0;
    align-self: stretch; 

    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #FFF; 
}


.GrowZones-DataAnalytics-ChartingArea   {
    display: flex;
    padding: 20px 14px;
    flex-direction: column;     
    gap: 20px;
}





.GrowZones-DataAnalytics-DataTable  {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    align-self: stretch; 

    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #FFF; 
    overflow-x:hidden;
}

.GrowZones-DataAnalytics-DataTable-Info {
    display:flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch; 
}



.GrowZones-DataAnalytics-DataTable-Info-Header   {
    display: flex;
    padding: 6px 40px 6px 10px;
    align-items: center;
    gap: 40px;
    align-self: stretch; 

    border-radius: 4px 0px 0px 0px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    border-right:none;
    background: #F7F8FB; 
}

.GrowZones-DataAnalytics-DataTable-Info-Content {
    display:flex;
    flex-direction: column;
    align-self:stretch;
    justify-content: center;
    flex:1 0 auto;
    padding: 6px 20px 6px 20px;
}

.GrowZones-DataAnalytics-DataTable-Data {
    display:flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    flex:1 1 auto;
    overflow-x:auto;
}


.GrowZones-DataAnalytics-DataTable-Data-Header   {
    display: flex;
    padding: 8px 10px 8px 0px;
    align-items: center;
    gap: 40px;
    align-self: stretch; 

    border-radius: 0px 4px 0px 0px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    border-left:0px;
    background: #F7F8FB; 
}

.GrowZones-DataAnalytics-DataTable-Data-Header-ActiveDataItems   {
    display: flex;
    justify-content: space-between;
    align-items: center; 
    gap:0px;
}

.GrowZones-DataAnalytics-DataTable-Data-Header-ActiveDataItem    {
    display: flex;
    align-items: center;
    gap: 8px; 

}




.GrowZones-DataAnalytics-DataTable-Data-Header-ActiveDataItem-Key {
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #FFF; 
    padding: 8px 12px 8px 10px;
    gap: 6px; 
    overflow-x:hidden;
    flex:1 1 auto;
}
.GrowZones-DataAnalytics-DataTable-Data-Header-ActiveDataItem-Key-Color {
    flex:0 0 auto;
    width: 13px;
    height: 13px; 
    border-radius:13px;
}
.GrowZones-DataAnalytics-DataTable-Data-Header-ActiveDataItem-Key-Title {
    color: #191C22;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: $main-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
}





.GrowZones-DataAnalytics-DataTable-Data-Content  {
    display: flex;
    padding-left: 0px;
    justify-content: flex-start;
    align-items: center;
    gap: 0px;
    align-self: stretch; 
}

.GrowZones-DataAnalytics-DataTable-Data-Values   {
    display: flex;
    justify-content: space-between;
    align-items: center; 
    gap:0px;
    padding: 10px 0px;
}

.GrowZones-DataAnalytics-DataTable-Data-Values-DataItem-Value   {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0; 

    color: #191C22;
    font-family: $main-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
}



.BladeZoneInstance-ActiveGrows-Container    {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    padding:0 10px;
    margin-top:10px;
}
.BladeZoneInstance-ActiveGrows    {
    display: flex;
    flex-direction: column;
    max-width:100%;
    flex:1 1 auto;
}







.GrowZonesStatus-EventsTable    {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    align-self: stretch; 
    
    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: var(--White, #FFF); 
}

.GrowZonesStatus-EventsTable-TableHeader    {
    display: flex;
    padding: 10px 20px;
    align-items: center;
    gap: 30px;
    align-self: stretch; 
    
    border-radius: 4px 4px 0px 0px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #F7F8FB; 
}


.GrowZonesStatus-EventsTable-TableHeader-Title  {
    display: flex;
    align-items: center;

    color: #191C22;
    font-family: $main-font;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
}

.GrowZonesStatus-EventsTable-TableHeader-SearchContainer  {
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;  

    flex: 1 0 0; 
}

.GrowZonesStatus-EventsTable-TableHeader-Filters  {
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;

    overflow-x:auto;
}


.GrowZonesStatus-EventsTable-TableContent   {
    display: flex;
    padding: 0px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 6px;
    align-self: stretch; 
}



.GrowZonesStatus-EventsTable-EventItem   {
    display: flex;
    padding: 6px 0px;
    align-items: center;
    gap: 20px;
    align-self: stretch; 
}

.GrowZonesStatus-EventsTable-EventItem-EventDetails  {
    display: flex;
    align-items: center;
    gap: 20px;
    flex: 1 0 0; 
}

.GrowZonesStatus-EventsTable-EventItem-EventDetails-Date   {
    display: flex;
    align-items: center;
    gap: 12px; 

    > div  {
        color: #191C22;
        font-family: $main-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal; 
    }
}

.GrowZonesStatus-EventsTable-EventItem-EventDetails-Info    {
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1 0 0; 
}

.GrowZonesStatus-EventsTable-EventItem-EventDetails-Info-Type   {
    display:flex;

    color: var(--Red, #EC3C3C);
    text-align: right;
    font-family: $main-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
}

.GrowZonesStatus-EventsTable-EventItem-EventDetails-Info-Content    {
    display:flex;

    color: #191C22;
    font-family: $main-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
}










.GrowZones-Status-ZoneTypes   {
    display:flex;
    flex-direction: column;
    gap: 8px;
}


.GrowZones-Status-ZoneType   {
    display:flex;
    flex-direction: column;
    gap: 4px;

    .GrowZones-Status-ZoneType-Header   {
        display:flex;
        flex-direction: column;
        font-family: $main-font;
        font-style: normal;
        font-weight: 500;
    
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        /* Text/Text-Default */
        color: $text-neutral;
    }
}



.GrowZones-Status-ZoneType-Zones   {
    display:flex;
    flex-direction: column;
    gap: 8px;
    margin-left:20px;
}

.GrowZones-Status-GrowOutZone  {
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: stretch;
    gap:8px;
    padding: 12px 12px;

    /* Base/True White */
    background: $true-white;

    /* Accent/Neutral */
    border: 1px solid $accent-neutral;

    /* shadow-secondary */
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 4px;

    .GrowZones-Status-GrowOutZone-Header    {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 12px;
        order:1;
    }

    .GrowZones-Status-GrowOutZone-StatusIndicator  {
        width:10px;
        height:10px;
        border-radius: 10px;

        order:1;
    }

    .GrowZones-Status-GrowOutZone-Name  {
        display: flex;
        order:1;
        min-width:50px;
    }


    .GrowZones-Status-GrowOutZone-Content    {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 12px;
        order:2;
    }

}



.GrowZones-DataAnalytics    {
    display:flex;
    flex:1;
}



.GrowZones-Charts-ChartingArea   {
    display:flex;
    flex-direction: column;
    flex-grow:1;
    touch-action: none;
    gap:10px;
}

.GrowZones-Charts-MainChartingArea, .GrowZones-Charts-OverviewChartingArea {
    display:flex;
    background:$true-white;
    /*padding:10px 4px;*/
    border-radius:5px;
}


.GrowZones-Charts-MainChartingWrapper, .GrowZones-Charts-OverviewChartingWrapper  {
    /*padding:10px 4px;*/
    display:flex;
    flex-grow:1;
    flex-direction: column;
}

.GrowZones-Charts-MainChartingContent, .GrowZones-Charts-OverviewChartingContent  {
    display:flex;
    flex-grow:1;
    position:relative;
    box-sizing: border-box;
}

.GrowZones-Charts-MainChart, .GrowZones-Charts-OverviewChart    {
    flex-grow:1;
}

.GrowZones-Charts-MainChart-SubselectedEnergyArea   {
    position: absolute;
    left:0px;
    top:0px;
    right:0px;
    bottom:0px;
    box-sizing: content-box;
    pointer-events:none;
}


.GrowZones-Charts-MainChart-SubselectedEnergyArea-Background    {
    position:absolute;
    top:0;
    bottom:0;
    opacity: 0.4;
    background: #FADC8F; 
    pointer-events:none;
}

.GrowZones-Charts-MainChart-SubselectedEnergyArea-StartEdge, .GrowZones-Charts-MainChart-SubselectedEnergyArea-EndEdge {
    position:absolute;
    top:0;
    bottom:0;
    width:2px;
    transform:translate(-1px, 0);
    background-color: #F0B61F;
    pointer-events:none;
}


.GrowZones-Charts-MainChart-SubselectedEnergyArea-StartGrabber-Container, .GrowZones-Charts-MainChart-SubselectedEnergyArea-EndGrabber-Container {
    position:absolute;
    transform:translate(-50%, -50%);
}

.GrowZones-Charts-MainChart-SubselectedEnergyArea-StartGrabber-Pill, .GrowZones-Charts-MainChart-SubselectedEnergyArea-EndGrabber-Pill {
    display:block;
    position:relative;
    width:10px;
    height:30px;
    border-radius: 2px;
    background: #F0B61F; 
    &.active    {
        background: #BE8B06; 
    }  
    pointer-events: none;
}



.GrowZones-Charts-MainChart-SubselectedEnergyArea-StartGrabber-Pill-Dash1, .GrowZones-Charts-MainChart-SubselectedEnergyArea-EndGrabber-Pill-Dash1  {
    position:absolute;
    height:9px;
    width:1px;
    left:4.5px;
    top:15px;
    transform:translate(-2px, -50%);
    background:#fff;
    border-radius:1px;    
    pointer-events: none;
}
.GrowZones-Charts-MainChart-SubselectedEnergyArea-StartGrabber-Pill-Dash2, .GrowZones-Charts-MainChart-SubselectedEnergyArea-EndGrabber-Pill-Dash2  {
    position:absolute;
    height:9px;
    width:1px;
    left:4.5px;
    top:15px;
    transform:translate(2px, -50%);
    background:#fff;
    border-radius:1px;  
    pointer-events: none;
}


.GrowZones-Charts-MainChart-EnergyReadoutTooltip    {
    position:absolute;
    right:4px;
    margin-bottom:4px;

    display: flex;
    padding: 6px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px; 

    border-radius: 4px;
    border: 1px solid var(--Dark-Section-Border, #9CA6B4);
    background: #F7F8FB; 

    pointer-events: none;
    z-index:2000;
}

.GrowZones-Charts-MainChart-EnergyReadoutTooltip-Title  {
    color: #191C22;
    text-align: right;
    font-family: $main-font;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
}


.GrowZones-Charts-MainChart-EnergyReadoutTooltip-Values  {
    color: #191C22;
    text-align: right;
    font-family: $main-font;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
}



.GrowZones-Charts-MainCharting-OverlayContainer {
    position: absolute;
    left:0px;
    top:0px;
    right:0px;
    bottom:0px;
    box-sizing: content-box;
    pointer-events:none;
}

.GrowZones-Charts-MainCharting-Tooltip-Wrapper  {
    position:relative;
    pointer-events: none;
    width:0;
}
.GrowZones-Charts-MainCharting-Tooltip-Container    {
    display:flex;
    position:absolute;
}

.GrowZones-Charts-MainCharting-Tooltip-Content  {
    position:relative;
    display: flex;
    padding: 10px;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #FFF;
}
.GrowZones-Charts-MainCharting-Tooltip-DataItem {
    display:flex;
    gap:10px;
    align-items:center;
    &.GrowZones-Charts-MainCharting-Tooltip-DataItem-Active {
        .GrowZones-Charts-MainCharting-Tooltip-DataItem-Label   {
            font-weight:700;
            color:$main-blue;
        }
        .GrowZones-Charts-MainCharting-Tooltip-DataItem-Value   {
            color:$main-blue;
        }
        .GrowZones-Charts-MainCharting-Tooltip-DataItem-ActiveDataItemIndicator {
            visibility: visible;
        }
    }
}
.GrowZones-Charts-MainCharting-Tooltip-DataColorIndicator   {
    height: 13px;
    width: 13px;
    border-radius: 13px;
    align-self: center;
}
.GrowZones-Charts-MainCharting-Tooltip-DataItem-Label   {
    display:flex;
    gap:2px;
}
.GrowZones-Charts-MainCharting-Tooltip-DataItem-ActiveDataItemIndicator {
    width:5px;
    height:5px;
    border-radius:5px;
    background:$main-blue;
    align-self:center;
    visibility: hidden;
}

.GrowZones-Charts-MainCharting-ActivePointCrosshair-VerticalLine, .GrowZones-Charts-MainCharting-ActivePointCrosshair-HorizontalLine    {
    position:absolute;
    border-style:dashed;
    border-color:rgba(0,0,0,1);
    border-width:0;
}
.GrowZones-Charts-MainCharting-ActivePointCrosshair-VerticalLine    {
    width:0px;
    border-right-width:1.5px;
}
.GrowZones-Charts-MainCharting-ActivePointCrosshair-HorizontalLine    {
    height:0px;
    border-bottom-width:1.5px;
}

.GrowZones-Charts-OverviewChart-SubselectedArea    {
    position: absolute;
    left:0px;
    top:0px;
    right:0px;
    bottom:0px;
    touch-action: none;
    box-sizing: content-box;

    >canvas {
        width:100%;
        height:100%;
        box-sizing: content-box;
    }
}

.GrowZones-Charts-OverviewChart-Subselection-Wrapper    {
    position: absolute;
    left:0px;
    top:0px;
    bottom:0px;
    box-sizing: content-box;
}
.GrowZones-Charts-OverviewChart-Subselection-Container  {
    position:relative;
    height:100%;    
    width:100%;
}
.GrowZones-Charts-OverviewChart-Subselection-PreStartOverlay, .GrowZones-Charts-OverviewChart-Subselection-PostEndOverlay   {
    position: absolute;
    background-color: rgba(0,0,0,0.2);
    border:1px solid rgba(0,0,0,1.0);
    margin:-1px;
    width:auto;
    top:0;
}
.GrowZones-Charts-OverviewChart-Subselection-PreStartOverlay    {
    left:0;
}
.GrowZones-Charts-OverviewChart-Subselection-PostEndOverlay    {
    right:0;
}



.GrowZones-Charts-OverviewChart-Subselection-Content  {
    position:relative;
    height:100%;    
    width:auto;
    &.GrowZones-Charts-OverviewChart-Subselection-Content-Draggable {
        cursor:pointer;
        /*background-color:rgba(0,0,0,0.2);*/
    }
}

.GrowZones-Charts-OverviewChart-Subselection-LeftGrabber, .GrowZones-Charts-OverviewChart-Subselection-RightGrabber {
    position:absolute;
    height:100%;
    width:10px;
    z-index:10;
    >div   {
        position: absolute;
        top:12px;
        bottom:12px;
        width:5px;        
        background-color:rgba(0, 0, 0, 0.6);
    }
}
.GrowZones-Charts-OverviewChart-Subselection-LeftGrabber    {
    left:-5px;
    >div   {
        left:0;
        border-radius:5px 0 0px 5px;
    }
}
.GrowZones-Charts-OverviewChart-Subselection-RightGrabber    {
    right:-5px;
    >div   {
        right:0px;
        border-radius:0 5px 5px 0;
    }
}

.GrowZones-Charts-OverviewChart-Subselection-LeftGrabber:hover, .GrowZones-Charts-OverviewChart-Subselection-RightGrabber:hover {
    cursor:pointer;
    >div {
        background-color:rgba(0, 0, 0, 1.0);
    }
}



.GrowZones-Charts-OverviewChart-Subselection-RightGrabber    {
 >div   {
    
 }    
}



.GrowZones-Charts-MainChartingArea {
    flex-grow:1;
    flex-direction: column;
    order:0;
    min-height:250px;
}

.GrowZones-Charts-OverviewChartingArea {
    flex-basis:15%;
    min-height:70px;
    order:1;
} 


.GrowZones-Charts-OverviewChart    {
    touch-action: none;
}

.GrowZones-Charts-OverviewChart-SubselectedArea    {
    pointer-events:none;

}



.GrowZones-Charts-MainCharting-ActiveTimeDisplay    {
    display:flex;
    position:relative;
    flex-direction: row;
    align-self: stretch;
    align-items:center;
    z-index:1000;
}
.GrowZones-Charts-MainCharting-ActiveTimeIndicator {
    position:absolute;
    width:2px;
    transform: translate(-50%, 0);
    bottom:100%;
    background-color:#2E72D2;
    pointer-events: none;
}
.GrowZones-Charts-MainCharting-ActiveTimeLabel  {
    position:absolute;
    transform: translate(-50%, 4px);
    

}
.GrowZones-Charts-MainCharting-ActiveTimeLabel-Content  {
    display: inline-flex;
    padding: 6px 10px;
    justify-content: center;
    align-items: flex-start;
    gap: 10px; 

    border-radius: 4px;
    border: 1px solid var(--Main-Blue, #2E72D2);
    background: #FFF; 
    >span   {
        color: #191C22;
        text-align: right;
        font-family: $main-font;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 
    }

}
.GrowZones-Charts-OverviewCharting-DateSelectors    {
    display:flex;
    flex-direction: row;
    align-self: stretch;
    justify-content: space-between;
    align-items:center;
    z-index:1000;
    padding:4px 0px;
}
.GrowZones-Charts-OverviewCharting-DateSelector    {
    display: inline-flex;
    padding: 2px 6px;
    justify-content: center;
    align-items: center;
    gap: 10px; 

    cursor:pointer;

    background-color:#fff;
    border-radius: 4px;
    border: 1px solid var(--Lihgt-blue-filler, #E4EAF5);

    color: var(--Main-Blue, #2E72D2);
    font-family: $main-font;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
}




.GrowZones-Charts-ComponentToggles {
    display:flex;
    flex-direction:row;
    order:1;
    gap:10px;
    padding: 0px 10px 10px 10px;
}
.GrowZones-Charts-DataTypeColorIndicator   {
    width: 20px;
    height: 8px;
    
    border-radius: 6.89813px;
    
    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.GrowZones-Charts-EnergyDisplay    {
    display:flex;
    flex-direction: row;

    

    .GrowZones-Charts-EnergyDisplay-Value  {

    }
    .GrowZones-Charts-EnergyDisplay-P-Unit   {
        color:$text-neutral-subdued;
        font-size:12px;
        align-self:flex-end;
        padding-right:5px;
    }
    .GrowZones-Charts-EnergyDisplay-C-Unit   {
        color:$text-neutral-subdued;
        font-size:14px;
        align-self:center;
    }
}

.GrowZones-Charts-EnergySubselectionToggle {
    display:flex;
    flex-grow:1;
    &.GrowZones-Charts-EnergySubselectionActive {
        background-color:rgba(255, 180, 62, 0.2);
    }
}





.GrowZones-Charts-DrawingBoard {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    pointer-events:none;
}

.GrowZones-Charts-CurrentPositionIndicator {
    display:flex;
    flex-direction: column;
    position:absolute;
     
    background-color: $interactive;
}

.GrowZones-Charts-Tooltip  {
    display:flex;
    flex-direction: column;
    position:absolute;
    min-width:100px;
    min-height:40px;

    /* Base/True White */
    background: #FFFFFF;
    /* Neutral/Neutral 300 */
    border: 1px solid $neutral-300!important;
    border-radius: 4px;

    margin:0;
    gap: 4px;

    /* shadow-tertiary */
    box-shadow: 16px 20px 16px rgba(187, 193, 204, 0.01), 7px 9px 12px rgba(187, 193, 204, 0.01), 2px 2px 6px rgba(187, 193, 204, 0.01), 0px 0px 0px rgba(187, 193, 204, 0.01);        
}
.GrowZones-Charts-Tooltip-Table    {
    
}
.GrowZones-Charts-Tooltip-ValueDisplay {
    padding:0 6px;
}
.GrowZones-Charts-Tooltip-DateDisplay {
    padding:0 6px;

    font-family: $main-font;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 167% */

    /* Text/Text-Subdued */
    color: $text-neutral;
}
.GrowZones-Charts-Tooltip-Unit {
    margin-left:4px;
    font-family: $main-font;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 167% */

    /* Text/Text-Subdued */
    color: $text-neutral-subdued;
}

.GrowZones-Charts-TimeTooltip  {
    display:flex;
    flex-direction: column;
    position:absolute;

    transform:translate(-50%, -50%);

    /* Base/True White */
    background: #FFFFFF;
    /* Neutral/Neutral 300 */
    border: 1px solid $neutral-300!important;
    border-radius: 4px;

    margin:0;
    padding: 4px;

    /* shadow-tertiary */
    box-shadow: 16px 20px 16px rgba(187, 193, 204, 0.01), 7px 9px 12px rgba(187, 193, 204, 0.01), 2px 2px 6px rgba(187, 193, 204, 0.01), 0px 0px 0px rgba(187, 193, 204, 0.01);

    >div    {
        font-family: $main-font;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 167% */

        /* Text/Text-Subdued */
        color: $text-neutral-subdued;
    }
}






#GrowZones-Service  {
    display:flex;
    flex-direction: column;
    overflow-y:hidden;
    flex:1;
}

.GrowZones-Service-VerticalRackGroups   {
    display:flex;
    flex-direction: column;
    gap: 8px;
    overflow-y:scroll;
}


.GrowZones-Service-VerticalRackGroup   {
    display:flex;
    flex-direction: column;
    gap: 4px;

    .GrowZones-Service-VerticalRackGroup-Header   {
        display:flex;
        flex-direction: column;
        font-family: $main-font;
        font-style: normal;
        font-weight: 500;
    
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        /* Text/Text-Default */
        color: $text-neutral;
    }
}



.GrowZones-Service-VerticalRackGroup-Racks   {
    display:flex;
    flex-direction: column;
    gap: 8px;
    margin-left:20px;
}

.GrowZones-Service-VerticalRackGroup-Rack  {
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: stretch;
    gap: 4px;
    padding: 12px 12px;

    /* Base/True White */
    background: $true-white;

    /* Accent/Neutral */
    border: 1px solid $accent-neutral;

    /* shadow-secondary */
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 4px;

    .GrowZones-Service-VerticalRackGroup-Rack-Header    {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 12px;
        flex-grow:1;
        order:0;
    }

    
    .GrowZones-Service-VerticalRackGroup-Rack-Header-StatusIndicator  {
        width:10px;
        height:10px;
        border-radius: 10px;

        order:1;
    }
    
    .GrowZones-Service-VerticalRackGroup-Rack-Header-ServiceMode    {
        display:flex;
        flex:1;
        order:2;
        justify-content: flex-end;
    }


    .GrowZones-Service-VerticalRackGroup-Rack-Content    {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 12px;
        flex-grow:1;
        order:1;
    }


    .GrowZones-Service-VerticalRackGroup-Rack-Name  {
        display: flex;
        order:0;
        min-width:50px;
    }

}










.GrowZones-Config-VerticalRackGroups   {
    display:flex;
    flex-direction: column;
    gap: 8px;
}


.GrowZones-Config-VerticalRackGroup   {
    display:flex;
    flex-direction: column;
    gap: 4px;

    .GrowZones-Config-VerticalRackGroup-Header   {
        display:flex;
        flex-direction: column;
        font-family: $main-font;
        font-style: normal;
        font-weight: 500;
    
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        /* Text/Text-Default */
        color: $text-neutral;
    }
}



.GrowZones-Config-VerticalRackGroup-Racks   {
    display:flex;
    flex-direction: column;
    gap: 8px;
    margin-left:20px;
}

.GrowZones-Config-VerticalRackGroup-Rack  {
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    gap: 10px;

    /* Base/True White */
    background: $true-white;

    /* Accent/Neutral */
    border: 1px solid $accent-neutral;

    /* shadow-secondary */
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 4px;

    .GrowZones-Config-VerticalRackGroup-Rack-Content    {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        margin: 12px 12px;
        gap: 12px;
        flex-grow:1;
        order:1;
    }

    .GrowZones-Config-VerticalRackGroup-Rack-StatusIndicator  {
        width:10px;
        height:10px;
        border-radius: 10px;

        order:1;
    }

    .GrowZones-Config-VerticalRackGroup-Rack-Name  {
        display: flex;
        order:1;
        min-width:50px;
    }

    .GrowZones-Config-VerticalRackGroup-Rack-ZoneTypeSelection  {
        display: flex;
        order:2;
    }


    .GrowZones-Config-VerticalRackGroup-Rack-FunctionsContainer    {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin:12px 12px;
        order:2;
    }
    .GrowZones-Config-VerticalRackGroup-Rack-Functions {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;

    }
}

.GrowZones-Config-VerticalRackGroup-Rack-Mover {
    svg    {
        width:25px;
        height:25px;
    }
}


#GrowZones-Config-VerticalRackGroup-ReorderingRack   {
    position:fixed;
    z-index:101;
}
#GrowZones-Config-VerticalRackGroup-Reordering-BlockingInteractivity   {
    position:fixed;
    left:0;
    right:0;
    bottom:0;
    top:0;
    width:100%;
    height:100%;
    background-color: rgba(0,0,0,0.1);
    z-index:100;
}




