@import '../../globals.scss';


.DateInput {
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-self:flex-start;
    gap: 10px;
    position:relative;


    /* Base/True White */
    background: #FFFFFF;

    /* Neutral/Neutral 200 or 300 */
    outline: 1px solid $neutral-300;

    /* shadow-tertiary */
    box-shadow: 16px 20px 16px rgba(187, 193, 204, 0.01), 7px 9px 12px rgba(187, 193, 204, 0.01), 2px 2px 6px rgba(187, 193, 204, 0.01), 0px 0px 0px rgba(187, 193, 204, 0.01);
    border-radius: 4px;

    /* shadow-tertiary */
    box-shadow: 16px 20px 16px rgba(187, 193, 204, 0.01), 7px 9px 12px rgba(187, 193, 204, 0.01), 2px 2px 6px rgba(187, 193, 204, 0.01), 0px 0px 0px rgba(187, 193, 204, 0.01);
    border-radius: 4px;

    &.DateInput-Focused   {
        outline-color:$interactive;
        outline-width:2px;

    }

    .DateInput_Content    {
        display:flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 8px 12px;
        gap: 4px;

    }

    .DateInput_Prefix   {
        display:flex;
        svg {
            width:20px;
            height:20px;

            /* Inside auto layout */
            flex: none;
            order: 0;
            flex-grow: 0;
        }
    }

    .DateInput_Suffix {
        font-family: $main-font;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */

        /* Neutral/Neutral 700 */
        color: $neutral-700;
    }

    .DateInput_DisplayArea  {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 4px;

        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
        
        input  {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;

            /* identical to box height, or 125% */
            display: flex;
            align-items: center;

            /* Text/Text-Default */
            color: $text-neutral;


            /* Inside auto layout */
            flex: none;
            order: 0;
            align-self: stretch;
            flex-grow: 0;


            border:0;
            margin:0;
            padding:0;
            -webkit-appearance: none;
            -moz-appearance: none;
            outline: none;
        }
    }
}