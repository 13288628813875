@import '../../globals.scss';

.GrowAnalyticsPage  {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 6px;
    flex: 1 0 0;
    align-self: stretch;

    overflow-y:auto;
}

.GrowAnalytics-Charting {
    display: flex;
    align-items: flex-start;
    gap: 6px;
    flex: 1 0 0;
    align-self: stretch;
    overflow-y:auto;
}

.GrowAnalytics-Charting-DataToggles-Container {
    display: flex;
    padding-bottom: 10px;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    align-self: stretch;

    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #FFF;
}
.GrowAnalytics-Charting-DataToggles-Header {
    display: flex;
    padding: 9px 10px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    
    gap:20px;

    background: #F7F8FB;
}
.GrowAnalytics-Charting-DataToggles-Header-Toggle   {
    display:flex;
    width: 17px;
    height: 17px;
    align-items:center;
    cursor:pointer;
}

.GrowAnalytics-Charting-DataToggles-Content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-self: stretch;
    overflow-y:auto;
    padding:10px;
}



.GrowAnalytics-Charting-DataToggle-Container    {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-self: stretch;
}

.GrowAnalytics-Charting-DataToggle-GroupToggle    {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    gap:20px;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    background: transparent;

    &.GrowAnalytics-Charting-DataToggle-GroupToggle-Expanded    {
        border-color: var(--Main-Blue, #2E72D2);
        background: var(--Lihgt-blue-filler, #E4EAF5);
    }
}


.GrowAnalytics-Charting-DataToggle-GroupToggle-Info {
    display: flex;
    padding: 6px 0px 6px 10px;
    align-items: center;
    gap: 10px; 
    cursor:pointer;
    flex:1 0 auto;
}
.GrowAnalytics-Charting-DataToggle-GroupToggle-Info-ExpandToggle    {
    display:flex;
    align-content:center;
    justify-content: center;
}
.GrowAnalytics-Charting-DataToggle-GroupToggle-Info-Key {
    display: flex;
    align-items: center;
    gap: 6px; 
}
.GrowAnalytics-Charting-DataToggle-GroupToggle-Info-Key-Color {
    width: 13px;
    height: 13px; 
    border-radius:13px;
}
.GrowAnalytics-Charting-DataToggle-GroupToggle-Info-Key-Title {
    overflow: hidden;
    color: #191C22;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: $main-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
}

.GrowAnalytics-Charting-DataToggle-GroupToggle-ToggleGroupActiveButton  {
    display:flex;
    flex-direction: column;
    padding-right:10px;
}

.GrowAnalytics-Charting-DataToggle-DataItems-Container  {
    display:flex;
    flex-direction: column;
    overflow-y:hidden;
    transition:height 200ms ease;
}

.GrowAnalytics-Charting-DataToggle-DataItems  {
    display:flex;
    flex-direction: column;
}
.GrowAnalytics-Charting-DataToggle-DataItems-Content    {
    display: flex;
    padding-top:6px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 6px; 
}






.GrowAnalytics-Charting-DataToggle-DataItemToggle    {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    gap:20px;
}


.GrowAnalytics-Charting-DataToggle-DataItemToggle-Info {
    display: flex;
    padding: 6px 0px 6px 10px;
    align-items: center;
    gap: 10px; 
    cursor:pointer;
    flex:1 0 auto;
}
.GrowAnalytics-Charting-DataToggle-DataItemToggle-Info-Key {
    display: flex;
    align-items: center;
    gap: 6px; 
}
.GrowAnalytics-Charting-DataToggle-DataItemToggle-Info-Key-Color {
    width: 13px;
    height: 13px; 
    border-radius:13px;
}
.GrowAnalytics-Charting-DataToggle-DataItemToggle-Info-Key-Title {
    overflow: hidden;
    color: #191C22;
    text-overflow: ellipsis;
    font-family: $main-font;
    white-space: nowrap;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
}


.GrowAnalytics-Charting-DataToggle-DataItemToggle-ToggleGroupActiveButton  {
    display:flex;
    flex-direction: column;
    padding-right:10px;
}




.GrowAnalytics-Charting-Charts   {
    display: flex;
    padding: 20px 14px;
    flex-direction: column;
    gap: 20px;
    flex: 1 0 0;
    align-self: stretch;
}

.GrowAnalytics-Charting-Content {
    display:flex;
    flex-direction: column;
    flex-grow:1;
    touch-action: none;
    gap:10px;
}

.GrowAnalytics-Charting-MainChart-Wrapper   {
    position: relative;
    display:flex;
    flex-grow:1;
    background:$true-white;
    /*padding:10px 4px;*/
    border-radius:5px;
}
.GrowAnalytics-Charting-OverviewChart-Wrapper   {
    display:flex;
    background:$true-white;
    /*padding:10px 4px;*/
    border-radius:5px;
}

.GrowAnalytics-Charting-MainChart-Content, .GrowAnalytics-Charting-OverviewChart-Content   {
    display:flex;
    flex-grow:1;
    position:relative;
    box-sizing: border-box;
}

.GrowAnalytics-Charting-MainChart, .GrowAnalytics-Charting-OverviewChart    {
    flex-grow:1;
}




.GrowAnalytics-Charting-MainChart-OverlayContainer {
    position: absolute;
    left:0px;
    top:0px;
    right:0px;
    bottom:0px;
    box-sizing: content-box;
    pointer-events:none;
}

.GrowAnalytics-Charting-MainChart-Tooltip-Wrapper  {
    position:relative;
    pointer-events: none;
    width:0;
}
.GrowAnalytics-Charting-MainChart-Tooltip-Container    {
    display:flex;
    position:absolute;
}

.GrowAnalytics-Charting-MainChart-Tooltip-Content  {
    position:relative;
    display: flex;
    padding: 10px;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #FFF;
}
.GrowAnalytics-Charting-MainChart-Tooltip-DataItem {
    display:flex;
    gap:10px;
    align-items:center;
    &.GrowAnalytics-Charting-MainChart-Tooltip-DataItem-Active {
        .GrowAnalytics-Charting-MainChart-Tooltip-DataItem-Label   {
            font-weight:700;
            color:$main-blue;
        }
        .GrowAnalytics-Charting-MainChart-Tooltip-DataItem-Value   {
            color:$main-blue;
        }
        .GrowAnalytics-Charting-MainChart-Tooltip-DataItem-ActiveDataItemIndicator {
            visibility: visible;
        }
    }
}
.GrowAnalytics-Charting-MainChart-Tooltip-DataColorIndicator   {
    height: 13px;
    width: 13px;
    border-radius: 13px;
    align-self: center;
}
.GrowAnalytics-Charting-MainChart-Tooltip-DataItem-Label   {
    display:flex;
    gap:2px;
}
.GrowAnalytics-Charting-MainChart-Tooltip-DataItem-ActiveDataItemIndicator {
    width:5px;
    height:5px;
    border-radius:5px;
    background:$main-blue;
    align-self:center;
    visibility: hidden;
}

.GrowAnalytics-Charting-MainChart-ActivePointCrosshair-VerticalLine, .GrowAnalytics-Charting-MainChart-ActivePointCrosshair-HorizontalLine    {
    position:absolute;
    border-style:dashed;
    border-color:rgba(0,0,0,1);
    border-width:0;
}
.GrowAnalytics-Charting-MainChart-ActivePointCrosshair-VerticalLine    {
    width:0px;
    border-right-width:1.5px;
}
.GrowAnalytics-Charting-MainChart-ActivePointCrosshair-HorizontalLine    {
    height:0px;
    border-bottom-width:1.5px;
}





.GrowAnalytics-Charting-MainChart-ActiveTimeDisplay    {
    display:flex;
    position:relative;
    flex-direction: row;
    align-self: stretch;
    align-items:center;
    z-index:1000;
}
.GrowAnalytics-Charting-MainChart-ActiveTimeIndicator {
    position:absolute;
    width:2px;
    transform: translate(-50%, 0);
    bottom:100%;
    background-color:#2E72D2;
    pointer-events: none;
}
.GrowAnalytics-Charting-MainChart-ActiveTimeLabel  {
    position:absolute;
    transform: translate(-50%, 4px);
    

}
.GrowAnalytics-Charting-MainChart-ActiveTimeLabel-Content  {
    display: inline-flex;
    padding: 6px 10px;
    justify-content: center;
    align-items: flex-start;
    gap: 10px; 

    border-radius: 4px;
    border: 1px solid var(--Main-Blue, #2E72D2);
    background: #FFF; 
    >span   {
        color: #191C22;
        text-align: right;
        font-family: $main-font;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 
    }

}

.GrowAnalytics-Charting-NoGrowsSelectedPopup-Container  {
    display:flex;
    align-items: center;
    justify-content: center;
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color: transparent;
}
.GrowAnalytics-Charting-NoGrowsSelectedPopup-Content    {
    display: flex;
    padding: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #FFF;
}


.GrowZones-Charts-OverviewChart-SubselectedArea    {
    position: absolute;
    left:0px;
    top:0px;
    right:0px;
    bottom:0px;
    touch-action: none;
    box-sizing: content-box;

    >canvas {
        width:100%;
        height:100%;
        box-sizing: content-box;
    }
}

.GrowZones-Charts-OverviewChart-Subselection-Wrapper    {
    position: absolute;
    left:0px;
    top:0px;
    bottom:0px;
    box-sizing: content-box;
}
.GrowZones-Charts-OverviewChart-Subselection-Container  {
    position:relative;
    height:100%;    
    width:100%;
}
.GrowZones-Charts-OverviewChart-Subselection-PreStartOverlay, .GrowZones-Charts-OverviewChart-Subselection-PostEndOverlay   {
    position: absolute;
    background-color: rgba(0,0,0,0.2);
    border:1px solid rgba(0,0,0,1.0);
    margin:-1px;
    width:auto;
    top:0;
}
.GrowZones-Charts-OverviewChart-Subselection-PreStartOverlay    {
    left:0;
}
.GrowZones-Charts-OverviewChart-Subselection-PostEndOverlay    {
    right:0;
}



.GrowZones-Charts-OverviewChart-Subselection-Content  {
    position:relative;
    height:100%;    
    width:auto;
    &.GrowZones-Charts-OverviewChart-Subselection-Content-Draggable {
        cursor:pointer;
        /*background-color:rgba(0,0,0,0.2);*/
    }
}

.GrowZones-Charts-OverviewChart-Subselection-LeftGrabber, .GrowZones-Charts-OverviewChart-Subselection-RightGrabber {
    position:absolute;
    height:100%;
    width:10px;
    z-index:10;
    >div   {
        position: absolute;
        top:12px;
        bottom:12px;
        width:5px;        
        background-color:rgba(0, 0, 0, 0.6);
    }
}
.GrowZones-Charts-OverviewChart-Subselection-LeftGrabber    {
    left:-5px;
    >div   {
        left:0;
        border-radius:5px 0 0px 5px;
    }
}
.GrowZones-Charts-OverviewChart-Subselection-RightGrabber    {
    right:-5px;
    >div   {
        right:0px;
        border-radius:0 5px 5px 0;
    }
}

.GrowZones-Charts-OverviewChart-Subselection-LeftGrabber:hover, .GrowZones-Charts-OverviewChart-Subselection-RightGrabber:hover {
    cursor:pointer;
    >div {
        background-color:rgba(0, 0, 0, 1.0);
    }
}



.GrowZones-Charts-OverviewChart-Subselection-RightGrabber    {
 >div   {
    
 }    
}








.GrowAnalytics-GrowLegend-Container  {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #FFF;
}
.GrowAnalytics-GrowLegend-GrowColumn-Container  {
    display: flex;
    align-items: center;
    align-self: stretch;
}
.GrowAnalytics-GrowLegend-GrowColumn-SelectGrowsButton  {
    background: #F7F8FB;
    display:flex;
    padding: 6px 10px;
    align-items: flex-start;
    flex-shrink: 0;
}
.GrowAnalytics-GrowLegend-GrowColumn-Grows  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap:16px;
    padding:14px 0;
}

.GrowAnalytics-GrowLegend-DataTypes-Container   {
    display:flex;
    flex-direction: column;
    flex-grow:1;
    justify-content: center;
    overflow-x:auto;
    align-self: stretch;
    flex: 1 0 0;
}

.GrowAnalytics-GrowLegend-DataTypes-Header  {
    display: flex;
    align-items: center;
    background: #F7F8FB;
}

.GrowAnalytics-GrowLegend-Header-DataType-Container  {
    padding-right:9.5px;
    padding-left:2.5px;
}
.GrowAnalytics-GrowLegend-Header-DataType  {
    display: flex;
    padding: 8px 12px 8px 10px;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #FFF;

    white-space: nowrap;
}

.GrowAnalytics-GrowLegend-DataTypes-Grows   {
    display:flex;
    flex-direction: column;
    gap:16px;
    padding:14px 0;
    flex:1;
}




.GrowAnalytics-GrowLegend-Grows {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap:16px;
    padding:14px 0;
}

.GrowAnalytics-GrowLegend-Grow  {
    display:flex;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}
.GrowAnalytics-GrowLegend-Grow-Identifier-Content   {
    display: flex;
    overflow-x:hidden;
}
.GrowAnalytics-GrowLegend-Grow-Identifier    {
    padding-left:20px;
    display: flex;
    flex-direction: column;
    gap:2px;
    overflow-x:hidden;
}

.GrowAnalytics-GrowLegend-Grow-DataItems-Container  {
    display:flex;
    flex-direction: row;
    align-items: flex-start;
    align-self: center;
}

.GrowAnalytics-GrowLegend-Grow-DataItem-Container   {
    display: flex;
    flex-direction: column;
}
.GrowAnalytics-GrowLegend-Grow-DataItem    {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:0 5px;
}