@import '../../globals.scss';


#InventoryPage  {
    display:flex;
    flex-direction: column;
    align-content: flex-start;
    flex-grow: 1;
    overflow-y:hidden;
}

.ViewInventoryItem-PropertiesList   {

    .ViewInventoryItem-Property {
        display:flex;
        flex-direction: row;
        gap:10px;
    }
}