@import './globals.scss';

/* Load system fonts, Enable Kerning and Smoothing, Deactive Safari Text Adjusting */
html {
  font-family: $main-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;

  overscroll-behavior-x: none;

}
  
  
/* Prevent form elements from rendering using OS defaults */

button, input, optgroup, select, textarea { font-family: inherit }

body {
  padding:0;
  margin:0;
  top:0;
  left:0;
  right:0;
  bottom:0;
  position: fixed;
  overflow:hidden;

  font-family: $main-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overscroll-behavior-x: none;
}
 
a {
  text-decoration: none;
}



#root {
  display:flex;
  flex-grow: 1;
  height:100%;
}

#PageWrapper  {
  display: flex;
  flex-direction: column;
  align-self:stretch;
  align-items:stretch;
  flex:1;
  position:relative;
  overflow-y:auto;
  scrollbar-width: thin;
  height:100%;
}

#PageContainer  {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  height:100%;
  transition: width .10s ease-in-out;
  background:#F8F8F8;

}

#PageContent  {
  display: flex;
  flex-grow: 1;
  overflow-y: hidden;
}

.PageWrapper_Standard {
  display: flex;
  flex-direction: column;
  flex-grow:1;
  width:100%;
  height:100%;
  box-sizing: border-box;
  position:relative;

  @media (min-width: 767px) {
    padding: 0px 20px 20px 20px;
  }
  @media (max-width: 766px) {
    padding: 0px 16px 16px 16px;
  }

}


.Popup_Standard {
  position:fixed;
  left:0;
  right:0;
  bottom:0;
  top:0;
  z-index:1000;
  background-color: rgba(0, 0, 0, 0.5);
  transition: backdrop-filter 1s;
  backdrop-filter: blur(5px) opacity(1);

  display:flex;
  align-items: center;
  justify-content: center;


  .Popup_Standard-Modal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    /* Base/True White */
    background: $true-white;

    /* shadow-primary */
    box-shadow: 47px 50px 27px rgba(46, 114, 210, 0.01), 26px 28px 23px rgba(46, 114, 210, 0.03), 12px 12px 17px rgba(46, 114, 210, 0.04), 3px 3px 9px rgba(46, 114, 210, 0.05), 0px 0px 0px rgba(46, 114, 210, 0.05);
    border-radius: 8px;

    min-width:50%;
    min-height:30%;


    .Popup_Standard-Modal_Header  {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 16px 20px;
      gap: 20px;

      /* Base/True White */
      background: $true-white;

      /* Divider/Bottom */
      box-shadow: inset 0px -1px 0px #E1E3E5;
      border-radius: 8px 8px 0px 0px;

      /* Inside auto layout */
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;

      .Popup_Standard-Modal_HeaderContent {
        display:flex;
        flex-direction: column;
        order:0;
        flex-grow:1;
        align-items: flex-start;
        gap:6px;
      }

      .Popup_Standard-Modal_Close {
        display:flex;
        order:1;
        align-self:stretch;
        align-items:center;
        padding:0 10px;
        margin:0 -10px;
        cursor:pointer;

        > svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    .Popup_Standard-ModalContent  {
      display: flex;
      flex-direction: column;
      padding: 16px 20px;
      align-self: stretch;

      /* Base/True White */
      background: $true-white;
    }
  }
}
