
@import '../../globals.scss';






#GrowManagerChartsPage  {
    position:relative;
}


#GrowManager    { 
    display:flex;
    flex-direction: column;
    flex-grow:1;
    overflow-y:hidden;
    align-self: stretch;
    align-items: stretch;

    #GrowManagerDataToggles {
        display:flex;
        flex-direction:row;
        gap:10px;
        overflow-x:auto;
        scrollbar-width:thin;
    }
}

#GrowManager_Content    {
    display: flex;
    flex-direction: row;
    overflow-y:hidden;
    align-items: stretch;
    gap:6px;
    flex:1;
}

#GrowManager_TabContent {   
    display: flex;
    flex-direction: column;

    overflow:hidden;
    flex:1;

    &.CollapseForStatus {
        width:0;
        flex:0;
        overflow:hidden;
    }
}


#GrowManager_GrowRepository {
    order:0;
}

.GrowItem   {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    box-sizing: border-box;

    /* Inside auto layout */
    order: 0;
    align-self: stretch;
    flex-grow: 1;

    /* Neutral/Neutral 300 */
    border-radius: 8px;
    background: #ffffff;
    

    .GrowItem_Tile  {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        align-self:stretch;
        justify-content: center;
        flex:1;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        background: #fafafa;
    }

    .GrowItem_Tile-Header {
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items:center;
    }

    .GrowItem_Tile-Title    {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */
        
        /* Text/Text-Default */
        color: $text-neutral;
        
        order:0;
    }

    .GrowItem_Tile-StatusIndicator  {
        width:10px;
        height:10px;
        border-radius: 10px;

        order:1;
    }

    .GrowItem_RepositoryTile    {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self:stretch;
        flex:1;

    
    }

    .GrowItem_RepositoryTile-GrowInfo    {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        align-self:stretch;
        flex:1;
    }

    .GrowItem_DateColumnItem    {
        svg {
            color: $neutral-600;
        }
    }

    .GrowItem-TimelineTile  {
        align-self:stretch;
        flex-shrink: 0;
    }

    &.GrowItem_Selectable   {
        cursor: pointer;
    }

    &.GrowItem_Selected   {
        background-color: rgba(46,114,210,0.1);
    }

    .GrowItem_TileContent  {
        display:flex;
        flex-direction:column;
        align-content: center;
        justify-content: center;

        padding:5px;

    }

    .GrowItem-GaugeTile {
        padding:16px 16px;
    }

    

    .GrowItem_WrappingTiles {
        display:flex;
        flex-direction: row;
        flex-wrap:wrap;
        overflow: hidden;
    }
    

    .GrowItem_ContentArea   {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        overflow:hidden;

        order:1;

        flex-grow:1;
        
    }

    .GrowItem_ExpandedContent   {
        display:flex;
        flex-direction: column;
        align-self:flex-start;
        align-content:flex-start;
        justify-content: flex-start;
        justify-items: flex-start;
        overflow:hidden;
    }


    .GrowGroup-GrowItems    {
        display:flex;
        flex-direction: column;
        gap:6px;
        padding-bottom:6px;
    }
    .GrowGroup-GrowItem_Tile    {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        align-self:stretch;
        justify-content: center;
        flex:1;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        background: $off-white;   
    }
}
.GrowGroup-ToggleAllGrowsButton {
    font-family: 'Roboto';
    font-weight: 400;
    padding:2px 5px;
    font-size: 11px;
    line-height: 10px;
    background-color: $true-white;
    border-radius: 0 0 5px 5px;
    /* identical to box height, or 143% */

    /* Text/Text-Subdued */
    color: $text-neutral-subdued;
}




@media screen and (max-width: 5000px) {
    .GrowItem_WrappingTiles > .GrowItem_Tile  {
        flex:0 1 calc(12.5% - 16px);
    }
}

@media screen and (max-width: 1600px) {
    .GrowItem_WrappingTiles > .GrowItem_Tile  {
        flex:0 1 calc(16.66% - 16px);
    }
}

@media screen and (max-width: 1200px) {
    .GrowItem_WrappingTiles > .GrowItem_Tile  {
        flex:0 1 calc(20% - 16px);
    }
}
@media screen and (max-width: 950px) {
    .GrowItem_WrappingTiles > .GrowItem_Tile  {
        flex:0 1 calc(25% - 16px);
    }
}
@media screen and (max-width: 750px) {
    .GrowItem_WrappingTiles > .GrowItem_Tile  {
        flex:0 1 calc(33% - 16px);
    }
}
@media screen and (max-width: 600px) {
    .GrowItem_WrappingTiles > .GrowItem_Tile  {
        flex:0 1 calc(50% - 16px);
    }
}
@media screen and (max-width: 480px) {
    .GrowItem_WrappingTiles > .GrowItem_Tile  {
        flex:0 1 calc(100% - 16px);
    }
}


#GrowManager-Timeline-Content   {
    display:flex;
    flex-direction: column;
    flex-grow:1;

    padding:20px;

    overflow-y:hidden;

    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #FFF; 
}




#GrowManagerChartsPage  {
    display:flex;
    flex-direction: row;
    gap:0;
    flex-grow:1;
}

#GrowManagerChartsPage_ChartingArea   {
    display:flex;
    flex-direction: column;
    flex-grow:1;
    touch-action: none;
    gap:10px;
    overflow:hidden;
}

#GrowManagerChartsPage_MainChartingArea, #GrowManagerChartsPage_OverviewChartingArea {
    display:flex;
    background:$true-white;
    /*padding:10px 4px;*/
    border-radius:5px;
}


#GrowManagerChartsPage_MainChartingWrapper, #GrowManagerChartsPage_OverviewChartingWrapper  {
    padding:10px 4px;
    display:flex;
    flex-grow:1;
}

#GrowManagerChartsPage_MainChartingContent, #GrowManagerChartsPage_OverviewChartingContent  {
    display:flex;
    flex-grow:1;
    position:relative;
    box-sizing: border-box;
}

#GrowManagerChartsPage_MainChart, #GrowManagerChartsPage_OverviewChart    {
    flex-grow:1;
}

#GrowManagerChartsPage_MainChart-SubselectedEnergyArea, #GrowManagerChartsPage_OverviewChart-SubselectedArea    {
    position: absolute;
    inset: 0px;
    width: 100%;
    height: 100%;
    touch-action: none;
    box-sizing: content-box;

    >canvas {
        
        box-sizing: content-box;
    }
}



#GrowManagerChartsPage_MainChartingArea {
    flex-grow:1;
    flex-direction: column;
    order:0;
}

#GrowManagerChartsPage_OverviewChartingArea {
    flex-basis:15%;
    min-height:70px;
    order:1;
}


#GrowManagerChartsPage_OverviewChart    {
    touch-action: none;
}

#GrowManagerChartsPage_OverviewChart-SubselectedArea    {
    pointer-events:none;

}






#GrowManagerDataToggles {
    display:flex;
    flex-direction:row;
    order:1;
    gap:10px;
    padding: 0px 10px 10px 10px;
}
.GrowManagerDataToggle-DataTypeColorIndicator   {
    width: 20px;
    height: 8px;
    
    border-radius: 6.89813px;
    
    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.GrowManagerDataToggle-EnergyDisplay    {
    display:flex;
    flex-direction: row;

    

    .GrowManagerDataToggle-EnergyDisplay-Value  {

    }
    .GrowManagerDataToggle-EnergyDisplay-P-Unit   {
        color:$text-neutral-subdued;
        font-size:12px;
        align-self:flex-end;
        padding-right:5px;
    }
    .GrowManagerDataToggle-EnergyDisplay-C-Unit   {
        color:$text-neutral-subdued;
        font-size:14px;
        align-self:center;
    }
}

.GrowManagerDataToggle-EnergySubselectionToggle {
    display:flex;
    flex-grow:1;
    &.GrowManagerDataToggle-EnergySubselectionActive {
        background-color:rgba(255, 180, 62, 0.2);
    }
}





#GrowManagerChartsPage_DrawingBoard {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    pointer-events:none;
}

.GrowManagerChartsPage_CurrentPositionIndicator {
    display:flex;
    flex-direction: column;
    position:absolute;
     
    background-color: $interactive;
}

#GrowManagerChartsPage_Tooltip  {
    display:flex;
    flex-direction: column;
    position:absolute;
    min-width:100px;
    min-height:40px;

    /* Base/True White */
    background: #FFFFFF;
    /* Neutral/Neutral 300 */
    border: 1px solid $neutral-300!important;
    border-radius: 4px;

    margin:0;
    gap: 4px;

    /* shadow-tertiary */
    box-shadow: 16px 20px 16px rgba(187, 193, 204, 0.01), 7px 9px 12px rgba(187, 193, 204, 0.01), 2px 2px 6px rgba(187, 193, 204, 0.01), 0px 0px 0px rgba(187, 193, 204, 0.01);        
}
.GrowManagerChartsPage_Tooltip-Table    {
    
}
.GrowManagerChartsPage_Tooltip-ValueDisplay {
    padding:0 6px;
}
.GrowManagerChartsPage_Tooltip-DateDisplay {
    padding:0 6px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 167% */

    /* Text/Text-Subdued */
    color: $text-neutral;
}
.GrowManagerChartsPage_Tooltip-Unit {
    margin-left:4px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 167% */

    /* Text/Text-Subdued */
    color: $text-neutral-subdued;
}

.GrowManagerChartsPage_TimeTooltip  {
    display:flex;
    flex-direction: column;
    position:absolute;

    transform:translate(-50%, -50%);

    /* Base/True White */
    background: #FFFFFF;
    /* Neutral/Neutral 300 */
    border: 1px solid $neutral-300!important;
    border-radius: 4px;

    margin:0;
    padding: 4px;

    /* shadow-tertiary */
    box-shadow: 16px 20px 16px rgba(187, 193, 204, 0.01), 7px 9px 12px rgba(187, 193, 204, 0.01), 2px 2px 6px rgba(187, 193, 204, 0.01), 0px 0px 0px rgba(187, 193, 204, 0.01);

    >div    {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 167% */

        /* Text/Text-Subdued */
        color: $text-neutral-subdued;
    }
}







.GrowScheduling-GrowItem-Container  {
    display:flex;
    flex-direction: column;
}

.GrowScheduling-GrowItem-Main   {
    display:flex;
    flex-direction: row;
}

.GrowScheduling-GrowItem-Content    {
    display:flex;
    align-items: center;
    gap:6px;
    overflow-x:hidden;
}  


.GrowScheduling-GrowItem-Bar    {
    display:flex;
    position:relative;
    flex-shrink: 0;
    flex-direction: row;
    border: 1px solid var(--Dark-Section-Border, #9CA6B4);
    background: #FFF; 
    align-items: center;
    
}






.GrowScheduling-GrowItem-Bar-JumpBackArrow-Container {
    display:flex;
    flex-shrink: 0;
    overflow:hidden;
}

.GrowScheduling-GrowItem-Bar-JumpBackArrow  {
    display:flex;
    flex-direction: column;
    padding:9px 5px;
    height:10px;
    justify-content: center;
}






.GrowScheduling-GrowItem-Bar-GrowDisplayName-Container  {
    display:flex;
    flex-direction: column;
    overflow:hidden;
}

.GrowScheduling-GrowItem-Bar-GrowDisplayName-Content    {

}

.GrowScheduling-GrowItem-Bar-GrowDisplayName    {
    display:flex;
    padding:0 10px;
    >span   {
        white-space: nowrap;
        overflow: hidden;
        color: #191C22;
        text-overflow: ellipsis;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}




.GrowScheduling-GrowItem-Options-Container  {
    display:flex;
    flex-shrink: 0;
    overflow:hidden;
}
.GrowScheduling-GrowItem-Options-Content  {
    display:flex;
    flex-direction: column;
    justify-content: center;
}

.GrowScheduling-GrowItem-Options    {
    display:flex;
    flex-direction: column;
    justify-content: center;
    padding:9px 10px;
    height:10px;
}


.GrowScheduling-GrowItem-Bar-TrailingContent {
    display:flex;
    flex-grow:1;
    justify-content:flex-end;
}
.GrowScheduling-GrowItem-Bar-JumpForwardArrow-Container {
    display:flex;
    flex-shrink: 0;
    overflow:hidden;
}

.GrowScheduling-GrowItem-Bar-JumpForwardArrow {
    display:flex;
    padding:9px 5px;
    flex-direction: column;
    height:10px;
    justify-content: center;
}





.GrowScheduling-GrowItem-GrowDisplayName-Container  {
    display:flex;
    flex-direction: column;
    opacity:0.6;
    overflow:hidden;
}

.GrowScheduling-GrowItem-GrowDisplayName-Content    {

}

.GrowScheduling-GrowItem-GrowDisplayName    {
    display:flex;
    >span   {
        white-space: nowrap;
        overflow: hidden;
        color: #191C22;
        text-overflow: ellipsis;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}



.GrowScheduling-GrowItem-OutsideContent {
    display:block;
    position:relative;
    width:0;
    top:0;
    bottom:0;
}

.GrowScheduling-GrowItem-OutsideBeforeBar-GrowDisplayName-Container {
    display:flex;
    position:absolute;
    right:6px;
    opacity:0.6;
    height:100%;
    align-items:center;
}

.GrowScheduling-GrowItem-OutsideBeforeBar-GrowDisplayName-Content    {

}

.GrowScheduling-GrowItem-OutsideBeforeBar-GrowDisplayName    {
    display:flex;
    >span   {
        white-space: nowrap;
        overflow: hidden;
        color: #191C22;
        text-overflow: ellipsis;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}