@import '../../../globals.scss';





#GrowRacksStatus  {
    display:flex;
    flex-direction: column;
    overflow-y:hidden;
    flex:1;
}

.GrowRacksStatus-NoGroupsContent  {
    display: flex;
    align-self:stretch;
    flex:1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 19px; 

    >span:nth-child(1)   {
        font-family:$main-font;
        color: #191C22;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal; 
    }
}



.GrowRacksStatus-VerticalRackGroups {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    overflow-y:auto;
    align-self:stretch;
    flex:1;
}


.GrowRacksStatus-VerticalRackGroup-Rack-Sides   {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    gap: 6px;
    .GrowRacksStatus-VerticalRackGroup-Rack-Side    {
        overflow-x:hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right:2px;
    }
}



.GrowRacksStatus-VerticalRackGroup-Rack-PauseInfo-State {
    color: #191C22;
    font-family: $main-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
    white-space:nowrap;
}
.GrowRacksStatus-VerticalRackGroup-Rack-PauseInfo-TimeRemaining {
    color: #191C22;
    font-family: $main-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
    white-space:nowrap;
}
.GrowRacksStatus-VerticalRackGroup-Rack-PauseInfo-AddTimeButton {
    display: flex;
    padding: 6px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch; 

    border-radius: 4px;
    border: 1px solid var(--section-border, #2E72D2);

    >span   {
        color: #2E72D2;
        font-family: $main-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 
        white-space:nowrap;
    }
}