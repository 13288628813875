@import '../globals.scss';


.ToggleCellButton {
    /* Auto layout */
    display: flex;
    flex-shrink:1;

    > div   {
        display:flex;
        align-self:stretch;
        align-items: center;
        padding: 0px;
        border-radius: 4px;
        overflow:hidden;
        cursor:pointer;

        justify-content: center;
        align-items: center;
        gap: 10px; 

        > span    {
            font-family: $main-font;
            font-style: normal;
            font-weight: 400;

        
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            /* Text/Text-Default */
            color: $true-white;
        }
    }

    






    &.ToggleCellButton-Medium  {
        > div {
            padding: 8px 18px;
            > span {
                font-size: 14px;
                line-height: normal; 
                /* identical to box height, or 143% */
            }
        }
    }
    &.ToggleCellButton-Small   {
        > div {
            padding: 5px 12px;
            > span {
                font-size: 14px;
                line-height: 20px;
                /* identical to box height, or 143% */
            }
        }
    }

    &.ToggleCellButton-Status {
        > div   {
            padding: 6px 10px; 
            > span {
                font-size: 14px;
                line-height: normal;
                /* identical to box height, or 143% */
            }
        }
    }


    &.ToggleCellButton-Primary  {
        > div   {
            /* Accent/Neutral */
            background: transparent;

            > span {
                color: #2E72D2;
                font-family: $main-font;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal; 
            }
        }

        &.Button-Disabled   {
            > div   {
                /* Accent/Neutral */
                background: $button-neutral-disabled;
    
                > span  {
                    /* Text/Text-Default */
                    color: $text-neutral-subdued;
                }
            }
        }
    }
    &.ToggleCellButton-Neutral  {
        border-radius: 4px;
        border: 1px solid #DDE3EB;
        background: #FFF; 

        > div   {
            /* Accent/Neutral */
            background: $true-white;

            > span  {
                /* Text/Text-Default */
                color: $text-neutral;
            }
        }

        &.Button-Disabled   {
            > div   {
                /* Accent/Neutral */
                background: $neutral-50;
    
                > span  {
                    /* Text/Text-Default */
                    color: $text-neutral-subdued;
                }
            }
        }
    }
    &.ToggleCellButton-Critical  {
        > div   {
            /* Accent/Neutral */
            background: $true-white;
            > span, > svg  {
                /* Text/Text-Default */
                color: $text-critical;
            }
        }
    }

    &.ToggleCellButton-Focused    {
        border-radius: 4px;
        border: 1px solid var(--main-blue, #2E72D2);

        > div {
            background: var(--lihgt-blue-filler, #E4EAF5);

            > span, > svg    {
                /* Text/Text-Default */
                color: $text-neutral;
            }
        }
    }
    
    
    
    &.ToggleCellButton-WithBorder {
        border:1px solid $neutral-300;
        border-radius: 4px;
    }


    &.ToggleCellButton-Disabled   {
        color:$text-neutral-subdued;
        svg {
            color:$text-neutral-subdued;
        }
    }
    
}