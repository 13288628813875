@import '../../globals.scss';


.DataStatus-Cell   {
    display: flex;
    padding: 22px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    
    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #FFF;
}

.DataStatus-Cell-Header-Container   {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
}

.DataStatus-Cell-Header-PrimaryContent  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
}
.DataStatus-Cell-Header-PrimaryContent-StatusChip   {
    display: flex;
    padding: 2px 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    border-radius: 8px;
    background: #E8EBEF;
    
    color: var(--Text, #191C22);
    font-family: "DM Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.DataStatus-Cell-Header-AuxContent {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}

.DataStatus-Cell-Content-Container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.DataStatus-Cell-DataList  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    align-self: stretch;
}

.DataStatus-Cell-DataList-StandardItem  {
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
}
.DataStatus-Cell-DataList-StandardItem-Label {
    display:flex;
    align-items: flex-start;
}
.DataStatus-Cell-DataList-StandardItem-VisualizationContainer   {
    display:flex;
    flex:1;
}
.DataStatus-Cell-DataList-StandardItem-Value {
    display:flex;
    align-items: flex-start;


    &.DataStatus-Cell-DataList-StandardItem-Value-Invalid *   {
        color:#EC3C3C!important;
    }
}




.DataStatus-Cell-DataList-BarVisualization-Container    {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    flex: 1 0 0;
}
.DataStatus-Cell-DataList-BarVisualization    {
    display: flex;
    align-items: center;
    gap: 5px;
    flex: 1 0 0;
}

.DataStatus-Cell-DataList-BarVisualization-RangeMin  {
    display: flex;
    justify-content: flex-end;
}
.DataStatus-Cell-DataList-BarVisualization-RangeMax  {
    display: flex;
    justify-content: flex-start;
}
.DataStatus-Cell-DataList-BarVisualization-RangeMin-Content, .DataStatus-Cell-DataList-BarVisualization-RangeMax-Content  {
    display: flex;
    align-items: flex-start;
}

.DataStatus-Cell-DataList-Bar-Container {
    display: flex;
    height: 13px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
}

.DataStatus-Cell-DataList-Bar   {
    position: relative;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    width:100%;
    height:100%;
}
.DataStatus-Cell-DataList-BarFill   {
    border-radius: 3px;
    opacity: 0.2;
    height:100%;
    transition: width 500ms ease;
}
.DataStatus-Cell-DataList-Bar-normal   {
    border-radius: 4px;
    border-color:var(--Main-Blue, #1D65F0);
    .DataStatus-Cell-DataList-BarFill   {
        background: var(--Main-Blue, #1D65F0);
    }
}



.DataStatus-Cell-DataList-LargeBarVisualization-Container   {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 6px;
    align-self: stretch;

    margin-bottom: 10px;
}
.DataStatus-Cell-DataList-LargeBarVisualization-Header    {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}
.DataStatus-Cell-DataList-LargeBarVisualization-Header-Label    {

}
.DataStatus-Cell-DataList-LargeBarVisualization-Header-Value    {
    
}
.DataStatus-Cell-DataList-LargeBarVisualization-Bar-Container    {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    height:30px;
}

.DataStatus-Cell-DataList-LargeBarVisualization-Bar   {
    position: relative;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    width:100%;
    height:100%;
}
.DataStatus-Cell-DataList-LargeBarVisualization-BarFill   {
    border-radius: 3px;
    opacity: 0.2;
    height:100%;
    transition: width 500ms ease;
}
.DataStatus-Cell-DataList-LargeBarVisualization-Display {
    position:absolute;
    left:5px;
    top:0;
    bottom:0;
    align-content:center;
}
.DataStatus-Cell-DataList-LargeBarVisualization-Bar-normal   {
    border-radius: 4px;
    border-color:var(--Main-Blue, #1D65F0);
    .DataStatus-Cell-DataList-LargeBarVisualization-BarFill   {
        background: var(--Main-Blue, #1D65F0);
    }
    .DataStatus-Cell-DataList-LargeBarVisualization-Display {
        color:var(--Main-Blue, #1D65F0);
    }
}













.DataStatus-Cell-DataList-SetpointBarVisualization-Container   {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 6px;
    align-self: stretch;

    margin-bottom: 10px;
}
.DataStatus-Cell-DataList-SetpointBarVisualization-Header    {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}
.DataStatus-Cell-DataList-SetpointBarVisualization-Header-Label    {

}
.DataStatus-Cell-DataList-SetpointBarVisualization-Header-Value    {
    display:flex;
    gap:2px;
}
.DataStatus-Cell-DataList-SetpointBarVisualization-Bar-Container    {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    height:18px;
}

.DataStatus-Cell-DataList-SetpointBarVisualization-Bar   {
    position: relative;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    width:100%;
    height:100%;
}
.DataStatus-Cell-DataList-SetpointBarVisualization-BarSetpointIndicator-Locator {
    position:absolute;
    left:50%;
    right:50%;
    top:-5px;
    bottom:-5px;
}
.DataStatus-Cell-DataList-SetpointBarVisualization-BarSetpointIndicator {
    width:2px;
    height:100%;
    transform: translateX(-1px);
    background-color: #191C22;
}
.DataStatus-Cell-DataList-SetpointBarVisualization-BarFill   {
    position: absolute;
    border-radius: 3px;
    height:100%;
    transition: left 500ms ease, right 500ms ease;
    width:auto;
}
.DataStatus-Cell-DataList-SetpointBarVisualization-Display {
    position:absolute;
    left:5px;
    top:0;
    bottom:0;
    align-content:center;
}
.DataStatus-Cell-DataList-SetpointBarVisualization-Bar-normal   {
    border-radius: 4px;
    border-color:var(--Main-Blue, #1D65F0);
    .DataStatus-Cell-DataList-SetpointBarVisualization-BarFill   {
        background: var(--Main-Blue, #1D65F0);
    }
    .DataStatus-Cell-DataList-SetpointBarVisualization-Display {
        color:var(--Main-Blue, #1D65F0);
    }
}


.DataStatus-Override-Container  {
    display:flex;
    gap:10px;
    align-self: stretch;
    justify-content: space-between;
}
.DataStatus-Override-Toggle {
    display:flex;
    gap:10px;
    flex:1;
    align-items: center;
}