@import '../../../globals.scss';



.GrowStatus-Info-PlantType-Card {
  display: flex;

  cursor:pointer;

  border-radius: 4px;
  border: 1px solid var(--Section-Border, #DDE3EB);
  background: #F7F8FB;
}

.GrowStatus-Info-PlantType-Card-Content {
  display:flex;
  padding: 10px 20px 10px 10px;
  align-items: center;
  gap: 10px;
  flex:1;
}

.GrowStatus-Info-PlantType-Card-Icon  {
  display:flex;
  width:45px;
  align-self: stretch;
  border-radius: 4px;
  overflow:hidden;
  border:1px solid rgba(0,0,0,0.1);
}
.GrowStatus-Info-PlantType-Card-Info  {
  display: flex;
  padding-right: 20px;
  flex-direction: column;
  align-items: flex-start;
  flex:1;
}

.GrowStatus-Info-PlantType-Card-Link  {
  display:flex;
  align-items: center;
}





.GrowStatus-Info-BladeZone-Card {
  display: flex;
  
  cursor:pointer;

  border-radius: 4px;
  border: 1px solid var(--Section-Border, #DDE3EB);
  background: #F7F8FB;
}

.GrowStatus-Info-BladeZone-Card-Content  {
  display: flex;
  padding: 10px 20px 10px 10px;
  align-items: center;
  gap: 10px;
  flex:1;
}

.GrowStatus-Info-BladeZone-Card-Info  {
  display: flex;
  padding-right: 20px;
  flex-direction: column;
  align-items: flex-start;
  flex:1;
}

.GrowStatus-Info-BladeZone-Card-Link  {
  display:flex;
  align-items: center;
}