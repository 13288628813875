@import '../../globals.scss';




.CalendarMonth-Container    {
    display:flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
}
.CalendarMonth  {
    display: flex;
    padding:25px;
    flex-direction: column;
    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
}

.CalendarMonth-Content  {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    gap:10px;
}

.CalendarMonth-Header   {
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;    
    align-self: stretch;
    gap:10px;
}

.CalendarMonth-StandardRows {
    display:flex;
    flex-direction: column;
    align-self: stretch;
}
.CalendarMonth-StandardRow {
    display:flex;
    flex-direction: row;
    align-items:stretch;
}


.CalendarMonth-StandardCell    {
    display:flex;
    align-items: center;
    justify-content: center;
    position: relative;

    min-width:30px;
    min-height:30px;

    cursor: pointer;
}
.CalendarMonth-StandardCell-Content {
    display:flex;
    position: absolute;
    width:100%;
    height:100%;
    align-items: center;
    justify-content: center;
}
.CalendarMonth-StandardMonthCell {

}

.CalendarMonth-StandardOtherMonthCell   {
    opacity:0.4;
}

.CalendarMonth-StandardCell-StartDateVisual {
    position: absolute;
    display:block;
    width:75%;
    height:75%;
    border-radius: 100%;
    border:3px solid #1D65F0;
}


.CalendarMonth-StandardCell-StartOfDateRangeVisual {
    position: absolute;
    display:block;
    left:12.5%;
    right:0;
    height:75%;
    border-radius: 75% 0 0 75%;
    overflow:hidden;
    background:#F0F4FB;
}



.CalendarMonth-StandardCell-EndDateVisual {
    position: absolute;
    display:block;
    width:75%;
    height:75%;
    border-radius: 100%;
    border:3px solid #1D65F0;
}


.CalendarMonth-StandardCell-EndOfDateRangeVisual {
    position: absolute;
    display:block;
    left:0;
    right:12.5%;
    height:75%;
    border-radius: 0 75% 75% 0;
    overflow:hidden;
    background:#F0F4FB;
}


.CalendarMonth-StandardCell-InDateRangeVisual-StartWeek {
    position: absolute;
    display:block;
    left:12.5%;
    right:0;
    height:75%;
    border-radius: 75% 0 0 75%;
    overflow:hidden;
    background:#F0F4FB;
}

.CalendarMonth-StandardCell-InDateRangeVisual-Interweek {
    position: absolute;
    display:block;
    left:0;
    right:0;
    height:75%;
    overflow:hidden;
    background:#F0F4FB;
}

.CalendarMonth-StandardCell-InDateRangeVisual-EndWeek {
    position: absolute;
    display:block;
    left:0;
    right:12.5%;
    height:75%;
    border-radius: 0 75% 75% 0;
    overflow:hidden;
    background:#F0F4FB;
}




.Calendar {
    display:flex;
    flex-direction: column;
    border-radius: 14px;
    padding: 10px 25px 10px 25px;
    gap:10px;
    align-self:flex-start;

    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);

    &.Calendar-Disabled {
        opacity:0.6;
    }
}

.Calendar-Header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;


    .Calendar-Header-DateDisplay    {
        display:flex;
        flex-direction: column;
        align-items:center;
        order:2;
        flex:1;

        color: #191C22;
        text-align: center;
        font-family: $main-font;
        font-size: 16.364px;
        font-style: normal;
        font-weight: 500;
        line-height: normal; 
    }

    .Calendar-Header-DateBackwardsButton    {
        display:flex;
        flex-direction: column;
        order:1;
        padding:10px 10px;
        cursor: pointer;
    }
    .Calendar-Header-DateForwardsButton    {
        display:flex;
        flex-direction: column;
        order:3;
        padding:10px 10px;
        cursor: pointer;
    }
}

.Calendar-Content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0px;
    gap: 12px;
}

.Calendar-HeaderTiles   {
    display: flex;
    flex-direction: row;
    align-items: center;
    display:none;
        
    .Calendar-HeaderTiles-Tile  {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;

        /* identical to box height, or 100% */
        letter-spacing: 0.03em;
        text-transform: uppercase;

        color: #000000;
    }
}

.Calendar-Tiles {
    display: flex;
    flex-direction: column;
    align-self:stretch;
    .Calendar-Tiles-Row {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .Calendar-Tiles-Tile    {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: stretch;
        
        
        color: #191C22;
        font-family: $main-font;
        font-size: 16.364px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 

        min-width:38px;
        min-height:38px;

        &.Calendar-Tiles-Tile-PreviousTerm, &.Calendar-Tiles-Tile-NextTerm  {

            opacity: 0.4; 
        }
    }

    .Calendar-Tiles-Tile-Basic  {
        display:flex;
        align-self: stretch;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex:1;
    }
}
