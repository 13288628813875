@import '../../globals.scss';

.StandardList-Wrapper {
    display:flex;
    flex-direction: column;
    flex:1;
    align-items: stretch;
    justify-content: stretch;
    overflow-y:hidden;
}
.StandardList-Container {
    display:flex;
    align-self:stretch;
    flex-direction: column;
    overflow-y:hidden;
    overflow-x: auto;
    scrollbar-width:thin;
    align-items:stretch;
    flex:1;
}
.StandardList {
    display: flex;
    flex:1;
    flex-direction: column;
    align-items:stretch;
    gap:2px;

    -moz-background-clip: padding; 
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    overflow-y:hidden;
    max-height:100%;
    min-height:50px;

    &.StandardList-IsolatedRows {
        background-color:transparent;
        border-color:transparent;

        &:not(.StandardList-HiddenHeader)   {
            gap:8px;
        }
        
        .StandardList-ContentWrapper {

            gap:8px;
        }

        .StandardList-Header    {
            background-color:$true-white;
            border-radius:8px;

        }

        .StandardList-Rows  {
            gap:8px;
        }

        .StandardList-Row   {
            background: $true-white;
        }
    }
}

.StandardList-DetachedResourceHeader    {
    display:flex;
    flex-direction: column;
    align-self: stretch;
    margin:5px 0;
    gap: 8px;
    background-color:$accent-neutral;
}

.StandardList-AttachedResourceHeader    {
    display:flex;
    flex-direction: column;
    align-self: stretch;
    padding: 16px;
    gap: 8px;
    background-color:$accent-neutral;
}

.StandardList-Header    {

    display: flex;
    flex-direction: row;
    align-self:stretch;
    align-items:center;
    min-width: max-content;
    border:1px solid $neutral-200;
    background-color:$true-white;
    border-radius:4px;

    /* Surface/Neutral */
    /*background: $surface-neutral;*/

        
    .StandardList-Header-GroupedColumnContainer {
        display:flex;
        flex-direction: column;
        align-items: flex-start;        
    }
    .StandardList-Header-GroupedColumnContent   {
        display:flex;
        flex-direction: row;
        align-items: center;
    }

    .StandardList-Header-ColumnContainer    {
        display:flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .StandardList-Header-ColumnContent    {
        display:flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .StandardList-Header-Column {
        display: flex;
        flex-direction: column;
        justify-items: center;

        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        touch-action: none;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.StandardList-ContentWrapper    {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    align-items: stretch;
    flex: 1;
    /*min-width: max-content;*/
    border:1px solid $neutral-200;
    background-color:$true-white;
    border-radius:4px;

    
    overflow-y:auto;
    overflow-x:hidden;
    /*scrollbar-gutter: stable;*/
}

.StandardList-Content   {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    align-items: stretch;
    flex:1;
}

.StandardList-Groups    {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1;
    /*min-width: max-content;*/
}

.StandardList-Group {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    /*min-width: max-content;*/

}
.StandardList-Group-HeaderContainer  {
    display: flex;
    flex-direction: column;
    flex-grow:1;    
}

.StandardList-Group-Header  {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding: 8px 16px;
    gap: 10px;
    
    /* Base/True White */
    background: $true-white;
}


.StandardList-Group-Rows    {
    display:flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 1;
    overflow-y:scroll;
    scrollbar-width: thin;
    /*min-width: max-content;*/
    /*margin-left:16px;*/
}

.StandardList-Rows  {
    display:flex;
    flex-direction: column;
    flex:1;
}

.StandardList-RowContainer  {
    display:flex;
    flex-direction: column;
    align-items: stretch;
}

.StandardList-Row   {
    display: flex;
    flex-direction: column;
    align-items:stretch;

    /* Surface/Neutral */
    flex-grow: 1;
    /*min-width: max-content;*/ 

    .StandardList-Row-ContentWrapper   {
        display: flex;
        flex-direction: column;
        align-items:stretch;
        position:relative;
    }

    .StandardList-Row-Content   {
        display: flex;
        flex-direction: column;
        align-items:stretch;
    }

    &.StandardList-Row-Selectable   {
        cursor: pointer;
    }

    &.StandardList-Row-Selected {
        background: $neutral-100;
    }

    .StandardList-Row-ColumnsContent  {
        display: flex;
        flex-direction: row;
        align-items:center;
        min-width: max-content;

    }

    .StandardList-Row-ColumnContainer   {
        display:flex;
        flex-direction: column;
        align-items: stretch;
        align-self:stretch;
    }

    .StandardList-Row-ColumnContent {
        display:flex;
        flex-direction: column;
        align-items: stretch;
        align-self:stretch;
        flex:1;
    }

    .StandardList-Row-Column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex:1;
        overflow-x:hidden;
        
        &:not(.StandardList-Row-Column-NoTruncate)  {
            align-items: flex-start;
            &, *  {
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        &.StandardList-Row-Column-NoTruncate  {
            align-items: stretch;
        }
    }

    .StandardList-Row-Column-Truncate   {
        &, *  {
            overflow-x:hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .StandardList-Row-Column-Primary   {
        display:flex;
        gap:10px;
        align-items: center;
        font-family: $main-font;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */
        
        /* Text/Text-Default */
        color: $text-neutral;
    }
    .StandardList-Row-Column-Secondary   {
        display:flex;
        gap:10px;
        align-items: center;
        font-family: $main-font;
        font-style: italic;
        padding-right:2px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */

        /* Text/Text-Subdued */
        color: $text-neutral-subdued;
    }
    .StandardList-Row-Column-Button {
        display:flex;
        align-self:stretch;
        align-items:center;
        justify-content: center;
        text-align: center;
    }
    .StandardList-Row-Column-Trash   {
        color:$critical;
    }
    
    .StandardList-Row-ExpandedContent   {
        display:flex;
        flex-direction: column;
        overflow-y:hidden;
        align-self:stretch;
    }
}

.StandardList-Row-ExpandButton  {
    display:flex;
    align-self:center;
    cursor: pointer;
}
.StandardList-Row-DefaultExpandButton  {
    width:20px;
    height:4px;
    background-color: $true-white;
}