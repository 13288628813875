@import '../globals.scss';

.ImageCarousel-Container  {
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch; 
    touch-action: pan-x;
}

.ImageCarousel  {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    position:relative;
    overflow:hidden;
}


.ImageCarousel-Image-Container    {
    position:absolute;
    top:0;
    bottom:0;
    display:flex;
    align-content:stretch;
    justify-content: stretch;
    transition: left 500ms ease, opacity 500ms ease;
}
.ImageCarousel-Image    {
    display:flex;
    flex:1;
    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #F8F8F8; 
}

.ImageCarousel-Image-Content    {
    display:flex;
    align-self:stretch;
    flex:1;

    >img    {
        width:100%;
    }
}

.ImageCarousel-Image-Loading    {
    display:flex;
    align-self:stretch;
    flex:1;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.ImageCarousel-Image-EmptyContent   {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch; 
    flex:1;
    text-align: center;
}