@import '../../globals.scss';


#AddToInventoryPopup  {
    
}


.AddToInventoryPopup-DetailsForm    {
    display:flex;
    flex-direction: column;
    gap:24px;
} 

.AddToInventoryPopup-AddIntentoryItemInputs {
    align-self:flex-start;

    th  {
        text-align: left;
    }

    td  {
        min-width:25%;
        
    }

    .AddToInventoryPopup-NewIntentoryItemTypeProperty   {

    }
}


.AddToInventoryPopup-NewIntentoryItemTypeCategoriesDisplay  {
    display:flex;
    flex-direction: row;
    gap:8px;
}


.AddToInventoryPopup-NewIntentoryItemTypeProperties {
    align-self:flex-start;

    th  {
        text-align: left;
    }

    td  {
        min-width:25%;
    }

    .AddToInventoryPopup-NewIntentoryItemTypeProperty   {

    }
}



.AddToInventoryPopup-NewIntentoryItemTypeInputs {
    align-self:flex-start;

    th  {
        text-align: left;
    }

    td  {
        min-width:25%;
    }

    .AddToInventoryPopup-NewIntentoryItemTypeInput   {

    }
}