@import '../../globals.scss';


.GrowTimelineBar-Container   {
    display:flex;
    flex-direction: column;
    gap:4px;

    flex:1;
}

.GrowTimelineBar {
    display:flex;
    flex-direction: row;
    flex-grow:1;
    /*border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);*/
    /*background: #F7F8FB;*/
}

.GrowTimelineBar-Content    {
    display:flex;
    flex:1;
}


.GrowTimelineBar-Bars {
    display:flex;
    align-self:stretch;
    position: relative;
    height:15px;
}

.GrowTimelineBar-Bar  {
    position:absolute;
    top:0;
    bottom:0;
    height:100%;
    gap:10px;

    &.GrowTimelineBar-Bar-Active {
        .GrowTimelineBar-Bar-Content    {
            border-color:var(--Main-Blue, #1D65F0);
        }
    }
}


.GrowTimelineBar-Bar-Content  {
    display:flex;
    align-self: stretch;
    position: relative;
    flex:1;
    height:100%;
    background-color: #F0F4FB;
    border-radius: 2px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #F0F4FB;
    overflow:hidden;
}

.GrowTimelineBar-Bar-ProgressBar  {
    position:absolute;
    top:0;
    bottom:0;
    height:100%;
    background: #D2E0FC;
}



.GrowTimelineBar-EventEntry   {
    position:absolute;
    top:50%;
    bottom:50%;
    width:0;
    height:0;
    cursor:pointer;
}

.GrowTimelineBar-EventEntry-Container   {
    display:flex;
    position: relative;
    transform: translate(-50%, -50%);
    padding:10px;
}
.GrowTimelineBar-EventEntry-Content {
    display:flex;
    position: relative;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.GrowTimelineBar-EventEntry-SingleEntry   {
    position:absolute;
    width: 6px;
    height: 6px; 
    border-radius:6px;
    background-color:#9CA6B4;
    transition:background-color 500ms ease;
}

.GrowTimelineBar-EventEntry-SingleEntry-Selection {
    position:absolute;
    width: 10px;
    height: 10px; 
    border-radius: 10px;
    border:1px solid $main-blue;
    opacity:0;
    transition:opacity 500ms ease;
}


.GrowTimelineBar-EventEntry-Selected .GrowTimelineBar-EventEntry-SingleEntry  {
    background-color: $main-blue;
}
.GrowTimelineBar-EventEntry-Selected .GrowTimelineBar-EventEntry-SingleEntry-Selection  {
    opacity: 1.0;
}



.GrowTimelineBar-EventEntry-MultiEntry   {
    position: absolute;
    display:flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;
    border-radius:10px;
    background-color:#9CA6B4;
    transition:background-color 500ms ease;
}

.GrowTimelineBar-EventEntry-Selected .GrowTimelineBar-EventEntry-MultiEntry  {
    background-color: $main-blue;
}


.GrowTimelineBar-EventEntry-MultiEntry-Value   {
    color: var(--White, #FFF);
    text-align: center;
    font-family: Roboto;
    font-size: 7px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
    
}


.GrowTimelineBar-Dates-Container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.GrowTimelineBar-Dates-StartDate, .GrowTimelineBar-Dates-EndDate   {
    display:flex;
    width:20ch;
}
.GrowTimelineBar-Dates-EndDate  {
    justify-content: flex-end;
}

.GrowTimelineBar-Dates-Progress   {
    display:flex;
    flex:1;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap:2px;
}