@import '../../globals.scss';


.KeypadInput_Keypad {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media (min-width: 767px) {
        gap: 10px;
    }
    @media (max-width: 767px) {
        gap: 6px;
    }
    align-self: stretch; 
}


.KeypadInput_Keypad-KeyRow  {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (min-width: 767px) {
        gap: 10px;
    }
    @media (max-width: 767px) {
        gap: 6px;
    }
    flex: 1 0 0;
    align-self: stretch; 
}

.KeypadInput_KeyPad-Key {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch; 

    border-radius: 4px;

    &.KeypadInput_KeyPad-DefaultKey    {
        border: 2px solid var(--Section-Border, #DDE3EB);
        background: #F7F8FB; 

        color: #191C22;
        font-family: $main-font;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal; 
    }
    &.KeypadInput_KeyPad-BackspaceKey    {
        border: 2px solid var(--Red, #EC3C3C);
        background: #FBEAEA; 
    }
}




.KeypadInput_DisplayField   {
    display: flex;
    min-width: 194px;
    padding: 30px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 10px;
    border-width: 2px;
    border-style: solid;

    &:not(.KeypadInput_DisplayField-Active) {
        border-color:#DDE3EB;
    }
    &.KeypadInput_DisplayField-Active   {
        border-color:#2E72D2;
    }
}


.KeypadInput_DisplayField-Value-Container    {
    display: flex;
    justify-content: center;
    align-items: center; 
    gap:10px;
}


.KeypadInput_DisplayField-Value-Content    {
    display:flex;
    flex-direction: row;
    gap:0px;
    justify-content:stretch;
}

.KeypadInput_DisplayField-Value-PlaceholderDisplay  {
    color: var(--Text, #919297);
    text-align: center;
    font-family: $main-font;
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
}

.KeypadInput_DisplayField-Value-Display    {
    color: var(--Text, #191C22);
    text-align: center;
    font-family: $main-font;
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.KeypadInput_DisplayField-Value-Caret   {
    display:flex;
    width:2px;
    
    background-color: #2E3D48;
    -webkit-animation: 1s blink step-end infinite;
    -moz-animation: 1s blink step-end infinite;
    animation: 1s blink step-end infinite;
}

@keyframes blink {
    from, to {
        background-color: transparent;
    }
    50% {
        background-color: black;
    }
  }
  
  @-moz-keyframes blink {
    from, to {
        background-color: transparent;
    }
    50% {
        background-color: black;
    }
  }
  
  @-webkit-keyframes blink {
    from, to {
        background-color: transparent;
    }
    50% {
        background-color: black;
    }
  }
  

.KeypadInput_DisplayField-Suffix    {
    color: var(--Text, #191C22);
    text-align: center;
    font-family: $main-font;
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
}