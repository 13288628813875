@import '../../../globals.scss';



#GerminationStationsStatus  {
    display:flex;
    flex-direction: column;
    overflow-y:hidden;
    flex:1;
}

.GerminationStationsStatus-NoGroupsContent  {
    display: flex;
    align-self:stretch;
    flex:1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 19px; 

    >span:nth-child(1)   {
        font-family:$main-font;
        color: #191C22;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal; 
    }
}



.GerminationStationsStatus-GerminationStations {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    overflow-y:auto;
    align-self:stretch;
    flex:1;
}
