@import '../globals.scss';


.Switch {
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    gap:6px;
    &.Switch-Disabled {
        opacity:0.4;
        cursor: default;
        pointer-events: none;
    }

    
    &.Switch-On {
        .Switch-Content {
            background: #2E72D2;
        }
    }
    &.Switch-Off {
        .Switch-Content {
            background: #DBE0EA;
        }
    }


    &.Switch-WithBorder {
        padding: 8px 10px; 
        border-radius: 4px;
        border: 1px solid var(--section-border, #2E72D2);
    }

    .Switch-Content {
        display:flex;
        flex-direction: row;
        align-items:stretch;

        

        margin:0;

        /* Base/True White */
        background: #FFFFFF;

    }


    .Switch-Thumb   {
        margin:2px;
        background: #FFFFFF;
        position:relative;
        transition:all 150ms ease;
    }

    
    .Switch-Button-Main    {
        display:flex;
        flex-direction: row;
        align-items:center;

        padding: 8px 12px 8px 12px;
        
        cursor:pointer;

        &.Switch-Button-Off    {
            &.Switch-Button-Selected    {
                background: $neutral-200;

                .Switch-Button-Display  {
                    color: $text-neutral;
                }
            }
        }
        &.Switch-Button-On    {
            &.Switch-Button-Selected    {
                background: $interactive;

                .Switch-Button-Display  {
                    color: $true-white;
                }
            }
        }

        &:not(:last-child)   {
            border-right: 1px solid $neutral-300!important;
        }
    
        .Switch-Button-Display  {
            font-family: $main-font;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            /* identical to box height, or 143% */ 
            
        }
    }

    

    

}




.Switch-Label {
    display:flex;
    align-self:center;
    color: var(--main-blue, #2E72D2);
    text-align: center;
    font-family: $main-font;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 

    &.Switch-Label-TurnOff {

    }
    &.Switch-Label-TurnOn {

        
    }
}
