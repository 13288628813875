@import '../globals.scss';


.ControlBar-Horizontal  {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding: 0px;
    gap: 8px;
    align-self:stretch;

    &.ControlBar-Center {
        flex:1;
        justify-content: center;
    }
}

.ControlBar-Horizontal-Center {
    /* Auto layout */
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    padding: 0px;
    gap: 8px;
    margin:6px 0;
    align-self:stretch;
    align-items:center;
    align-content: center;
}

    
.ControlBar-Horizontal-Left, .ControlBar_Horizontal-Right  {
    /* Auto layout */
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    padding: 0px;
    margin:6px 0;
    align-items:center;
    align-content: center;

    &.ControlBar-Horizontal-Overflow {
        flex:1;
    }

}
.ControlBar-Horizontal-Left {
    order:0;
}
.ControlBar-Horizontal-Right    {
    order:1;
    flex-shrink: 0;
}
.ControlBar-Horizontal-Overflow  {
    overflow-x:auto;
    scrollbar-width: thin;
}