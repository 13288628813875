@import '../../globals.scss';

.ScanRackIcon   {
    #phone_scanning {
        animation: ScanRackIcon-PhoneAnimation 1s linear infinite;
        transform-origin: 40px 50px;
    }
}

@keyframes ScanRackIcon-PhoneAnimation  {
    0% {
        transform: scale(1.0);
        stroke: #2E72D2;
    }
    50% {
        transform: scale(2.0);
        stroke: #4EA222;
    }
    100% {
        transform: scale(1.0);
        stroke: #2E72D2;
    }
}


.RackLoadingIcon   {
    #loading_dots   {
        #dot_1  {
            animation: RackLoadingIcon-LoadingAnimation 0.8s ease infinite alternate;
        }
        #dot_2  {
            animation: RackLoadingIcon-LoadingAnimation 0.8s ease infinite alternate;
            animation-delay: 0.18s;
        }
        #dot_3  {
            animation: RackLoadingIcon-LoadingAnimation 0.8s ease infinite alternate;
            animation-delay: 0.35s;
        }
        transform-origin: center;
    }
}

@keyframes RackLoadingIcon-LoadingAnimation {
    0% {
        fill: #2E72D2;
        opacity:1.0;
        transform: translate(0, -15px) scale(1, 0.9);
    }
    60% {
        transform: translate(0, 0) scale(1, 1);
    }
    100% {
        fill: #1E4292;
        opacity:0.1;
    }
}

@keyframes RackLoadingIcon-LoadingAnimation2 {
    0% {
        fill: #2E72D2;
    }
    50% {
        fill: #2E72D2;
    }
    100% {
        fill: #1E4292;
    }
}

@keyframes RackLoadingIcon-LoadingAnimation3 {
    0% {
        fill: #1E4292;
    }
    50% {
        fill: #2E72D2;
    }
    100% {
        fill: #2E72D2;
    }
}