@import '../globals.scss';


.AnalogGauge-Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    align-self:stretch;
    gap: 16px;

    
    .CircularGauge-ValueDisplay {
        display: flex;
        flex-direction: column;
        font-size: 160%;
        align-content:center;
        align-items: center;
    }
    .CircularGauge-AuxText {
        display: flex;
        flex-direction: column;
        font-size: 80%;
        align-content:center;
        align-items: center;
        color:$text-neutral-subdued;
    }
}

.AnalogGauge-Gauge  {
    display:flex;
    position: relative;
    align-items: stretch;
    align-self:stretch;
}

.AnalogGauge-Gauge-Canvas   {
    position: absolute;
    width:100%;
    height:100%;
}


.AnalogGauge-Gauge-Trail    {
    stroke-width:8px;
    stroke: rgb(221, 227, 235); 
    stroke-linecap: butt; 
    transform-origin: center center 0px; 
}

.AnalogGauge-Gauge-Actual   {
    stroke-width:8px;
    stroke: $main-blue;
    stroke-linecap:butt; 
    transform-origin: center center 0px; 
    transition: all 200ms ease-in-out;
}

.AnalogGauge-Gauge-MinRangeTick {
    stroke-width:0.5px;
    stroke: rgb(0, 0, 0); 
    transform-origin: center 100% 0px;
}

.AnalogGauge-Gauge-MaxRangeTick {
    stroke-width:0.5px;
    stroke: rgb(0, 0, 0); 
    transform-origin: center 100% 0px;
}


.AnalogGauge-Gauge-Content  {
    display:flex;
    flex:1 0;
    align-self: stretch;
    align-content: center;
    align-items: center;
    justify-content: center;
}
.AnalogGauge-Gauge-StandardContent  {
    padding-top:25px;
    color: var(--Main-Blue, #1D65F0);
    font-family: "DM Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}