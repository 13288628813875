@import '../globals.scss';


.PageHeader {
    display: flex;
    flex-grow: 1, 0;
    align-self:stretch;
    flex-direction: column;
    align-items: stretch;
    gap: 20px;
    

    flex-shrink: 0;

    &.PageHeaderLarge   {
        padding: 0px 24px 0px 24px;
    }

    &.PageHeaderCondensed   {
        padding: 0px 20px 0px 20px;
    }
    /* Neutral/Neutral 300 */

    
    margin-bottom:8px;

}



.PageHeader-Wrapper {
    display:flex;
    flex-direction: column;
    align-self:stretch;
    border-bottom: 1px solid $neutral-300;
}
.PageHeader-MainContent {
    display: flex;
    flex-grow: 1, 0;
    align-self:stretch;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;

    overflow-x:hidden;
}

.PageHeaderNavigationToggle {
    display:flex;

    cursor:pointer;
    fill:$neutral-600;

    :hover  {
        fill:$neutral-900;
    }
}

.PageHeaderNavigationContainer  {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding:0px 2px 0px 0px;

    gap:20px;

    /* Inside auto layout */
    order: 0;
    overflow-x: hidden;


    @media (min-width: 767px) {
        min-width:50px;
    }
    @media (max-width: 766px) {
        min-width:100px;
    }

    .PageHeaderNavigationToggle {
        width: max-content;
    }

    .PageHeaderNavigationTitleContainer {
        display:flex;
        flex-direction: column;
        overflow-x:hidden;
        align-self: stretch;
        flex-shrink:1;
    }

    .PageHeaderNavigationTitle {
        overflow-x:hidden;
        > * {
            overflow:hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    
    .PageHeaderNavigationTitle-Title    {
        color: #191C22;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: $header-font;
        font-style: normal;
        font-weight: 600;
        line-height: normal; 

        @media (min-width: 767px) {
            font-size: 24px;
        }
        @media (max-width: 766px) {
            font-size: 20px;
        }
    
    }
}


.PageHeader_MiddleContent {
    display: flex;
    order:1;
    align-self: stretch;
    overflow-x:auto;
    scrollbar-width:none;
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    &::-webkit-scrollbar:horizontal {    
        height: 0px;
    }
}

.PageHeader_TabControl_Tabs {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 0px 20px;

    overflow-x:auto;
    scrollbar-width:none;
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    
    &::-webkit-scrollbar:horizontal {    
        height: 0px;
    }

    .TabControl_Tab     {
        padding-top:2px;
        padding-bottom:2px;
    }

}


.PageHeader_SubTabControl_Tabs  {
    display: flex;
    flex: 0;

    flex-direction: row;
    align-items: flex-start;
    padding: 0px 0px;

    overflow-x:scroll;
    scrollbar-width:none;
    ::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    



    .TabControl_Tab     {
        .TabControl_TabLabel    {
            padding:13px 10px 10px;
        }
    }

    
}

.PageHeaderNavigationBackButton {
    display:flex;
    align-items: center;
    align-self: stretch;
    cursor:pointer;
    padding-right:8px;
}

.PageHeaderFunctionContainer    {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;

    /* Inside auto layout */
    order: 2;

    justify-content: flex-start;
    /*flex:1 0 auto;*/

    &.PageHeaderFunctionContainer-WithOverflow  {
        overflow-x:auto;
        scrollbar-width: thin;
    }
    flex-grow:1;

    
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    
    &::-webkit-scrollbar:horizontal {    
        height: 0px;
    }
    

    .PageHeaderFunctions    {
        display:flex;
        flex-direction: row;
        flex-grow:1;
        justify-content: flex-end;
        align-self: stretch;
        gap:12px;
        align-items: center;
    }
}

