@import '../../globals.scss';

#ManufacturingPage   {
    display:flex;
    flex-direction: column;
    overflow-y:hidden;
    flex:1;
    gap:4px;
}

#ManufacturingPage_TabContent   {
    overflow-y:auto;
}