@import '../globals.scss';


.ProgressBar-Container {
    display: flex;
    flex-direction: column;
    align-self:stretch;
    border-radius: 2px;
}

.ProgressBar    {
    display:flex;
    height:100%;
    border-radius: 2px;
}