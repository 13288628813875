@import '../../../globals.scss';


#RecipesPage  {
    display:flex;
    flex-direction: column;
    align-content: flex-start;
    flex-grow: 1;
    overflow-y:hidden;
}

#RecipePage  {
    display:flex;
    flex-direction: column;
    align-content: flex-start;
    flex-grow: 1;
    overflow-y:hidden;
}


#RecipePage_TabContent  {
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;
    flex: 1 0 0;
    align-self: stretch; 
    overflow-y: hidden;
    gap: 20px;

    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #FFF; 
}

.RecipePage_TimelineContainer   {
    display: flex;
    align-items: stretch;
    align-self: stretch;
    gap: 6px;

    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #F7F8FB; 

    @media (min-width: 767px) {
        padding: 6px 10px;
    }
    @media (max-width: 767px) {
        padding: 0px 6px; 
    }
}


#Recipe-ConfigManager  {
    display: flex;
    padding: 20px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch; 
    flex:1;

    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #F7F8FB; 
    overflow-y:auto;
}

#Recipe-TimelineManager    {
    display: flex;
    padding: 6px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch; 
    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #F7F8FB; 

    overflow-y:auto;
}


.Recipe-TimelineItems  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch; 
}

.Recipe-TimelineItem    {
    display: flex;
    padding: 14px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch; 

    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #FFF; 
}

.Recipe-TimelineItem-Header    {
    display: flex;
    align-items: center;
    gap: 10px; 
    align-self: stretch;
}

.Recipe-TimelineItem-Header-PrimaryContent  {
    display: flex;
    align-items: center;
    flex:1;
    gap: 10px; 
}


.Recipe-TimelineItem-Header-Step    {
    color: #191C22;
    font-family: $main-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
}

.Recipe-TimelineItem-Header-Title  {
    color: #191C22;
    font-family: $main-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal; 
}

.Recipe-TimelineItem-Content    {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch; 
    gap: 10px; 
}



.Recipe-TimelineItem-Content-Row    {
    display:flex;
    flex-direction: column;
    justify-content: stretch;
    align-self: stretch;
    gap:10px;
}

.Recipe-TimelineItem-Content-Row-Content    {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch; 
}

.Recipe-TimelineItem-Content-Row-PrimaryContent    {
    display: flex;   
    overflow-x: hidden;

    @media (min-width: 767px) {
        gap: 20px;  
        flex:1;            
        align-items: center;
    }
    @media (max-width: 767px) {
        gap: 10px;  
        flex-direction: column;
        align-items:flex-start;
    }
}


.Recipe-TimelineItem-Content-Row-PrimaryContent-TitleDisplay    {
    display:flex;
    flex-direction: row;
    gap:20px;
    align-items: center;
    align-self:stretch;
}


.Recipe-TimelineItem-Content-Row-PrimaryContent-TitleDisplay-Name    {
    color: #191C22;
    font-family: $main-font;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.Recipe-TimelineItem-Content-Row-PrimaryContent-TitleDisplay-NameInput    {
    display:flex;
    flex-shrink: 1;
    padding:2px;
    overflow-x:hidden;
}


.Recipe-TimelineItem-Content-Row-PrimaryContent-TitleDisplay-Functions    {
    display:flex;
    flex-shrink: 0;
    gap: 6px;
}

.Recipe-TimelineItem-Content-Row-PrimaryContent-InfoEntryRow    {
    display:flex;
    justify-content: space-between;
    align-self: stretch;
}

.Recipe-TimelineItem-Content-Row-PrimaryContent-InfoEntry   {
    display: flex;
    align-items: flex-start;
    gap: 4px; 
    >div:nth-child(1)   {
        color: #191C22;
        font-family: $main-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 
    }
    >div:nth-child(2)   {
        color: #9CA6B4;
        font-family: $main-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 

        
    }
}

.Recipe-TimelineItem-Content-Row-SecondaryContent   {
    display: flex;
    align-items: center;
    gap: 20px;  
}


.Recipe-TimelineItem-Content-Row-SecondaryContent-Functions   {
    display: flex;
    align-items: center;
    gap: 10px;  
}

.Recipe-TimelineItem-Content-Row-Divider    {
    height: 1px;
    width:100%;
    align-self: stretch; 
    background-color: #DDE3EB;
    margin-bottom:10px;
}


.Recipe-TimelineItem-NextInFlowButtons  {
    display:flex;
    gap: 8px;
}


#Recipe-TimelineItem-ReorderingTimelineItem   {
    position:fixed;
    z-index:101;
}
#Recipe-TimelineItem-ReorderingTimelineItem-BlockingInteractivity   {
    position:fixed;
    left:0;
    right:0;
    bottom:0;
    top:0;
    width:100%;
    height:100%;
    background-color: rgba(0,0,0,0.1);
    z-index:100;
}





#Recipe-NutrientsManager    {
    display:flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 1;
}

#Recipe-NutrientsManager-Content    {
    display:flex;
    flex-direction: row;
}

#Recipe-Nutrients-Parts  {
    display:flex;
    flex-direction: column;
    gap: 8px;

    order:1;
    flex:1;
}

.Recipe-Nutrients-Part  {
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    /* Base/True White */
    background: $true-white;

    /* Accent/Neutral */
    border: 1px solid $accent-neutral;

    /* shadow-secondary */
    box-shadow: 47px 50px 27px rgba(187, 193, 204, 0.01), 26px 28px 23px rgba(187, 193, 204, 0.03), 12px 12px 17px rgba(187, 193, 204, 0.04), 3px 3px 9px rgba(187, 193, 204, 0.05), 0px 0px 0px rgba(187, 193, 204, 0.05);
    border-radius: 4px;

    .Recipe-Nutrients-Part-Content    {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        margin: 12px 12px;
        gap: 12px;
        flex-grow:1;
        order:1;
    }

    .Recipe-Nutrients-Part-Mover {
        
        svg    {
            width:25px;
            height:25px;
        }
    }

    .Recipe-Nutrients-Part-Name  {
        min-width:50px;
    }


    .Recipe-Nutrients-Part-FunctionsContainer    {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        padding: 0px;
        order:2;
    }
    .Recipe-Nutrients-Part-Functions {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
        margin:12px 12px;

    }

    .Recipe-Nutrients-Part-Duplicate {
        color:$text-neutral;
        width:20px;
        height:20px;
    }

    .Recipe-Nutrients-Part-Trash {
        color:$critical;
        width:15px;
        height:15px;
    }
}

.Recipe-Nutrients-Subparts  {
    display:flex;
    flex-direction: column;
    gap: 8px;
    margin-left:20px;
    margin-bottom:10px; 
}

#Recipe-Nutrients-ReorderinPart   {
    position:fixed;
}

#Recipe-Nutrients-CompositionDisplay  { 
    display:flex;
    flex-direction: column;
    
    order:2;
    flex:1;
}



.RecipePage_CycleTimeline   {
    display: flex;
    padding: 4px 10px;
    align-items: flex-start;
    gap: 6px;
    align-self: stretch; 
    padding:6px 0 0 0;

    min-width:200px;

    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #F7F8FB; 

    .RecipePage_CycleTimeline-CycleSelectionContainer   {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
    }
    .RecipePage_CycleTimeline-CycleSelection {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 8px;
    }


    
}

.RecipePage_CycleTimeline-Visualization {
    display:flex;
    flex-direction: row;
    flex-grow:1;
    gap:8px;

    .RecipePage_CycleTimeline-VisualizationContainer    {
        display:flex;
        align-self:stretch;
        flex-direction: column;
        flex:1;

        
        .RecipePage_CycleTimeline-VisualizationBars {
            display:flex;
            align-self:stretch;
            height:15px;
            position:relative;

            .RecipePage_CycleTimeline-VisualizationBar  {
                position:absolute;
                top:0;
                bottom:0;
                height:100%;
                gap:10px;

                .RecipePage_CycleTimeline-VisualizationBarContent   {
                    display:flex;
                    position:relative;
                    flex-direction: column;
                    align-items: middle;
                    justify-content: center;
                    text-align: center;
                    height:100%;
                    box-sizing: border-box;
                    border-width:1px;
                    border-style:solid;
                    border-radius:5px;
                    border-color: transparent;
                    cursor:pointer;
                }


                .RecipePage_CycleTimeline-VisualizationBarLabel   {
                    display:flex;
                    position:relative;
                    flex-direction: column;
                    align-items: middle;
                    justify-content: center;
                    text-align: center;
                    height:100%;
                    box-sizing: border-box;
                    border-width:1px;
                    border-style:solid;
                    border-color: transparent;
                    cursor:pointer;
                    font-size:12px;
                }

                .RecipePage_CycleTimeline-VisualizationBarProgress  {
                    position:absolute;
                    top:0;
                    bottom:0;
                    height:100%;
                }
                .RecipePage_CycleTimeline-VisualizationBarProgressLine  {
                    position:absolute;
                    top:0;
                    bottom:0;
                    height:100%;
                    width:2px;
                }
            }
        }


        .RecipePage_CycleTimeline-VisualizationTicks    {
            display:flex;
            align-self:stretch;
            height:15px;
            position:relative;

            .RecipePage_CycleTimeline-VisualizationTickContent {
                display:flex;
                flex-direction: column;
                position:absolute;
                top:0;
                bottom:0;
                height:100%;
                align-items:center;

                .RecipePage_CycleTimeline-VisualizationTick    {
                    width:1px;
                    height:6px;
                    background-color: $text-neutral;
                }

                .RecipePage_CycleTimeline-VisualizationTickLabel    {
                    font-family: $main-font;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 12px;
                    /* identical to box height, or 133% */
                    text-align: center;
                    
                    /* Text/Text-Subdued */
                    color: $text-neutral-subdued;
                }
            }
        }
    }
      
    .RecipePage_CycleTimeline-VisualizationDurationDisplay  {
        display:flex;
        flex-direction: column;
        justify-content: center;
        height:25px;
        align-content: center;
        vertical-align: center;
    }

    .RecipePage_CycleTimeline-CycleManagementLink   {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        height:25px;
        align-content: center;
        vertical-align: center;

        cursor:pointer;

        /* Base/Interactive */
        color: $interactive;

        >svg    {
            width:20px;
            height:20px;
        }
    }
}



#Recipe-ZoneManager   {
    position: relative;
    display:flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 1;
    align-self: stretch;
    overflow-y: auto;
}

#Recipe-ZoneManager-Zones   {
    display:flex;
    flex-direction: row;
    align-items: stretch;
    order:0;
    flex-grow: 1;
    overflow: hidden;
    gap:10px;
}

#Recipe-ZoneManager-SetpointSelection {
    display:flex;
    flex-direction: column;
    align-items: stretch;
    order:0;

    .Recipe-ZoneManager-Zone    {
        display:flex;
        flex-direction: row;
        align-items:flex-start;
        background-color: $true-white;
        overflow: hidden;

        &:first-child   {
            border-radius: 16px 16px 0px 0px;
        }
        &:last-child    {
            border-radius: 0px 0px 16px 16px;
        }

        .Recipe-ZoneManager-Zone-Type   {
            display:flex;
            flex-direction: row;
            align-items: center;
            align-self: stretch;
            cursor:pointer;
            >svg    {
                color:$true-white;
                width: 30px;
                height: 30px;
            }

            background-color: $neutral-500;
            &.Recipe-ZoneManager-Zone-SelectedType  {
                background-color: $interactive;
            }
        }
        .Recipe-ZoneManager-Zone-Setpoints   {
            display:flex;
            flex-direction: column;
            padding: 10px 10px;
            gap: 2px;
            flex-grow:1;

            &.Recipe-ZoneManager-SelectedZone-LightingSetpoints {
                padding: 4px 10px;

            }

            &.Recipe-ZoneManager-SelectedZone-Setpoints {
                gap: 10px;
                align-self:stretch
            }
            
            .Recipe-ZoneManager-SelectedZone-SetpointType   {
                box-sizing: border-box;

                /* Auto layout */
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 8px;
                gap: 10px;

                cursor: pointer;


                /* Neutral/Neutral 500 */
                border: 1px solid $neutral-500;

                /* shadow-tertiary */
                box-shadow: 16px 20px 16px rgba(187, 193, 204, 0.01), 7px 9px 12px rgba(187, 193, 204, 0.01), 2px 2px 6px rgba(187, 193, 204, 0.01), 0px 0px 0px rgba(187, 193, 204, 0.01);
                border-radius: 4px;

                &.Recipe-ZoneManager-SelectedZone-SetpointTypeSelected   {
                    /* Neutral/Neutral 300 */
                    background: $neutral-300;
                }


                .Recipe-ZoneManager-SelectedZone-SetpointType-ActiveToggle {
                    .MuiButtonBase-root {
                        padding:0;
                    }
                }

                .Recipe-ZoneManager-SelectedZone-SetpointType-Indicator    {
                    width:8px;
                    height:8px;
                    border-radius:8px;
                }
            }

            .Recipe-ZoneManager-Zone-SetpointType   {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px;
                gap: 4px;

                .Recipe-ZoneManager-Zone-SetpointType-Indicator    {
                    width:8px;
                    height:8px;
                    border-radius:8px;
                }
                .Recipe-ZoneManager-Zone-SetpointType-Name    {
                    font-family: $main-font;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 16px;

                    /* identical to box height, or 0% */

                    /* Text/Text-Subdued */
                    color: #4D5563;
                }                
            }

        }
    }
}

#Recipe-ZoneManager-SetpointChartWrapper    {
    position: relative;
    display:flex;
    align-items: stretch;
    order:1;
    flex-grow:1;
    touch-action: none;
}
#Recipe-ZoneManager-SetpointChartContainer   {
    display:flex;
    align-items: stretch;
    flex-grow:1;

    background-color: $true-white;
    border-radius:16px;
    /*overflow: hidden;
    isolation: isolate;*/

    #Recipe-ZoneManager-SetpointChart   {
        display:flex;
        flex-grow:1;
    }  
}


#Recipe-ZoneManager-SetpointChart_TooltipBoard {
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    pointer-events:none;
    z-index:1000;
}


#Recipe-ZoneManager-SetpointChart_Tooltip-Container    {
    position:absolute;
    height:0;
    width:0;

    z-index:99999;
}

#Recipe-ZoneManager-SetpointChart_Tooltip  {
    display:flex;
    position: absolute;
    flex-direction: column;

    min-width:100px;
    min-height:40px;
    /* Base/True White */
    background: #FFFFFF;
    /* Neutral/Neutral 300 */
    border: 1px solid $neutral-300!important;
    border-radius: 4px;
    
    margin:0;
    gap: 4px;


    /* shadow-tertiary */
    box-shadow: 16px 20px 16px rgba(187, 193, 204, 0.01), 7px 9px 12px rgba(187, 193, 204, 0.01), 2px 2px 6px rgba(187, 193, 204, 0.01), 0px 0px 0px rgba(187, 193, 204, 0.01);

    &:not(.Recipe-ZoneManager-SetpointChart_SetpointControl)  {
        padding: 5px;
    }
    &.Recipe-ZoneManager-SetpointChart_SetpointControl  {
        pointer-events: auto!important;
        padding: 20px;
        gap: 16px;
    }
}

.Recipe-ZoneManager-SetpointChart_Tooltip-Table {
    
}

.Recipe-ZoneManager-SetpointChart_Tooltip-Unit  {
    margin-left:4px;
    font-family: $main-font;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 167% */

    /* Text/Text-Subdued */
    color: $text-neutral-subdued;


}

.Recipe-ZoneManager-SetpointChart_SetpointControl   {
    .Recipe-ZoneManager-SetpointChart_SetpointControl-Heading  {
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-content:center;
        flex-grow:1;

        font-family: $main-font;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        /* Text/Text-Default */
        color: $text-neutral;
    }

    .Recipe-ZoneManager-SetpointChart_SetpointControl-Inputs    {
        display:flex;
        flex-direction: row;
        gap: 16px;
    }

    .Recipe-ZoneManager-SetpointChart_SetpointControl-FunctionsContent  {
        display:flex;
        flex-direction: column;
        gap:4px;
    }
    .Recipe-ZoneManager-SetpointChart_SetpointControl-Functions {
        display:flex;
        flex-direction: row;
        align-items: flex-start;
        gap:8px;
        
        >div    {
            display:flex;
            align-content:center;
            justify-content: center;
            cursor:pointer;

            svg {
                width: 20px;
                height: 20px;
            }
        }
    }

    .Recipe-ZoneManager-SetpointChart_SetpointControl-FunctionInputs    {
        display:flex;
        flex-direction: row;
        gap: 16px;
    }
}

.Recipe-ZoneManager-CycleTimeline   {
    display:flex;
    order:1;
}



.Recipe-ZoneManager-LightingZoneCompositionDisplay   {
    display:flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    padding: 0px 10px;
    gap: 10px;
}

.Recipe-ZoneManager-LightingZoneCompositionDisplay-Items    {
    display:flex;
    flex-direction: row;
    align-self:stretch;

    .Recipe-ZoneManager-LightingZoneCompositionDisplay-Item  {
        display:flex;
        flex-direction: column;
        flex-basis:25%;
        align-items:center;
    }
    .Recipe-ZoneManager-LightingZoneCompositionDisplay-Value  {
        font-family: $main-font;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */

        /* Text/Text-Default */
        color: $text-neutral;
    }

    .Recipe-ZoneManager-LightingZoneCompositionDisplay-Type {
        width:20px;
        height:20px;
        border-radius:20px;


        font-family: $main-font;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        /* identical to box height, or 167% */

        align-items: center;
        text-align: center;

        color: #FFFFFF;

    }
}

.Recipe-ZoneManager-LightingZoneCompositionDisplay-Totals   {
    display:flex;
    flex-direction: column;
    align-items: center;
    >div    {
        font-family: $main-font;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        /* identical to box height, or 143% */

        color: $text-neutral-subdued;
    }
}


.Recipe-ZoneManager-LightingZoneCompositionControl   {
    display:flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    padding: 0px 10px;
    gap: 10px;
}

.Recipe-ZoneManager-LightingZoneCompositionControl-Items    {
    display:flex;
    flex-direction: row;
    align-self:stretch;
    flex-grow:1;
    gap:10px;

}




#Recipe-RelationshipsManager   {
    position: relative;
    display:flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 1;
    overflow-y:auto;
    align-self: stretch;
}


.Recipe-RelationshipsManager-Relationships  {
    border-collapse: separate; 
    border-spacing: 0px 16px;
        
    .Recipe-RelationshipsManager-Relationship  {
        padding:4px;
        /*box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        border-radius: 8px;*/
        
        .Recipe-RelationshipsManager-Relationship-MasterOptions    {
            >div    {
                display:flex;
                margin:4px 8px;
                padding: 12px 12px;
                box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
                border-radius: 8px;
                background: $true-white;
            }
        }

        .Recipe-RelationshipsManager-Relationship-Master    {
            >div    {
                display:flex;
                align-items:center;
                align-content:center;
                margin:4px 8px;
                padding: 4px 12px;
                box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
                border-radius: 8px;
                background: $true-white;
                cursor:pointer;
            }
        }

        .Recipe-RelationshipsManager-Relationship-Inputs    {
            >div    {
                display:flex;
                justify-content:center;
                margin:4px 8px;
                padding: 4px 12px;
                box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
                border-radius: 8px;
                background: $true-white;
            }
        }

        .Recipe-RelationshipsManager-Relationship-Inputs-Range {
            display:flex;
            gap:8px;
            align-items: center;
            justify-content: stretch;

            .Recipe-RelationshipsManager-Relationship-Inputs-Range-Master   {
                display:flex;
                flex-direction: column;
                align-items: center;
                gap:4px;
            }

            .Recipe-RelationshipsManager-Relationship-Inputs-Range-Slave   {
                display:flex;
                flex-direction: column;
                align-items: center;
                gap:4px;
            }

            .Recipe-RelationshipsManager-Relationship-Inputs-Range-Type {
                font-size:13px;
                color:$text-neutral-subdued;
            }
        }

        .Recipe-RelationshipsManager-Relationship-Inputs-Offset {
            display:flex;
            gap:8px;
            align-items: center;
            justify-content: stretch;
        }

        .Recipe-RelationshipsManager-Relationship-Slave    {
            >div    {
                margin:4px 8px;
                padding: 12px 12px;
                box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
                border-radius: 8px;
                background: $true-white;
            }
        }
    }
}