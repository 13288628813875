@import '../globals.scss';


.RadioButton {
    /* Auto layout */
    display: flex;
    align-content:stretch;

    >div    {
        display: flex;
        align-items: center;
        gap: 6px;
        flex-direction: row;
    }
}

.RadioButton-Dot    {
    
}

.RadioButton-Content    {

}