@import '../globals.scss';


.SelectRaftType-Container {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    gap:10px;
}

.RaftType-Raft    {
    padding:10px;
    border: 1px solid $neutral-300;
    box-shadow: 16px 20px 16px rgba(187, 193, 204, 0.01), 7px 9px 12px rgba(187, 193, 204, 0.01), 2px 2px 6px rgba(187, 193, 204, 0.01), 0px 0px 0px rgba(187, 193, 204, 0.01);
    border-radius: 4px;
    text-align: center;
    align-self:flex-start;

    cursor: pointer;
    background-color: $true-white;

    &.RaftType-Raft-Selected  {
        background-color: $interactive;
        color:$true-white;
    }
}