@import '../../../globals.scss';





#FlowStatus  {
    display:flex;
    flex-direction: column;
    overflow-y:hidden;
    flex:1;
}

.FlowStatus-NoGroupsContent  {
    display: flex;
    align-self:stretch;
    flex:1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 19px; 

    >span:nth-child(1)   {
        font-family:$main-font;
        color: #191C22;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal; 
    }
}



.FlowStatus-Flows {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    overflow-y:auto;
    align-self:stretch;
    flex:1;
}

.FlowStatus-Flow-Content  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;    
    overflow-y:hidden;

    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #FFF;

    @media (min-width: 767px) {
        padding: 20px;
    }
    @media (max-width: 766px) {
        padding: 20px 10px;
    }
}

.FlowStatus-Flow-InfoSide {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    align-self: stretch; 
}

.FlowStatus-Flow-OnlineStatusCard {
    display: flex;
    padding: 8px 20px;
    align-items: center;
    gap: 6px;
    align-self: stretch; 

    border-radius: 4px;

    .FlowStatus-Flow-OnlineStatusCard-StatusIndicator {
        width: 10px;
        height: 10px;
        border-radius:10px;
    }

    &.FlowStatus-Flow-OnlineStatusCard-Active  {
        border: 1px solid #4DBE3B;
        background: #DBF2D8;     
        .FlowStatus-Flow-OnlineStatusCard-StatusIndicator {
            background-color: #4DBE3B;
        }
    }
    &.FlowStatus-Flow-OnlineStatusCard-Inactive  {
        border: 1px solid var(--Red, #EC3C3C);
        background: #FBEAEA;   
        .FlowStatus-Flow-OnlineStatusCard-StatusIndicator {
            background-color: #EC3C3C;
        }
    }

    >div:nth-child(2)   {
        color: var(--Text, #191C22);
        font-family: $main-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal; 
    }
    >div:nth-child(3)   {
        color: var(--Text, #191C22);
        font-family: $main-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 
    }
}

.FlowStatus-Flow-CurrentActionCard    {
    display: flex;
    padding: 8px 20px;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch; 

    border-radius: 4px;
    border: 1px solid var(--Main-Blue, #DDE3EB);
    background: #FFF; 

    >div:nth-child(1)   {
        color: #191C22;
        text-align: center;
        font-family: $main-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal; 
    }

    .FlowStatus-Flow-CurrentActions   {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        gap: 6px;
        
        >div:nth-child(1)   {
            color: #4F5562;
            text-align: center;
            font-family: $main-font;
            font-size: 14px;
            font-style: italic;
            font-weight: 400;
            line-height: normal;
        }
        >div:nth-child(2)   {
            color: #4F5562;
            text-align: center;
            font-family: $main-font;
            font-size: 14px;
            font-style: italic;
            font-weight: 400;
            line-height: normal;
        }
        >div:nth-child(3)   {
            color: #4F5562;
            text-align: center;
            font-family: $main-font;
            font-size: 14px;
            font-style: italic;
            font-weight: 400;
            line-height: normal;
        }
    }
}

.FlowStatus-Flow-ManageCard   {
    display: flex;
    flex-direction: column;
    align-self:stretch;
    justify-content: stretch;
    align-items: stretch;
    gap: 10px;
    flex: 1 0 0; 

    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
}

.FlowStatus-Flow-ManageCard-Header    {
    display: flex;
    padding: 10px 15px;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
    gap: 10px;
    align-self: stretch; 

    border-radius: 4px 4px 0px 0px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #F7F8FB;
}

.FlowStatus-Flow-ManageCard-Content   {
    position:relative;
    display: flex;
    padding: 0px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex:1; 
}

.FlowStatus-Flow-Reservoirs-List-Container    {
    display:flex;
    /*position:absolute;
    width:100%;
    height:100%;
    overflow-y:hidden;
    left:0; top:0; right:0; bottom:0;*/
    flex-direction: column;
}
.FlowStatus-Flow-Reservoirs-List  {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    gap: 7px;
    align-self: stretch;
    flex-wrap: wrap; 
    overflow-y:auto;
    

    &.FlowStatus-Flow-Reservoirs-ListSize1 {
        .FlowStatus-Flow-Reservoirs-Reservoir-Container   {
            flex:1 0 100%;
        }
    }
    &.FlowStatus-Flow-Reservoirs-ListSize2 {
        .FlowStatus-Flow-Reservoirs-Reservoir-Container   {
            flex:0 0 calc(50% - (8px * 1/2));
        }
    }
    &.FlowStatus-Flow-Reservoirs-ListSize3 {
        .FlowStatus-Flow-Reservoirs-Reservoir-Container   {
            flex:0 0 calc(33.33% - (8px * 2/3));
        }
    }
    &.FlowStatus-Flow-Reservoirs-ListSize4 {
        .FlowStatus-Flow-Reservoirs-Reservoir-Container   {
            flex:0 0 calc(25% - (8px * 3/4));
        }
    }
    &.FlowStatus-Flow-Reservoirs-ListSize5 {
        .FlowStatus-Flow-Reservoirs-Reservoir-Container   {
            flex:0 0 calc(20% - (8px * 4/5)); 
        }
    }
}

.FlowStatus-Flow-Reservoirs-Reservoir-Container   {
    display:flex;
    flex-direction: column;
    justify-content: center;
    overflow-x: hidden;
}

.FlowStatus-Flow-Reservoirs-Reservoir   {
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;

    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #F7F8FB;
}

.FlowStatus-Flow-Reservoirs-Reservoir-MainContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch; 
    flex:1;
}

.FlowStatus-Flow-Reservoirs-Reservoir-MainContent-Header  {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    
    
}

.FlowStatus-Flow-Reservoirs-Reservoir-SolutionInfo-Container    {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    align-self: stretch;
}
.FlowStatus-Flow-Reservoirs-Reservoir-SolutionInfo-Name   {
    
}

.FlowStatus-Flow-Reservoirs-Reservoir-VolumeVisual-Container  {
    display:flex;
    position:relative;
    flex:1 0 auto;
    width:100%;
    height: 20px;
}

.FlowStatus-Flow-Reservoirs-Reservoir-VolumeVisual-Background {
    position: absolute;
    top:0px;
    bottom:0px;
    left:0px;
    right:0px;
    border-radius: 4px;
    background-color: #FFF;
    border-width:1px;
    border-style: solid;
    &.FlowStatus-Flow-Reservoirs-Reservoir-VolumeVisual-Background-Okay    {
        border-color:#2E72D2;
    }
    &.FlowStatus-Flow-Reservoirs-Reservoir-VolumeVisual-Background-Low    {
        border-color:#E99E0B;
    }
    &.FlowStatus-Flow-Reservoirs-Reservoir-VolumeVisual-Background-Empty    {
        border-color:#EC3C3C;
    }
}


.FlowStatus-Flow-Reservoirs-Reservoir-VolumeVisual-Bar {
    position: absolute;
    left:1px;
    top:1px;
    right:1px;
    bottom:1px;
    border-radius: 3px;
    min-width:4px;
    &.FlowStatus-Flow-Reservoirs-Reservoir-VolumeVisual-Bar-Okay    {
        background-color: #E4EAF5;
    }
    &.FlowStatus-Flow-Reservoirs-Reservoir-VolumeVisual-Bar-Low    {
        background-color: #F8EBD2;        
    }
    &.FlowStatus-Flow-Reservoirs-Reservoir-VolumeVisual-Bar-Empty    {
        background-color: #EC3C3C;        
    }
}

.FlowStatus-Flow-Reservoirs-Reservoir-VolumeAvailable   {
    display: flex;
    align-items: flex-end;
    gap: 4px;
}


.FlowStatus-Flow-Reservoirs-Reservoir-VolumeVisual-LowVolumeIndicator {
    position: absolute;
    top:1px;
    bottom:1px;
    width:1px;
    background-color:#000;
}



.FlowStatus-Flow-Reservoirs-Reservoir-Functions-Container {
    display: flex;
    align-items: stretch;
    gap: 6px;
    align-self: stretch; 
}