@import '../../../globals.scss';


.ControlDevicesStatus   {
    display:flex;
    flex-direction: column;
    overflow-y:hidden;
    flex:1;
}

.ControlDevicesStatus-ControlDeviceGroups   {
    
}