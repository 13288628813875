@import '../globals.scss';



.GridTile-WrappingTiles {
    display:flex;
    flex-direction: row;
    flex-wrap:wrap;
    overflow: hidden;
    flex-grow:1;
}

.GridTile  {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-self:stretch;
    justify-content: center;
    flex:1;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    background: #fafafa;
    justify-content: flex-start; 
    margin: 8px;
    overflow-x:hidden;

    &.GridTile-GaugeTile    {
        .GridTile-Content   {
            .CircularGauge  {
                margin:12px 6px;
            }
        }
    }

    &.GridTile-FullWidth    {
        flex-grow:1;
        width:100%;
    }
}

.GridTile-Content   {
    display:flex;
    flex-direction:column;
    align-content: center;
    justify-content: center;

    padding:5px;

    gap:4px;
}

.GridTile-Header {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
}

.GridTile-Title    {
    font-family: $main-font;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    
    /* Text/Text-Default */
    color: $text-neutral;
    
    order:0;
}

.GridTile-StatusIndicator  {
    width:10px;
    height:10px;
    border-radius: 10px;

    order:1;
}

.GridTile-Details   {
    
}

.GridTile-DataItem  {
    display:flex;
    gap:4px;
    justify-content:center;
    align-content:center;

    .GridTile-DataItem-Label    {
        font-family: $main-font;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        /* identical to box height, or 143% */
        
        /* Text/Text-Default */
        color: $text-neutral;
    }
    .GridTile-DataItem-Value    {
        font-family: $main-font;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */
        
        /* Text/Text-Default */
        color: $text-neutral;
    }
}

.GridTile-OverrideItem  {
    display:flex;
    flex-direction: column;
    gap:4px;
    .GridTile-OverrideItem-Toggle   {
        display:flex;
        gap:8px;
    }
    .GridTile-OverrideItem-Value    {

    }
}




.GridTile-DataItemTable   {
    border-collapse: separate; 
    border-spacing: 2px 0px;

    .GridTile-DataItem-Row  {

        .GridTile-DataItem-Row-Label    {
            font-family: $main-font;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            /* identical to box height, or 143% */
            
            /* Text/Text-Default */
            color: $text-neutral;
        }
        .GridTile-DataItem-Row-Value    {
            font-family: $main-font;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            /* identical to box height, or 143% */
            
            /* Text/Text-Default */
            color: $text-neutral;
        }
    }
}


:not(.GridTile-FullWidth)   {
    @media screen and (max-width: 5000px) {
        .GridTile-WrappingTiles > .GridTile  {
            flex:0 1 calc(12.5% - 16px);
        }
    }

    @media screen and (max-width: 1600px) {
        .GridTile-WrappingTiles > .GridTile  {
            flex:0 1 calc(16.66% - 16px);
        }
    }

    @media screen and (max-width: 1400px) {
        .GridTile-WrappingTiles > .GridTile  {
            flex:0 1 calc(20% - 16px);
        }
    }
    @media screen and (max-width: 1200px) {
        .GridTile-WrappingTiles > .GridTile  {
            flex:0 1 calc(25% - 16px);
        }
    }
    @media screen and (max-width: 750px) {
        .GridTile-WrappingTiles > .GridTile  {
            flex:0 1 calc(33% - 16px);
        }
    }
    @media screen and (max-width: 600px) {
        .GridTile-WrappingTiles > .GridTile  {
            flex:0 1 calc(50% - 16px);
        }
    }
    @media screen and (max-width: 480px) {
        .GridTile-WrappingTiles > .GridTile  {
            flex:0 1 calc(100% - 16px);
        }
    }
}