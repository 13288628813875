#BusinessPage  {
    display:flex;
    flex-direction: column;
    align-content: flex-start;
    flex-grow: 1;
    overflow-y:hidden;
}

#BusinessPage_TabContent  {
    display:flex;
    flex-direction: column;
    align-content: flex-start;
    flex-grow: 1;
    overflow-y:hidden;
}