@import '../../../globals.scss';



#RecipeNutrientsPage  {
    display: flex;
    padding: 6px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch; 
    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #F7F8FB; 

    overflow-y:auto;
}


.RecipeNutrientsPage-TimelineItemSelectionBar   {
    display: flex;
    padding: 16px 0px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch; 

    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #FFF;
}

.RecipeNutrientsPage-NutrientParts  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}



.RecipeNutrientsPage-NutrientPart  {
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch; 

    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #FFF;

    :not(.RecipeNutrientsPage-NutrientPartEditing)  {
        .RecipeNutrientsPage-NutrientPart-SubpartsList  {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 10px;
            align-self: stretch;
        }

        .RecipeNutrientsPage-NutrientSubPart    {
            display: flex;
            align-items: center;
            gap: 10px; 
        }

    }
    &.RecipeNutrientsPage-NutrientPartEditing    {
        .RecipeNutrientsPage-NutrientPart-SubpartsList  {
            display: flex;
            padding-left: 30px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 16px;
            align-self: stretch;
        }

        

        .RecipeNutrientsPage-NutrientSubPart    {
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch; 
        }

        
        .RecipeNutrientsPage-NutrientSubPart-Content    {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 10px; 
            flex:1;
        }

        .RecipeNutrientsPage-NutrientSubPart-Content-Controls    {
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch; 
        }

        .RecipeNutrientsPage-NutrientSubPart-Content-Inputs    {            
            display: flex;
            align-items: center;
            gap: 10px; 

        }
    }
}

.RecipeNutrientsPage-NutrientPart-Header {
    display: flex;
    align-items: center;
    gap: 10px; 
}




.RecipeNutrientsPage-NutrientPart-Configure {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch; 
}

