@import '../globals.scss';

.WalkthroughCarousel-Container  {
    display: flex;
    align-items: stretch;
    gap: 10px;
    align-self: stretch; 
    touch-action: pan-x;
}

.WalkthroughCarousel  {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    position:relative;
    overflow:hidden;

    transition:height 500ms ease;
}

.WalkthroughCarousel-NavigationButton   {
    display:flex;
    justify-content: center;
}

.WalkthroughCarousel-Slide-Container    {
    position:absolute;
    top:0;
    bottom:0;
    display:flex;
    flex-direction: column;
    align-content:stretch;
    justify-content: stretch;
    transition: left 500ms ease, opacity 500ms ease;
}
.WalkthroughCarousel-Slide    {
    display:flex;
    flex:1;
    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #F8F8F8; 
}

.WalkthroughCarousel-Slide-Content    {
    display:flex;
    align-self:stretch;
    flex:1;
    align-items:center;
    justify-content: center;
}




.WalkthroughCarousel-Slide-Description    {
    display:flex;
    align-content: center;
    justify-content: center;
    text-align: center;
}
