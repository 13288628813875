@import '../../../globals.scss';



#RecipeRelationshipsPage  {
    display: flex;
    padding: 6px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch; 
    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #F7F8FB; 

    overflow-y:auto;
}


.RecipeRelationshipsPage-TimelineItemSelectionBar   {
    display: flex;
    padding: 16px 0px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch; 

    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #FFF;
}



.RecipeRelationshipsPage-RelationshipsList   {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch; 
}

.RecipeRelationshipsPage-Relationship-Container   {
    display: flex;
    @media (min-width: 767px) {
        padding: 16px 40px;
    }
    @media (max-width: 767px) {
        padding: 16px 16px;
    }
    
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch; 

    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #FFF;
}

.RecipeRelationshipsPage-Relationship   {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch; 

    @media (min-width: 767px) {
        flex-direction: row;
    }
    @media (max-width: 767px) {
        flex-direction: column;
        gap:20px;
    }

    &:not(.RecipeRelationshipsPage-EditingRelationship)   {



        .RecipeRelationshipsPage-Relationship-MasterSlaveOptions {
            display: flex;
            align-items: flex-start;
            gap: 20px;
            align-self: stretch; 
            justify-content: space-between;
        }


        .RecipeRelationshipsPage-Relationship-Inputs    {
            display: flex;
            
            @media (min-width: 767px) {
                align-items: center;
                gap: 50px;
            }

            @media (max-width: 767px) {
                justify-content: center;
                align-items: center;
                gap: 12px;
                align-self: stretch; 
            }   
           
        }

        .RecipeRelationshipsPage-Relationship-Input-Range   {
            display: flex;
            @media (min-width: 767px) {
                flex-direction: column;
                align-items: stretch;
                gap: 10px; 
                >div {
                    display: flex;
                    padding: 8px 6px;
                    justify-content: space-between;
                    align-items: center; 
                    gap: 10px; 

                    /*width: 165px;*/

                    border-radius: 4px;
                    border: 1px solid var(--Section-Border, #DDE3EB);
                }
            }

            @media (max-width: 767px) {
                flex:1;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 5px;
                align-self: stretch; 
            }
        }

        .RecipeRelationshipsPage-Relationship-Input-Range-Label   {
        
        }
        .RecipeRelationshipsPage-Relationship-Input-Range-Values   {
            display: flex;
            padding: 8px 6px;
            align-items: flex-start;
            gap: 4px; 
            align-self: stretch; 
            
            border-radius: 4px;
            border: 1px solid var(--Section-Border, #DDE3EB);
        }
    }
    &.RecipeRelationshipsPage-EditingRelationship   {
        .RecipeRelationshipsPage-Relationship-MasterOption {
            display: flex;
            align-items: center;
            gap: 10px;
            flex:1;
        }

        .RecipeRelationshipsPage-Relationship-Inputs    {
            display: flex;
            @media (min-width: 767px) {
                align-items: center;
                gap: 20px; 
            }

            @media (max-width: 767px) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 20px;
                align-self: stretch;
            }   
        }

        .RecipeRelationshipsPage-Relationship-Input-Range   {
            display: flex;
            @media (min-width: 767px) {
                flex-direction: column;
                align-items: flex-start;
                gap: 10px; 
                >div {
                    display: flex;
                    align-items: center; 
                    gap:10px;
                }
            }

            @media (max-width: 767px) {
                flex-direction: column;
                align-items: flex-start;
                gap: 6px; 

                >div {
                    display: flex;
                    align-items: center; 
                    gap:10px;
                }
            }   
        }

        .RecipeRelationshipsPage-Relationship-Slave    {
            display: flex;
            justify-content: flex-end;
            flex:1;
        }
    }
}


.RecipeRelationshipsPage-Relationship-MasterOptions {

}