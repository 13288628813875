@import '../../../globals.scss';



.TopUpReservoirWalkthrough-OrigialVolumeDisplay {
    display:flex;
    align-self: flex-start;
    text-align:left;
    color: #191C22;
    font-family: $main-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity:0.4;
} 