@import '../globals.scss';


.Button {
    display: flex;
    flex-shrink:1;
    &:not(.Button-Disabled) {
        > div {
            cursor:pointer;
        }
    }

    > div   {
        display:flex;
        align-self:stretch;
        align-items: center;
        flex:1;
        padding: 0px;
        border-radius: 4px;
        overflow:hidden;

        justify-content: center;
        align-items: center;
        gap: 10px; 


        > div    {
            display: flex;
            align-content: center;
            justify-content: center;
            align-items: center;
            gap: 7px;
            width:100%;

            font-family: $main-font;
            font-style: normal;
            font-weight: 400;
            
        
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

        }
    }

    





    &.Button-Large  {
        > div {
            padding: 16px 18px;
            > div {
                font-size: 18px;
                line-height: normal; 
                /* identical to box height, or 143% */
            }
        }
    }
    &.Button-Medium  {
        > div {
            padding: 8px 18px;
            > div {
                font-size: 14px;
                line-height: normal; 
                /* identical to box height, or 143% */
            }
        }
    }
    &.Button-Small   {
        > div {
            padding: 5px 12px;
            > div {
                font-size: 14px;
                line-height: 20px;
                /* identical to box height, or 143% */
            }
        }
    }

    &.Button-Medium-Fill    {
        display:flex;
        flex:1;
        align-self:stretch;
        > div {
            flex:1;
            align-self:stretch;
            padding: 8px 18px;
            align-content:center;
            align-items:center;
            > div {
                font-size: 14px;
                line-height: normal; 
                /* identical to box height, or 143% */
            }
        }
    }

    &.Button-Large-IconAction   {
        > div {
            
            display: flex;
            padding: 10px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            align-self: stretch; 
            flex:1;            

            .Button-Large-Icon  {
                display:flex;
                flex:1;
                gap:10px;
                flex: 1;
                align-self: stretch;
                justify-content: center;
            }
            .Button-Large-Icon-Subtitle {
                color: var(--main-blue, #2E72D2);
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: normal; 
            }

            > div {
                display:flex;
                font-size: 14px;
                line-height: normal; 
                align-self: stretch;
                flex:1;
                flex-direction: column;
                gap:10px;
                /* identical to box height, or 143% */
            }
        }


        @media (min-width: 767px) {
            padding: 20px 40px;
        }
        @media (max-width: 767px) {
            padding: 10px 10px;
        }


    }




    &.Button-Small-IconAction   {
        display: flex;
        align-items: center;
        gap: 10px;
        
        border-radius: 4px;
        border: 1px solid var(--section-border, #DDE3EB);
        
        &.Button-Small-IconAction-Active    {
            border: 1px solid #2E72D2;
        }

        > div   {
            padding: 7px 8px;

            /* Accent/Neutral */
            background: $true-white;

            > div  {
                /* Text/Text-Default */
                color: $text-neutral;
            }
        }

    }

    
    &.Button-Flex100 {
        flex:1;
        align-self:stretch;
        align-content:stretch;
        align-items:stretch;
        > div   {
            flex:1;
        }
    }
    &.Button-Flex50 {
        flex:0.5;
        align-content:stretch;
        align-items:stretch;
        > div   {
            flex:1;
        }
    }

    &.Button-Flex33 {
        flex:0.33;
        align-content:stretch;
        align-items:stretch;
        > div   {
            flex:1;
        }
    }
    
    &.Button-Flex25 {
        flex:0.25;
        align-content:stretch;
        align-items:stretch;
        > div   {
            flex:1;
        }
    }
    
    &.Button-Flex20 {
        flex:0.20;
        align-content:stretch;
        align-items:stretch;
        > div   {
            flex:1;
        }
    }

    &.Button-Status {
        > div   {
            padding: 5px 9px; 
            > div {
                font-size: 14px;
                line-height: normal;
                /* identical to box height, or 143% */
            }
        }
    }


    &.Button-Primary  {
        > div   {
            /* Accent/Neutral */
            background: $button-neutral;
            color: $true-white;
        }

        &.Button-Disabled   {
            > div   {
                /* Accent/Neutral */
                background: $button-neutral-disabled;
                opacity:0.4;
    
                > div  {
                    /* Text/Text-Default */
                    color: $true-white;
                }
            }
        }
    }
    &.Button-Primary-Inverted   {
        > div {
            background: transparent;
            border: 1px solid $main-blue;
            > div {
                color: var(--main-blue, #2E72D2);
                font-family: $main-font;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal; 
            }
        }
    }
    &.Button-Primary-Control-Toggle {
        padding:1px;
        > div {
            background: transparent;
            border: 1px solid $main-blue;
            > div {
                color: var(--main-blue, #2E72D2);
                font-family: $main-font;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal; 
            }
        }
        &.Button-Primary-Control-Toggle-Active {
            > div {
                border-radius: 4px;
                border: 1px solid $main-blue;
                background: var(--Lihgt-blue-filler, #F0F4FB);
                margin: -1px;
                > div {

                }

            }
        }
        &.Button-Primary-Control-Toggle-Inactive {
            > div {
                border-radius: 4px;
                border: 1px solid var(--Red, #EC3C3C);
                background: #FBEAEA;

                margin: -1px;
                > div {

                }
                
                svg {
                    
                }
            }
        }

        &.Button-Disabled   {
            > div > * {
                opacity:0.4;
            }
        }
    }
    &.Button-Primary-Toggle    {
        padding: 1px;
        > div   {
            display: flex;
            padding: 10px 10px 10px 10px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            align-self: stretch;
            border-radius: 4px;
            border: 1px solid var(--section-border, #DDE3EB);
            background: #FFF; 

            > div {
                display: flex;
                align-items: center;
                gap: 4px; 
                color: #191C22;
                font-family: $main-font;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal; 
            }
        }
        &.Button-Primary-Toggle-Active {
            > div {
                border-radius: 4px;
                border: 2px solid $interactive;
                margin: -1px;
                background: $main-blue-inverted;
                > div {

                }
            }
        }

        &.Button-Disabled   {
            > div > * {
                opacity:0.4;
            }
        }
    }
    &.Button-Hyperlink  {
        >div    {
            padding:0;
            >div    {
                color: var(--main-blue, #2E72D2);
                font-family: $main-font;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;                 
            }
        }
    }
    &.Button-Neutral  {
        border-radius: 4px;
        border: 1px solid #DDE3EB;
        background: #FFF; 

        > div   {
            /* Accent/Neutral */
            background: $true-white;

            > div  {
                /* Text/Text-Default */
                color: $text-neutral;
            }
        }

        &.Button-Disabled   {
            > div   {
                opacity: 0.5;
    
                > div  {
                    /* Text/Text-Default */
                    color: $text-neutral-subdued;
                }
            }
        }
    }
    &.Button-Interactive-Neutral    {
        border-radius: 4px;
        border: 2px solid var(--main-blue, #2E72D2);
        background: var(--lihgt-blue-filler, #E4EAF5);

        color: var(--main-blue, #2E72D2); 
    }

    &.Button-Interactive-Button-Neutral {
        border-radius: 4px;
        border: 1px solid var(--main-blue, #2E72D2);
        background: #F6F9FE; 
        
        color: var(--main-blue, #2E72D2); 
    }
    

    &.Button-Critical  {
        border-radius: 4px;
        border: 1px solid var(--section-border, #DDE3EB);
        background: #F6F9FE; 

        > div   {
            /* Accent/Neutral */
            background: $true-white;
            > div, > svg  {
                /* Text/Text-Default */
                color: $text-critical;
            }
        }
    }

    &.Button-Focused    {
        border-radius: 4px;
        border: 1px solid var(--main-blue, #2E72D2);

        > div {
            background: var(--lihgt-blue-filler, #E4EAF5);

            > div, > svg    {
                /* Text/Text-Default */
                color: $text-neutral;
            }
        }
    }
    
    
    
    &.Button-WithBorder {
        border:1px solid $neutral-300;
        border-radius: 4px;
    }


    &.Button-Disabled   {
        color:$text-neutral-subdued;
        svg {
            color:$text-neutral-subdued;
        }
    }


    &.Button-CenterContent    {
        >div {
            align-content: center;
            align-items: center;
            justify-content: center;
        }
    }

    &.Button-LeftContent    {
        >div {
            align-content: flex-start;
            align-items:flex-start;
        }
    }
    

    &.Button-Action-Neutral {
        border-radius: 4px;
        border: 1px solid var(--Section-Border, #DDE3EB);
        background: #F7F8FB;

        align-self: stretch;

        &.Button-Action-Neutral-Active {
            border: 1px solid var(--Main-Blue, #1D65F0);
            background: var(--Lihgt-blue-filler, #F0F4FB);
        }
    }
}