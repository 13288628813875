

$main-font: "DM Sans";
$header-font: "Poppins";

$neutral-50: #F0F1F2;
$neutral-100: #E7EBF0;
$neutral-200: #DBE0EA;
$neutral-300: #CBD2DE;
$neutral-400: #B7BFCE;
$neutral-500: #9FA9BA;
$neutral-600: #858FA0;
$neutral-700: #6A7383;
$neutral-800: #4D5563;
$neutral-900: #323742;
$neutral-1000: #191C22;

$standard-border: #DDE3EB;

$true-white: #FFFFFF;
$off-white: #fafafa;
$white: $neutral-300;
$black: $neutral-1000;
$main-blue: #1D65F0;
$main-blue-inverted: #F0F4FB;
$main-red: rgb(236, 60, 60);

$interactive: $main-blue;
$critical: #D82C0D;
$warning: #916A00;
$success: #008060;

$interactive-inactive: #6D9CDE;


$surface-neutral: $neutral-50;
$surface-neutral-subdued: $neutral-100;
$surface-warning: #FFD79D;
$surface-warning-subdued: #E8DECF;
$surface-critical: #FEA29D;
$surface-critical-subdued: #E8D0CF;
$surface-success: #CEF09D;
$surface-success-subdued: #CEE8DE;
$surface-highlight: #9EF3FF;
$surface-highlight-subdued: #CFE5E8;

$accent-neutral: $neutral-100;
$accent-warning: #E6B878;
$accent-critical: #E57C77;
$accent-success: #77E5B8;
$accent-highlight: #77D7E5;

$text-neutral: $neutral-1000;
$text-neutral-subdued: $neutral-800;
$text-warning: $warning; 
$text-critical: $critical;
$text-success: $success; 
$text-highlight: #347C84;
$text-hint: $neutral-500;

$button-neutral: $interactive;
$button-neutral-disabled: $interactive-inactive;
$button-neutral-hovered: #1F5199;
$button-critical: $critical;
$button-critical-hovered: $critical;

$online: #77E5B8;
$offline: #E57C77;

:export {
    interactive: $interactive;
    accentNeutral: $accent-neutral;
    accentWarning: $accent-warning;
    accentHighlight: $accent-highlight;
    accentWarning: $accent-warning;
    accentSuccess: $accent-success;

    surfaceSuccess: $surface-success;

    neutral50: $neutral-50;
    neutral100: $neutral-100;
    neutral200: $neutral-200;
    neutral300: $neutral-300;
    neutral400: $neutral-400;
    neutral500: $neutral-500;
    neutral600: $neutral-600;
    neutral700: $neutral-700;
    neutral800: $neutral-800;
    neutral900: $neutral-900;
    neutral1000: $neutral-1000;

    trueWhite: $true-white;

    textCritical: $text-critical;

    online: $online;
    offline: $offline;
} 

.t_DisplayHuge    {
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 36px;
    /* identical to box height, or 129% */

    /* Text/Text-Default */
    color: $text-neutral;
}

.t_DisplayLarge   {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
    /* identical to box height, or 117% */
    
    /* Text/Text-Default */
    color: $text-neutral;
}

.t_DisplayMedium  {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */
    
    /* Text/Text-Default */
    color: $text-neutral;
}

.t_Content    {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    /* identical to box height, or 175% */
    
    /* Text/Text-Default */
    color: $text-neutral;
}

.t_Heading    {
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    
    /* Text/Text-Default */
    color: $text-neutral;
}

.t_SubHeading {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */
    
    text-transform: uppercase;
    
    /* Text/Text-Default */
    color: $text-neutral;
}

.t_Button {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */
    
    /* Text/Text-Default */
    color: $text-neutral;
}

.t_Body   {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    /* Text/Text-Default */
    color: $text-neutral;
}

.t_Caption    {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    
    /* Text/Text-Default */
    color: $text-neutral;
}

.t_Caption-Medium    {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    
    /* Text/Text-Default */
    color: $text-neutral;
}

.t_Helper   {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */
    
    /* Text/Text-Default */
    color: $text-neutral;
}

.t_Helper-Medium   {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */
    
    /* Text/Text-Default */
    color: $text-neutral;
}





.ControlBar_Horizontal  {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding: 0px;
    gap: 8px;
    
    .ControlBar_Horizontal-Left, .ControlBar_Horizontal-Right  {
        /* Auto layout */
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        padding: 0px;
        gap: 8px;
        margin:6px 0;
        align-items:center;
        align-content: center;

    }
    .ControlBar_Horizontal-Left {
        order:0;
    }
    .ControlBar_Horizontal-Right    {
        order:1;
        flex-shrink: 0;
    }
    .ControlBar_Horizontal-Overflow  {
        flex:1;
        overflow-x:auto;
        scrollbar-width: thin;
    }

}






.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */


                                    

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-text-size-adjust: 100%;
}


.Text-SoftTruncate  {
  overflow-x:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Text-Wrap  {
  white-space:normal;
  overflow-x:hidden;
}



.Text-H50, .Text-H24, .Text-H20, .Text-H18, .Text-H16, .Text-H14, .Text-H12, .Text-H10 {
  color: #191C22;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: normal; 
}


.Text-SemiBold-H50, .Text-SemiBold-H24, .Text-SemiBold-H20, .Text-SemiBold-H18, .Text-SemiBold-H16, .Text-SemiBold-H14, .Text-SemiBold-H12, .Text-Semibold-H10 {
  color: #191C22;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal; 
}




.Text-Light-H50, .Text-Light-H24, .Text-Light-H20, .Text-Light-H18, .Text-Light-H16, .Text-Light-H14, .Text-Light-H12, .Text-Light-H10 {
  color: #191C22;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  line-height: normal; 
}

.Text-S50, .Text-S24, .Text-S20, .Text-S18, .Text-S16, .Text-S14, .Text-S12, .Text-S10 {
  color: #191C22;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: DM Sans;
  font-style: normal;
  font-weight: 400;
  line-height: normal; 
}


.Text-SemiBold-S50, .Text-SemiBold-S24, .Text-SemiBold-S20, .Text-SemiBold-S18, .Text-SemiBold-S16, .Text-SemiBold-S14, .Text-SemiBold-S12, .Text-SemiBold-S10 {
  color: #191C22;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: DM Sans;
  font-style: normal;
  font-weight: 600;
  line-height: normal; 
}

.Text-Medium-S50, .Text-Medium-S24, .Text-Medium-S20, .Text-Medium-S18, .Text-Medium-S16, .Text-Medium-S14, .Text-Medium-S12, .Text-Medium-S10 {
  color: #191C22;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  line-height: normal; 
}

.Text-Alt50, .Text-Alt24, .Text-Alt20, .Text-Alt18, .Text-Alt16, .Text-Alt14, .Text-Alt12, .Text-Alt10 {
  color: #4F5563;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: DM Sans;
  font-style: normal;
  font-weight: 400;
  line-height: normal; 
}

.Text-AltI50, .Text-AltI24, .Text-AltI20, .Text-AltI18, .Text-AltI16, .Text-AltI14, .Text-AltI12, .Text-AltI10 {
  color: #4F5563;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: DM Sans;
  font-style: italic;
  font-weight: 400;
  line-height: normal; 
}

.Text-Aux50, .Text-Aux24, .Text-Aux20, .Text-Aux18, .Text-Aux16, .Text-Aux14, .Text-Aux12, .Text-Aux10 {
  color: #9CA6B4;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: DM Sans;
  font-style: normal;
  font-weight: 400;
  line-height: normal; 
}


.Text-Hyperlink50, .Text-Hyperlink24, .Text-Hyperlink20, .Text-Hyperlink18, .Text-Hyperlink16, .Text-Hyperlink14, .Text-Hyperlink12, .Text-Hyperlink10 {
  color: var(--Main-Blue, #1D65F0);
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


.Text-H50, .Text-SemiBold-H50, .Text-Light-H50, .Text-S50, .Text-Medium-S50, .Text-Medium-S50, .Text-Alt50, .Text-AltI50, .Text-Aux50, .Text-Hyperlink50  {
  font-size: 50px;
} 
.Text-H24, .Text-SemiBold-H24, .Text-Light-H24, .Text-S24, .Text-Medium-S24, .Text-Medium-S24, .Text-Alt24, .Text-AltI24, .Text-Aux24, .Text-Hyperlink24  {
  font-size: 24px;
} 
.Text-H20, .Text-SemiBold-H20, .Text-Light-H20, .Text-S20, .Text-Medium-S20, .Text-Medium-S20, .Text-Alt20, .Text-AltI20, .Text-Aux20, .Text-Hyperlink20  {
  font-size: 20px;
} 
.Text-H18, .Text-SemiBold-H18, .Text-Light-H18, .Text-S18, .Text-Medium-S18, .Text-Medium-S18, .Text-Alt18, .Text-AltI18, .Text-Aux18, .Text-Hyperlink18  {
  font-size: 18px;
} 
.Text-H16, .Text-SemiBold-H16, .Text-Light-H16, .Text-S16, .Text-Medium-S16, .Text-Medium-S16, .Text-Alt16, .Text-AltI16, .Text-Aux16, .Text-Hyperlink16  {
  font-size: 16px;
} 
.Text-H14, .Text-SemiBold-H14, .Text-Light-H14, .Text-S14, .Text-Medium-S14, .Text-Medium-S14, .Text-Alt14, .Text-AltI14, .Text-Aux14, .Text-Hyperlink14  {
  font-size: 14px;
} 
.Text-H12, .Text-SemiBold-H12, .Text-Light-H12, .Text-S12, .Text-Medium-S12, .Text-Medium-S12, .Text-Alt12, .Text-AltI12, .Text-Aux12, .Text-Hyperlink12  {
  font-size: 12px;
} 
.Text-H10, .Text-SemiBold-H10, .Text-Light-H10, .Text-S10, .Text-Medium-S10, .Text-Medium-S10, .Text-Alt10, .Text-AltI10, .Text-Aux10, .Text-Hyperlink10  {
  font-size: 10px;
} 

.Text-CPrimary  {
  color: $main-blue;
}

.Text-HeaderMedium  {
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal; 
} 
.Text-SubHeader  {
  color:#191C22;
  font-family: $main-font;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.Text-SubHeader-Medium  {
  color:#191C22;
  font-family: $main-font;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Text-AltSubHeader  {
  color:#4F5562;
  font-family: $main-font;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-style:italic;
}


.Text-Subtext  {
  color: var(--Text, #191C22);
  font-family: $main-font;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal; 
}

.Text-PrimarySubtext  {
  color: $main-blue;
  font-family: $main-font;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal; 
}
.Text-AltSubtext  {
  color:#4F5562;
  font-family: $main-font;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Text-AuxSubtext {
  color: var(--Dark-Section-Border, #9CA6B4);
  font-family: $main-font;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal; 
}
.Text-AuxSubtext-Small {
  color: var(--Dark-Section-Border, #9CA6B4);
  font-family: $main-font;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal; 
}

.Text-FormHeader  {
  color: var(--Text, #191C22);
  font-family: $main-font;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.Text-FormHeader-Medium  {
  color: var(--Text, #191C22);
  font-family: $main-font;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Text-FormLabel  {
  color: var(--Text, #191C22);
  font-family: $main-font;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal; 
}
.Text-FormLabel-Small {
  color: var(--Text, #191C22);
  font-family: $main-font;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal; 
}
.Text-FormAltLabel  {
  color: var(--Text, #4D5563);
  font-family: $main-font;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal; 
}
.Text-FormAltLabel-Small {
  color: var(--Text, #4D5563);
  font-family: $main-font;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal; 
}
.Text-FormValue  {
  color: var(--Text, #191C22);
  font-family: $main-font;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal; 
}
.Text-Hyperlink {
  color: $main-blue;
  font-family: $main-font;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal; 
  cursor:pointer;
}
.Text-Inline  {
  display:inline;
}


.Text-Color-Critical  {
  color: #EC3C3C !important;
}

.Linebreak-10 {
  padding-bottom:9px;
  border-bottom:1px solid #DDE3EB
}

.Linebreak-10-Dual {
  padding:9px 0;
  border-top:1px solid #DDE3EB;
  border-bottom:1px solid #DDE3EB;
}
.Linebreak-R-10 {
  padding-right:9px;
  border-right:1px solid #DDE3EB;
}

.Linebreak-L-10 {
  padding-left:9px;
  border-left:1px solid #DDE3EB;
}

.Linebreak-Top-10 {
  padding-top:9px;
  border-top:1px solid #DDE3EB;
}

.Linebreak-20 {
  padding-bottom:19px;
  border-bottom:1px solid #DDE3EB;
}

.Linebreak-Top-20 {
  padding-top:19px;
  border-top:1px solid #DDE3EB;
}


.Linebreak-20-Dual {
  padding:19px 0;
  border-top:1px solid #DDE3EB;
  border-bottom:1px solid #DDE3EB;
}
.Linebreak-R-20 {
  padding-right:19px;
  border-right:1px solid #DDE3EB;    
}

.Linebreak-L-20 {
  padding-left:19px;
  border-left:1px solid #DDE3EB;    
}

.Linebreak-30 {
  padding-bottom:29px;
  border-bottom:1px solid #DDE3EB;
}

.Linebreak-Top-30 {
  padding-top:29px;
  border-top:1px solid #DDE3EB;
}


.Linebreak-30-Dual {
  padding:29px 0;
  border-top:1px solid #DDE3EB;
  border-bottom:1px solid #DDE3EB;
}
.Linebreak-R-30 {
  padding-right:29px;
  border-right:1px solid #DDE3EB;    
}

.Linebreak-L-30 {
  padding-left:29px;
  border-left:1px solid #DDE3EB;    
}


.LineBreak-Horizontal  {
  width:100%;
  height:1px;
  background: #DDE3EB;
}

.LineBreak-Vertical  {
  width:1px;
  align-self: stretch;
  background: #DDE3EB;
}

.FlexLineBreak-Vertical  {
  width:1px;
  align-self: stretch;
  background: #DDE3EB;
}

.Grid-Space10 {
  display:grid;
  grid-gap: 10px;
}
.Grid-Space20 {
  display:grid;
  grid-gap: 20px;
}
.Grid-Space30 {
  display:grid;
  grid-gap: 30px;
}
.Grid-Space40 {
  display:grid;
  grid-gap: 40px;
}
.Grid-Space50 {
  display:grid;
  grid-gap: 50px;
}
.Grid2 {
  display: grid;
  grid-template-columns: repeat(2,1fr);
}
.Grid3 {
  display: grid;
  grid-template-columns: repeat(3,1fr);
}
.Grid4 {
  display: grid;
  grid-template-columns: repeat(4,1fr);
}


.FlexContent  {
  display:flex;
  flex-direction:column;
}

.FlexContent-5   {
  display:flex;
  flex-direction:column;
  gap:5px;
}
.FlexContent-10   {
  display:flex;
  flex-direction:column;
  gap:10px;
}
.FlexContent-12   {
  display:flex;
  flex-direction:column;
  gap:12px;
}
.FlexContent-15   {
  display:flex;
  flex-direction:column;
  gap:15px;
}
.FlexContent-20   {
  display:flex;
  flex-direction:column;
  gap:20px;
}

.FlexContent-30   {
  display:flex;
  flex-direction:column;
  gap:30px;
}
.FlexContent-40   {
  display:flex;
  flex-direction:column;
  gap:40px;
}
.FlexContent-50   {
  display:flex;
  flex-direction:column;
  gap:50px;
}
.FlexContent-60   {
  display:flex;
  flex-direction:column;
  gap:60px;
}
.FlexContent-100   {
  display:flex;
  flex-direction:column;
  gap:100px;
}



.FlexContent-H  {
  display:flex;
  flex-direction: row;
}

.FlexContent-H-5   {
  display:flex;
  flex-direction:row;
  gap:5px;
}
.FlexContent-H-10   {
  display:flex;
  flex-direction:row;
  gap:10px;
}
.FlexContent-H-15   {
  display:flex;
  flex-direction:row;
  gap:15px;
}
.FlexContent-H-20   {
  display:flex;
  flex-direction:row;
  gap:20px;
}

.FlexContent-H-30   {
  display:flex;
  flex-direction:row;
  gap:30px;
}
.FlexContent-H-40   {
  display:flex;
  flex-direction:row;
  gap:40px;
}
.FlexContent-H-50   {
  display:flex;
  flex-direction:row;
  gap:50px;
}
.FlexContent-H-60   {
  display:flex;
  flex-direction:row;
  gap:60px;
}
.FlexContent-H-70   {
  display:flex;
  flex-direction:row;
  gap:70px;
}
.FlexContent-H-80   {
  display:flex;
  flex-direction:row;
  gap:80px;
}



.FlexContent-Center   {
  align-content:center;
  align-self:center;
  align-items:center;
  /*justify-content: center;*/
  margin-top:auto; margin-bottom: auto;
}

.FlexContent-Left   {
  align-content:flex-start;
  align-self:flex-start;
  align-items:flex-start;
  justify-content:flex-start;
}


.FlexContent-H-Space  {
  display:flex;
  flex-direction: row;
  justify-content: space-between;
}


.FlexContent-HLeft   {
  align-content:flex-start;
  align-items:flex-start;
}
.FlexContent-HFill   {
  align-self:stretch;
}
.FlexContent-HFlex   {
  align-self:stretch;
  flex:1;
}

.FlexContent-HCenter   {
  align-content:center;
  align-self:center;
  align-items:center;
  /*justify-content: center;*/
}
.FlexContent-VCenter  {
  justify-content: center;  
}




.FlexContent-OverflowYHidden  {
  overflow-y:hidden;
}


.FlexContent-OverflowY   {
  max-height:100%!important;
  overflow-y:scroll;
  /*justify-content: flex-start !important;*/
}



.OverflowYHidden  {
  display:block!important;
  overflow-y:hidden;
}







.FlexWrap {
  display:flex;
  flex-direction: row;
  flex-wrap:wrap;
  overflow: hidden;

  &.FlexWrap-5  {
    gap:5px;
  }
  &.FlexWrap-Size1  {
    gap:10px;
  }
  &.FlexWrap-Size2  {
    gap:10px;
  }
}



@media screen and (max-width: 5000px) {
  .FlexWrap-Size1 > .FlexWrap-Item  {
      flex:0 1 calc(12.5% - (10px * 0.875));
  }
  .FlexWrap-Size2 > .FlexWrap-Item  {
      flex:0 1 calc(16.66% - (10px * 0.8333));
  }
}

@media screen and (max-width: 1600px) {
  .FlexWrap-Size1 > .FlexWrap-Item  {
      flex:0 1 calc(16.66% - (10px * 0.833));
  }
  .FlexWrap-Size2 > .FlexWrap-Item  {
      flex:0 1 calc(20% - (10px * 0.8));
  }
}

@media screen and (max-width: 1200px) {
  .FlexWrap-Size1 > .FlexWrap-Item  {
      flex:0 1 calc(20% - (10px * 0.8));
  }
  .FlexWrap-Size2 > .FlexWrap-Item  {
      flex:0 1 calc(25% - (10px * 0.75));
  }
}
@media screen and (max-width: 950px) {
  .FlexWrap-Size1 > .FlexWrap-Item  {
      flex:0 1 calc(25% - (10px * 0.75));
  }
  .FlexWrap-Size2 > .FlexWrap-Item  {
      flex:0 1 calc(33.33% - (10px * 0.6666));
  }
}
@media screen and (max-width: 750px) {
  .FlexWrap-Size1 > .FlexWrap-Item  {
      flex:0 1 calc(33.33% - (10px * 0.6666));
  }
  .FlexWrap-Size2 > .FlexWrap-Item  {
      flex:0 1 calc(50% - (10px * 0.5));
  }
}
@media screen and (max-width: 600px) {
  .FlexWrap-Size1 > .FlexWrap-Item  {
      flex:0 1 calc(50% - (10px * 0.5));
  }
  .FlexWrap-Size2 > .FlexWrap-Item  {
      flex:0 1 calc(100%);
  }
}
@media screen and (max-width: 480px) {
  .FlexWrap-Size1 > .FlexWrap-Item  {
      flex:0 1 calc(100%);
  }
  .FlexWrap-Size2 > .FlexWrap-Item  {
      flex:0 1 calc(100%);
  }
}












  .FormContent-FormItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch; 
    gap: 6px; 
  }

  .FormContent-FormItem-Label {
    color: #191C22;
    /* Nav Button Med */
    font-family: $main-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
  }

  .FormContent-FormItem-Value-Display {
    display: flex;
    padding: 10px;
    align-items: center;
    gap: 10px;
    align-self: stretch; 
    flex:1;

    border-radius: 4px;
    outline: 1px solid var(--Section-Border, #DDE3EB);
    background: rgba(255, 255, 255, 0.40);

    color: #191C22;
    font-family: $main-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
  }

  .FormContent-FormItem-Value-TextInput {

  }

  .FormContent-FormItem-Value-ButtonsInput  {
    display: flex;
    align-items: flex-start;
    gap: 11px;
    align-self: stretch; 
  }






  .lds-ring {
    /* change color here */
    color: #1c4c5b
  }
  .lds-ring-size10  {
    width:10px;
    height:10px;
    div   {
      border-width:1px;
    }
  }
  .lds-ring-size20  {
    width:20px;
    height:20px;
    div   {
      border-width:2px;
    }
  }
  .lds-ring-size40  {
    width:40px;
    height:40px;
    div   {
      border-width:4px;
    }
  }
  .lds-ring,
  .lds-ring div {
    box-sizing: border-box;
  }
  .lds-ring {
    display: inline-block;
    position: relative;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 80%;
    height: 80%;
    margin: 10%;
    border-style: solid;
    border-width:2px;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: currentColor transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
