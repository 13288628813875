@import '../globals.scss';


.Multibutton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end; 
}


.Multibutton-Content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch; 

    border-radius: 4px;
    border: 1px solid var(--Section-Border, #DDE3EB);
    background: #F7F8FB; 
}


.Multibutton-Button-Container   {
    display: flex;
    padding: 2px 3px;
    justify-content: center;
    align-items: center;
    gap: 10px;
}


.Multibutton-Button   {
    display: flex;
    padding: 5px 17px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0; 
    cursor:pointer;

    &.Multibutton-Button-Selected   {
        border-radius: 4px;
        border: 1px solid var(--Main-Blue, #2E72D2);
        background: var(--Lihgt-blue-filler, #E4EAF5);
        .Multibutton-Button-Content {
            color: var(--Main-Blue, #2E72D2);
        }
    }

    &.Multibutton-Button-Disabled  {
        cursor:default;
    }
}

.Multibutton-Button-Content {
    color: #191C22;
    font-family: $main-font;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
}