@import '../globals.scss';


.BasicAlert {
    /* Auto layout */
    display: flex;
    flex-shrink:1;

    > div   {
        display: flex;
        align-items: center;
        gap: 6px; 
        

        > span    {
        
            
        }
    }

    


    &.BasicAlert-Okay  {
        > div   {
            /* Accent/Neutral */
            background: transparent;

            > span {
                color: #2E72D2;
                font-family: $main-font;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal; 
            }
        }
    }
    
    
    &.BasicAlert-Notify  {
        > div   {
            /* Accent/Neutral */
            background: transparent;

            > span {
                color: #DABB1A;
                font-family: $main-font;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal; 
            }
        }
    }
}