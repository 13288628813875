@import '../globals.scss';

.SideNavigation {

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 50px; 
    background: #FFF;   
    z-index:1000;


    
    &.SideNavigation-Desktop    {
        border-right: 1px solid var(--Section-Border, #DDE3EB); 
        
    }

    &.SideNavigation-Mobile { 

        position:fixed;
        left:0;
        top:0;
        bottom:0;

        z-index:1000;
        overflow:hidden;

        .SideNavigation-Header-Brand    {
            flex:1;
            padding:20px;
        }
    }

    &.SideNavigation-Tablet { 

        position:fixed;
        left:0;
        top:0;
        bottom:0;
        border-right: 1px solid var(--Section-Border, #DDE3EB); 

        z-index:1000;
    }


    &.expanded  {

        
        &.SideNavigation-Desktop    {
            .SideNavigation-Header-Brand    {
                flex:1;
            }

            .SideNavigation-Link    {
                gap:13px;
            }
        }
        
        &.SideNavigation-Tablet    {
            .SideNavigation-Header-Brand    {
                flex:1;
            }
            .SideNavigation-Header-Toggle   { 
                padding:12px 20px;   
            }

            .SideNavigation-Links   {
                margin:2px 20px;
            }
            .SideNavigation-Link    {
                gap:13px;
            }
            
        }
        /*.SideNavigation-Link-Title    {
            flex:1;
        }*/
    }

    &.collapsed  {

        &.SideNavigation-Desktop    {
            .SideNavigation-Header-Brand    {
                flex:0;
            }
            
            .SideNavigation-Link    {
                gap:0px;
            }
            .SideNavigation-ProfileLink {
                gap:0px;
                padding:10px 2px;
            }
        }

        &.SideNavigation-Tablet    {
            .SideNavigation-Content {
                
            }
            .SideNavigation-Header-Brand    {
                flex:0;
            }
            .SideNavigation-Header-Toggle   { 
                padding:12px 6px;   
            }
            .SideNavigation-Links   {
                margin:2px 6px;
            }
            .SideNavigation-Link    {
                gap:0px;
            }
            .SideNavigation-ProfileLink {
                margin:15px 6px;
                gap:0px;
                padding:10px 2px;
            }
            
        }


        .SideNavigation-ProfileLink {
            &:not(.active)  {
                background: transparent;
            }
        }

        /*.SideNavigation-Link-Title    {
            flex:0;
        }*/
    }
 
    

}

.SideNavigation-BackgroundOverlay   {
    position:fixed;
    left:0;
    top:0;
    right:0;
    bottom:0;
    opacity:0;

    background: var(--text, #191C22);   
    z-index:999;

    transition:opacity 200ms ease;

    &.active    {
        opacity:0.5;
    }
    &:not(.active)  {
        pointer-events: none;  
    }
}

.SideNavigation-Container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1 1 auto; 
    overflow-y:hidden;
}

.SideNavigation-Content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 1 auto; 
    transition: padding 100ms ease;
    overflow-y:hidden;
}

.SideNavigation-Header  {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;

    .SideNavigation-Header-Brand    {
        display:flex;
        
        overflow: hidden;
        color: $main-blue;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: $main-font;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        >span   {

        }
    }

    .SideNavigation-Header-Toggle   {
        display:flex;
        align-self:flex;
        padding:12px 20px;
    }
}



.SideNavigation-LinksContainers   {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch; 
    overflow-y:auto;
}


.SideNavigation-Links   {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    align-self: stretch; 
    margin:2px 15px;
}


.SideNavigation-Link    {
    display: flex;
    padding: 2px 5px;
    align-items: center;
    gap: 5px;
    align-self: stretch; 

    cursor:pointer;
    border-radius: 4px; 
    outline: 1px solid transparent;

    &:hover {
        background: var(--background, #F0F1F2); 
    }

    &.active    {
        outline: 1px solid $main-blue;
        background: #f0f4fb;

        .SideNavigation-Link-Icon   {
            
            svg > * {
                fill: $main-blue;
            }
        }
    }

    .SideNavigation-Link-Icon   {
        display: flex;
        padding: 5px 5px;
        align-items: flex-start;
        gap: 10px; 

    }

    .SideNavigation-Link-Title  {
        display:flex;
        overflow: hidden;
        color: var(--text, #191C22);
        white-space: nowrap;
        font-family: $main-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 

        transition:flex 1s ease;
        
        >span   {
            text-overflow: ellipsis;
        }
    }
}





.SideNavigation-ProfileLink    {
    display: flex;
    padding: 10px;
    align-items: center;
    gap: 10px;
    align-self: stretch; 
    cursor:pointer;
    border-radius: 4px; 
    outline: 1px solid transparent;

    background: var(--background, #F0F1F2); 

    margin:15px 15px;
    overflow:hidden;
    align-self:flex-start;

    transition:background 200ms ease;


    &.active    {
        outline: 1px solid $main-blue;
        background: #f0f4fb;
    }

    .SideNavigation-ProfileLink-Icon   {
        display: flex;
        padding: 7.5px 5px;
        align-items: flex-start;
        gap: 10px; 
        overflow:hidden;
    }

    .SideNavigation-ProfileLink-TitleContainer  {
        display:flex;
        overflow: hidden;
        color: var(--text, #191C22);
        white-space: nowrap;
        font-family: $main-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 

        transition:flex 1s ease;
        overflow:hidden;
        
        .SideNavigation-ProfileLink-Title   {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            gap: 2px; 
            overflow:hidden;

            >span   {
                overflow: hidden;
                text-overflow: ellipsis;
            }

            >span:nth-child(1)  {
                color: var(--text, #191C22);
                font-family: $main-font;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal; 
            }
            >span:nth-child(2)  {
                color: #4F5562;
                font-family: $main-font;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal; 
            }
            
        }
    }
}





.ham {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: transform 400ms;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .hamRotate.active {
    transform: rotate(45deg);
  }
  .hamRotate180.active {
    transform: rotate(180deg);
  }

  .line {
    fill:none;
    transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
    stroke:#000;
    stroke-width:5.5;
    stroke-linecap:round;
  }
  .ham1 .top {
    stroke-dasharray: 40 139;
  }
  .ham1 .bottom {
    stroke-dasharray: 40 180;
  }
  .ham1.active .top {
    stroke-dashoffset: -98px;
  }
  .ham1.active .bottom {
    stroke-dashoffset: -138px;
  }