@import '../globals.scss';


.Walkthrough {
    
}


.Walkthrough-ScreenWrapper {
    position:fixed;
    left:0;
    right:0;
    bottom:0;
    top:0;
    z-index:1000;
    background-color: rgba(0, 0, 0, 0.5);
    transition: backdrop-filter 1s width 250ms ease, height 250ms ease;
    padding:20px;
    
    display:flex;
    align-content: stretch;
    justify-content: stretch;
}

.Walkthrough-Wrapper    {
    display:flex;
    align-items: center;
    /*justify-content: center;*/
    max-width: 100%;
    max-height: 100%;
    
    flex-grow:1;
}

.Walkthrough-Container   {

    display:flex;
    /*justify-content: center;*/
    max-width: 100%;
    max-height:100%;
    
    flex-grow:1;
}
  
  
.Walkthrough_FullScreen {
    display:flex;
    width:100%;
    height:100%;
    position:fixed;
    left:0;
    top:0;
    bottom:0;
    right:0;
    align-content:stretch;
    justify-content: stretch;
    background: var(--background, #F8F8F8); 
    z-index: 1000;

    .Walkthrough {
        flex:1;
        background:transparent;

        gap:20px;

        border:none;
        padding: 20px 40px; 
        
        border-radius: 0;
        border:none;
    }

    .Walkthrough-Header  {
        padding: 0px;
        margin-bottom:0;

    }

    &.Walkthrough_Mobile {
        .Walkthrough {
            padding:20px 20px;   
        }
    }

    &.Walkthrough_Tablet {

        .Walkthrough {
            padding:40px 10%;
            .Walkthrough-Header  {
            }
        }

        /*.Walkthrough-Card-FooterButtons {
            .Button {
                >div    {
                    padding:16px 18px;
                    > div   {
                        font-size:18px;
                    }
                }
            }
        }*/
    }
    
    &.Walkthrough_Desktop    {

        .Walkthrough {
            padding:30px 50px;
            .Walkthrough-Header  {
                
            }
        }


        /*.Walkthrough-Card-FooterButtons {
            .Button {
                >div    {
                    padding:16px 18px;
                    > div   {
                        font-size:18px;
                    }
                }
            }
        }*/
    }

    
    &.Walkthrough_WideDesktop    {

        .Walkthrough {
            padding:50px 20%;
            .Walkthrough-Header  {
                
            }
        }

    }

    &.Walkthrough_CenterContent  {
        .Walkthrough-StandardHeader  {
            align-items:center;
            align-content: center;
            justify-content: center;
            text-align: center
        }


        
    }

    .Walkthrough-Cards  {
        flex:1;
    }


    .Walkthrough-Content    {
        align-self:stretch;
    }

    .Walkthrough-Card-Wrapper   {
        height: 100%;
        width:100%;
        top:0;
        bottom:0;
    }
    .Walkthrough-Card   {
        @media (min-width: 767px) {
            padding: 40px 60px;
        }
        @media (max-width: 767px) {
            padding: 30px 20px 20px 20px;
        }
    }

    .Walkthrough-Card.Walkthrough-CardFill {
        border-radius: 10px;
        border: 1px solid var(--section-border, #DDE3EB);
        background: #FFF; 
    }

    .Walkthrough-Card-FooterButtons    {
        flex-direction: row;
        gap: 10px;        
    }
}

.Walkthrough-Content    {
    align-items:center;
    /*margin:10px 0px;*/
    flex:1;
    /*justify-content: center;*/
    >div    {
        
    }

    
}
.Walkthrough-ContentContainer:not(.Walkthrough-ContentContainer-Left)    {
    &:not(.Walkthrough-ContentContainer-Vertical)    {
        align-items:center;
        align-self:center;
    }
    &.Walkthrough-ContentContainer-Vertical   {
        align-items:stretch;
        align-self:stretch;
        align-content:center;
    }
}

.Walkthrough {
    display: flex;
    flex-direction: column;
    align-items: flex-start;


    padding: 20px 30px; 

    gap: 20px;

    /* Base/True White */
    background: $true-white;

    border-radius: 4px;    
    border: 1px solid var(--Section-Border, #DDE3EB); 

    /*min-width:50%;*/
    min-height:30%;

    max-height:100%;
    max-width: 100%;

    transition: width 250ms ease, height 250ms ease;

    overflow:hidden;
}

.Walkthrough-Header  {
    display: flex;
    align-items: center;
    gap: 40px;
    align-self: stretch; 
    max-width: 100%;
    overflow: hidden;

    &.Walkthrough-HeaderWithSeperator  {
        padding-bottom:13px;
        box-shadow: inset 0px -1px 0px #E1E3E5;
    }
    flex-shrink: 0;
}


.Walkthrough-HeaderContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch; 
    gap:6px;
    flex:1;
}



.Walkthrough-HeaderContent-Primary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: stretch; 
    gap:6px;
    max-width: 100%;
    overflow: hidden;
}

.Walkthrough-HeaderTitle-Container  {
    display:flex;
    flex-direction: row;
    gap:6px;
    align-items: center;
}

.Walkthrough-HeaderTitle   {
    display: flex;
    align-items: center;
    gap: 6px; 

    color: #191C22;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: $header-font;
    @media (min-width: 767px) {
        font-size: 22px;
    }
    @media (max-width: 767px) {
        font-size: 18px;
    }

    font-style: normal;
    font-weight: 600;
    line-height: normal; 
}


.Walkthrough-HeaderSubtitle   {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-self: stretch; 

    color: #191C22;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: $header-font;
    @media (min-width: 767px) {
        font-size: 22px;
    }
    @media (max-width: 767px) {
        font-size: 18px;
    }

    font-style: normal;
    font-weight: 300;
    line-height: normal; 
}


.Walkthrough-HeaderDescription   {
    margin-bottom:10px;
    
    font-family: $main-font;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    /* Text/Text-Subdued */
    color: $text-neutral-subdued


}

.Walkthrough-CloseButton {
    display:flex;
    order:1;
    align-self:stretch;
    align-items:center;
    padding:0 10px;
    margin:0 -10px;
    cursor:pointer;

    > svg {
    width: 20px;
    height: 20px;
    }
}


.Walkthrough-HeaderSubContent   {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-self: stretch; 

}


.Walkthrough-Content  {
    display: flex;
    flex-direction: column;
    /*padding: 10px 0px;*/
    align-self: stretch;
    align-items: stretch;

    /* Base/True White */
    /* background: $true-white; */

    flex-grow:1;
    overflow-y:auto;
    scrollbar-width: thin;

    max-width: 100%;
    overflow-x:hidden;

    transition: min-width 250ms ease, height 250ms ease;
}

.Walkthrough-FormItem    {
    display:flex;
    flex-direction: column;
    margin-bottom:16px;
    gap:10px;
    align-self:stretch;

    .Walkthrough-FormItem-Question   {
        display:flex;
        flex-direction: column;

        color: #191C22;
        /* Cell Content 3 */
        font-family: $main-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 
    }
    .Walkthrough-FormItem-Question-Help  {
        display:flex;
        flex-direction: column;
        color:$text-neutral-subdued;
        font-size:13px;
    }
    .Walkthrough-FormItem-Answer   {
        display: flex;
        flex-direction: column;
        /*justify-content: center;*/
        margin-top:auto; margin-bottom: auto;
        align-items: stretch;
        gap: 6px;
        align-self: stretch; 
        .Walkthrough-FormItem-Answer-Info   {
            color: #191C22;
            font-family: $main-font;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal; 
            opacity:0.4;
        }   
    }
}

.Walkthrough-FormItem-Inline {
    display:flex;
    flex-direction: row;
    margin-bottom:16px;
    gap:8px;
    align-self:stretch;
    align-items: center;

    .Walkthrough-FormItem-Question-Wrapper   {
        display:flex;
        flex-direction: column;
        order:1;
    }
    .Walkthrough-FormItem-Question   {
        display:flex;
        flex-direction: column;

    }
    .Walkthrough-FormItem-Question-Help  {
        display:flex;
        flex-direction: column;
        color:$text-neutral-subdued;
        font-size:13px;
    }
    .Walkthrough-FormItem-Answer   {
        order:2;
        display:flex;
        flex-direction: column;
    }
}

.Walkthrough-Footer  {
    display: flex;
    padding: 20px 0px 10px 0px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch; 
    margin-top:20px;

    &:not(.Walkthrough-NoSeperator)  {
        box-shadow: inset 0px 1px 0px #E1E3E5;
    }

    order:2;
}

.Walkthrough-FooterButtons {

}









.Walkthrough-ContentContainer    {
    display: flex;
    flex-direction: column;
    padding: 30px 40px;
    align-items: flex-start;
    gap: 10px;

    &.Walkthrough-ContentContainer-Split {
        &:not(.Walkthrough-ContentContainer-Vertical)    {
            flex-direction: row;
        }
    }

    &.Walkthrough-ContentContainer-20 {
        gap:20px;
    }
    &.Walkthrough-ContentContainer-30 {
        gap:30px;
    }
    &.Walkthrough-ContentContainer-40 {
        gap:40px;
    }
    &.Walkthrough-ContentContainer-50 {
        gap:50px;
    }

    &.Walkthrough-ContentContainer-White  {
        border-radius: 4px;
        border: 1px solid var(--section-border, #DDE3EB);
        background: #FFF; 
    }

    &.Walkthrough-ContentContainer-Vertical    {
        align-items:center;
        padding: 20px 25px;
    }
}

.Walkthrough-StandardHeader  {
    display:flex;
    color: #191C22;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
    &.Walkthrough-StandardHeader-Center {
        text-align: center;
    }
}

.Walkthrough-StandardSubHeader  {
    display:flex;
    color: #191C22;
    font-family: $main-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal; 
}







.Walkthrough-Cards {
    display:flex;
    align-self:stretch;
    
    flex-direction: row;
    position:relative;
    overflow-y:hidden;
    overflow-x: hidden;
}

.Walkthrough-Card-Wrapper {
    display:flex;
    position:relative;
    left:0;
    /*right:0;*/
    /*height:100%;
    width:100%;
    top:0;
    bottom:0;*/
    max-width: 100%;
    justify-content: stretch;
    align-content: stretch;
    align-items: stretch;
    flex: 1;
}

.Walkthrough-Card-Container {
    display:flex;
    flex-direction: column;
    justify-content: stretch;
    align-content: stretch;
    align-items: stretch;
    max-width: 100%;
    overflow-x: auto;
    flex: 1;
}

.Walkthrough-Card-ContentSizeContainer  {
    display:flex;
    flex-direction: column;
    align-self: stretch;
    justify-self: stretch;
    overflow-y:hidden;
    flex:1;
}

.Walkthrough-Card   {
    display: flex;
    @media (min-width: 767px) {
        gap: 30px;
    }
    @media (max-width: 767px) {
        gap: 20px;
    }

    
    flex-direction: column;
    /*align-items: center;*/
    flex: 1 1 auto; 
    overflow:hidden;

    &.Walkthrough-Card-VerticalCenter   {
        align-content:center;
    }

}


.Walkthrough-Card-MobileHeader  {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    align-self: stretch; 

    &.Walkthrough-Card-HeaderWithSeperator  {
        border-bottom:1px solid #DDE3EB;
        padding-bottom:9px;
    }
    /*@media (max-width: 767px) {
        padding: 30px 20px 20px 20px;
    }*/


    .Walkthrough-Card-HeaderContent {
        flex: 1 0 auto;
        color: var(--Text, #191C22);
        font-family: $header-font;
        font-style: normal;
        font-weight: 600;
        line-height: normal; 

        @media (min-width: 767px) {
            font-size: 20px;
        }
        @media (max-width: 767px) {
            font-size: 16px;
        }
        
        white-space:normal;
        overflow-x:hidden;
    }
}



.Walkthrough-Card-Header    {
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch; 

    &.Walkthrough-Card-HeaderWithSeperator  {
        border-bottom:1px solid #DDE3EB;
        padding-bottom:19px;
    }
    /*@media (max-width: 767px) {
        padding: 30px 20px 20px 20px;
    }*/


    .Walkthrough-Card-HeaderContent {
        flex: 1 0 auto;
        color: var(--Text, #191C22);
        font-family: $header-font;
        text-align: center;
        font-style: normal;
        font-weight: 600;
        line-height: normal; 

        @media (min-width: 767px) {
            font-size: 20px;
        }
        @media (max-width: 767px) {
            font-size: 16px;
        }


        white-space:normal;
        overflow-x:hidden;
        max-width:100%;
    }
}

.Walkthrough-Card-Content-Wrapper    {
    display:flex;
    flex-direction: column;
    flex:1;
    overflow:hidden;
    max-width:100%;
    &.Walkthrough-Card-Content-Center   {

        justify-content:center;
        align-self:center;
    }
    &.Walkthrough-Card-Content-VCenter  {
        justify-content:center;
    }
    &.Walkthrough-Card-Content-HFill    {
        justify-content:stretch;
        align-self:stretch;
    }

}
.Walkthrough-Card-Content    {
    display: flex;
    gap: 20px;
    align-self: stretch; 
    overflow-y:auto;
    overflow-x:auto;

    

    &.Walkthrough-Card-Content-Split    {
        flex-direction:row;
        align-content:stretch;
        margin:0px 30px;
        gap:40px;

        &.Walkthrough-Card-Content-Center > div   {
            /*justify-content: center;*/
            margin-top:auto; margin-bottom: auto;
        }

        >div    {
            display:flex;
            flex-direction: column;
            align-items: center;
            flex:1 0 auto;
            gap: 40px;
            align-self: stretch; 
        }
    }

    &:not(.Walkthrough-Card-Content-Split){
        flex-direction: column;
        align-items: center;

        &.Walkthrough-Card-Content-Center   {
            /*justify-content: center;*/
            margin-top:auto; margin-bottom: auto;
        }
    }
}

.Walkthrough-Card-FooterButtons-Container   {
    display:flex;
    align-items: stretch;
    flex-direction: column;
    gap:10px;
    align-self: stretch;
}
.Walkthrough-Card-FooterButtons    {
    display: flex;
    align-items: stretch;
    gap: 6px;
    align-self: stretch;

    @media (min-width: 767px) {
        padding-top:20px;
        &:not(.Walkthrough-Card-FooterEmpty)    {
            border-top:1px solid #DDE3EB;
        }
    }
    @media (max-width: 767px) {
        flex-direction: column;
        padding-top:20px;
        &:not(.Walkthrough-Card-FooterEmpty)    {
            border-top:1px solid #DDE3EB;
        }
    }


    .Button {

    }
}
.Walkthrough-Card-FooterButtons-Aux    {
    display:flex;
    flex-direction: row;
    align-self:center;
}