@import '../../../../globals.scss';


#CreateGrowPlanWalkthrough  {
    
}


.CreateGrowPlanWalkthrough-DetailsForm    {
    display:flex;
    flex-direction: column;
    gap:24px;
}

.CreateGrowPlanWalkthrough-RaftSelection-Container {
    display:flex;
    flex-direction: column;
    gap:4px;
    
    .CreateGrowPlanWalkthrough-RaftSelection-Label    {
        font-family: $main-font;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height, or 125% */

        /* Text/Text-Default */
        color: $text-neutral;

    }
}