@import '../../globals.scss';


.Checkbox {
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    gap:6px;
    align-items: center;
    &:not(.Checkbox-Disabled)   {
        cursor: pointer;
    }
    &.Checkbox-On {
        .Checkbox-Border {
            background: white;
            border:2px solid #2E72D2;
        }
        .Checkbox-Content {
            background: #2E72D2;
        }
    }
    &.Checkbox-Off {
        .Checkbox-Border {
            background: white;
            border:2px solid #9FA9BA;
        }
        .Checkbox-Content {
            background: white;
        }
    }


    .Checkbox-Content {
        display:flex;
        flex-direction: row;
        align-items:stretch;

        

        margin:0;

        /* Base/True White */
        background: #FFFFFF;

    }


    .Checkbox-Label {
        color: #191C22;
        text-align: center;
        font-family: $main-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal; 
    }

}
