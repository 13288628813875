@import '../globals.scss';


.CircularGauge {
    display: flex;
    align-content:center;
    align-items: center;

    
    .CircularGauge-ValueDisplay {
        display: flex;
        flex-direction: column;
        font-size: 160%;
        align-content:center;
        align-items: center;
    }
    .CircularGauge-AuxText {
        display: flex;
        flex-direction: column;
        font-size: 80%;
        align-content:center;
        align-items: center;
        color:$text-neutral-subdued;
    }
}