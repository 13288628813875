@import '../../globals.scss';



.NDSLoadingIcon   {
    #loading_dots   {
        #dot_1  {
            animation: NDSLoadingIcon-LoadingAnimation 0.8s ease infinite alternate;
        }
        #dot_2  {
            animation: NDSLoadingIcon-LoadingAnimation 0.8s ease infinite alternate;
            animation-delay: 0.18s;
        }
        #dot_3  {
            animation: NDSLoadingIcon-LoadingAnimation 0.8s ease infinite alternate;
            animation-delay: 0.35s;
        }
        transform-origin: center;
    }
}

@keyframes NDSLoadingIcon-LoadingAnimation {
    0% {
        fill: #2E72D2;
        opacity:1.0;
        transform: translate(0, -15px) scale(1, 0.9);
    }
    60% {
        transform: translate(0, 0) scale(1, 1);
    }
    100% {
        fill: #1E4292;
        opacity:0.1;
    }
}

@keyframes NDSLoadingIcon-LoadingAnimation2 {
    0% {
        fill: #2E72D2;
    }
    50% {
        fill: #2E72D2;
    }
    100% {
        fill: #1E4292;
    }
}

@keyframes NDSLoadingIcon-LoadingAnimation3 {
    0% {
        fill: #1E4292;
    }
    50% {
        fill: #2E72D2;
    }
    100% {
        fill: #2E72D2;
    }
}









.NDSPortSelection   {
    
    &.NDSPortSelection-Selected {

    }
}