@import '../../globals.scss';

 
#Tasks_ListPage    {
    display:flex;
    flex-direction: column;
    flex-grow:1;
    overflow-y:hidden;
}



#TasksTimelinePage  {
    display:flex;
    flex-direction: row;
    align-self: stretch;
    align-items: flex-start;
    gap:0;
    overflow-y:hidden;
}

#TasksTimelineTable {
    display:flex;
    flex:0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

#TasksTimelineContent {
    display:flex;
    flex-grow:1;
    overflow: hidden;
    >div   {
        border: 1px solid $neutral-200;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        overflow:hidden!important;
    }
}




.Grow_Harvest-PlantTypes    {
    display:flex;
    flex-direction: column;
    gap:8px;
    margin:4px 0;
}

.Grow_Harvest-PlantType {
    display:flex;
    flex-direction: column;
    
    .Grow_Harvest-PlantType-Header   {
        font-weight:bold;
    }
    .Grow_Harvest-PlantType-Inputs  {
        display:flex;
        flex-direction: column;
        gap:4px;

        .Grow_Harvest-PlantType-Input   {
            display:flex;
            flex-direction: row;
            padding:2px;
            border:1px solid $neutral-50;
            gap:8px;
            align-items: center;
        }
    }
}









.NDSSensorCalibration-SensorBanks    {
    display:flex;
    flex-direction: row;
    gap:8px;
}

.NDSSensorCalibration-SensorBank    {
    display:flex;
    align-self:flex-start;
    padding:8px;
    border:1px solid $neutral-300;
    border-radius:4px;

    &.NDSSensorCalibration-SensorBank-Selectable    {
        cursor: pointer;
    }
    &.NDSSensorCalibration-SensorBank-Selected  {

        background-color:$neutral-50;
    }

    .NDSSensorCalibration-SensorBank-Header    {
        
    }
}







.NDSSensorCalibration-SensorTypes    {
    display:flex;
    flex-direction: row;
    gap:8px;
}

.NDSSensorCalibration-SensorType    {
    display:flex;
    flex-direction: column;
    align-self:flex-start;
    padding:8px;
    border:1px solid $neutral-300;
    border-radius:4px;
    cursor: pointer;

    .NDSSensorCalibration-SensorType-Header    {
        
    }
}





.NDSReservoirTopUp-Reservoirs    {
    display:flex;
    flex-direction: row;
    gap:8px;
}

.NDSReservoirTopUp-Reservoir    {
    display:flex;
    flex-direction: column;
    align-self:flex-start;
    padding:8px;
    border:1px solid $neutral-300;
    border-radius:4px;

    &.NDSReservoirTopUp-Reservoir-Selectable    {
        cursor: pointer;
    }
    &.NDSReservoirTopUp-Reservoir-Selected  {

        background-color:$neutral-50;
    }

    .NDSReservoirTopUp-Reservoir-Header    {
        
    }
}


.Task-Hyperlink {
    color: $main-blue;
    font-family: $main-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
    cursor:pointer;
}


.Task-Hyperlink-NoLink {
    color: $black;
    font-family: $main-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
    cursor:pointer;
}


