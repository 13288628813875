@import '../../globals.scss';


.DropDownInput    {
    display:flex;
    flex-direction: column;
    align-self:stretch;


    &.DropDownInput-Disabled    {
        opacity:0.4;
    }
    &:not(.DropDownInput-Disabled)  {
        cursor:pointer;
    }

    &.DropDownInput-Neutral {
        .DropDownInput-Field    {
            background:#FFF;
            border-color: #9CA6B4;
        }
    }
    &.DropDownInput-AltNeutral {
        .DropDownInput-Field    {
            background:#FFF;
            border-color: #DDE3EB;
        }
    }

    &.DropDownInput-Selected    {
        .DropDownInput-Field    {
            border-color: #2E72D2;
        }
    }
}

.DropDownInput-Container    {
    display:flex;
    flex-direction: column;
    align-self:stretch;
    flex: 1 0 0;
}
.DropDownInput-Field    {
    display: flex;
    padding: 8px 12px 8px 10px;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;


    border-radius: 4px;
    border-width:1px;
    border-style: solid;
    border-color:#9CA6B4;


    
    .DropDownInput-Field-Icon   {
        border-radius: 4px;
        border: 1px solid var(--Section-Border, #DDE3EB);
        background: #F7F8FB;

        width: 39px;
        height: 35px;
    }

    .DropDownInput-Field-Prefix {
        overflow: hidden;
        color: #191C22;
        text-overflow: ellipsis;
        font-family: $main-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 
    }

    .DropDownInput-Field-SelectedItem    {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        flex: 1 0 0; 

        overflow: hidden;
        color: #191C22;
        text-overflow: ellipsis;
        font-family: $main-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 

        &.DropDownInput-Field-SelectedItem-Invalid  {
            color: #D82C0D;
        }
    }

    .DropDownInput-Field-Search    {
        flex:1;
        width:100%;
        color: #191C22;
        font-family: $main-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 

        display: flex;
        align-items: center;
        align-self:stretch;


        border:0;
        margin:0;
        padding:0;
        background:none;
        -webkit-appearance: none;
        -moz-appearance: none;
        outline: none;
    }

    .DropDownInput-Field-Placeholder    {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        flex: 1 0 0; 

        overflow: hidden;
        color: #191C22;
        text-overflow: ellipsis;
        font-family: $main-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 
    }


    .DropDownInput-Field-Arrow  {
        display:flex;
        align-self:center;
    }
}



.DropDownInput-Popup-Closer {
    position:fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    width:100%;
    height: 100%;
    z-index:999;
    cursor:default;
}

.DropDownInput-Popup-Wrapper    {
    position:fixed;
    overflow:hidden;
    box-sizing: border-box;
    transition:height 100ms ease;
    z-index:1000;
}

.DropDownInput-Popup-Container    {
    display:flex;
    overflow:hidden;
}

.DropDownInput-Popup    {
    display:flex;
    overflow-y:auto;

    border-radius: 4px;
    border-style:solid;
    border-color: #2E72D2;
    box-shadow: -1px 4px 20px 0px rgba(0, 0, 0, 0.10);
    background: #FFF;
    
}

.DropDownInput-Popup-ContentWrapper {
    display:flex;
    width: 100%;
}
.DropDownInput-Popup-Content    {
    display:flex;
    padding: 10px 8px; 
    align-items: flex-start;
    gap: 10px;     
    flex: 1;
}



.DropDownInput-Popup-Options    {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    flex: 1 0 0; 
}


.DropDownInput-Popup-Option   {
    display: flex;
    padding: 4px 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    align-self: stretch; 
    border-radius: 4px;

    &:hover {
        background: var(--background, #F0F1F2);
    }

    &:not(.DropDownInput-Popup-Option-Disabled) {
        cursor:pointer;
    }
    &.DropDownInput-Popup-Option-Disabled {
        cursor:default;
    }

    .DropDownInput-Popup-Option-Icon   {
        width:25px;
        height:25px;
        border-radius: 4px;
        border: 1px solid var(--Section-Border, #DDE3EB);
        background: #F7F8FB;
    }

    .DropDownInput-Popup-Option-Title   {
        display:flex;
        flex-direction: row;
        align-items:center;
        gap:10px;

        color: #000;
        
        overflow:hidden;
        text-overflow: ellipsis;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: $main-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */ 
    }

    .DropDownInput-Popup-Option-Info   {
        color: var(--dark-section-border, #9CA6B4);
        
        overflow:hidden;
        text-overflow: ellipsis;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: $main-font;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 10px; /* 100% */ 
    }

    &.DropDownInput-Popup-Option-Disabled  {
        /*background: var(--background, #F0F1F2);*/

        .DropDownInput-Popup-Option-Title   {
            opacity:0.4;
        } 
        .DropDownInput-Popup-Option-Info   {
            opacity:0.9;
        }     
    }


    &.DropDownInput-Popup-Option-Selected   {
        .DropDownInput-Popup-Option-Title   {
            color: #2E72D2;
        } 
    }
}