@import '../../globals.scss';

.FullScreenPhotoViewer  {
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    width:100%;
    height:100%;
    z-index:99999;
    background-color:#222222;
}

.FullScreenPhotoViewer-ImageContainer   {
    position:relative;

}

.FullScreenPhotoViewer-Image    {
    width:100%;
    height:100%;
}


.FullScreenPhotoViewer-CloseButton  {
    position:fixed;
    top:0;
    right:0;
    padding:20px;
}