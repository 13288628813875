@import '../globals.scss';


#SelectRecipes  {
    display:flex;
    flex-direction: column;
    align-content: stretch;
    flex:1;
    overflow-y:hidden;
    max-height:100%;
    align-self:stretch;
}
