@import '../../../globals.scss';


.UploadPhotoPopup-PhotoThumb    {
    width:48px;
    height:36px;
}


.ViewGrowPhoto-MainPhoto    {
  display:flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  background-color:#f8f8f8;
  border:1px solid #DDE3EB;
  border-radius:4px;
  overflow:hidden;
}